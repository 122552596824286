/* .tour {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
  position: relative;
} */

.tour-frame {
  display: flex;
  align-self: stretch;
  /* margin: 0px; */
  /* border: 2px dashed skyblue; */
  flex-direction: column;
  justify-content: center;
  /* gap: 5vw; */
}

.tour-container-desktop {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  padding: 0px 1.5%;
}

.tour-container-mobile {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}

.tour-header {
  color: var(--hub_background);
  text-align: center;
  width: 75%;
}

.tour-text-container {
  margin: 0;
  width: 50%;
  display: flex;
  gap: 30vh;
  flex-direction: column;
  padding-bottom: 10vh;
  padding-top: 10vh;
}

.tour-step-text-container {
  margin: 0;
  border: 1px solid grey;
}


.tour-image-container {
  position: sticky;
  top: 15vh;
  display: flex;
  /* border: 1px solid orchid; */
  align-self: self-start;
  height: 70vh;
  width: 50%;
  justify-content: flex-end;
  animation: fadein 5s;
}

.tour-image {
  object-fit: contain;
  position: relative;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  25% {
    opacity: .25;
  }

  50% {
    opacity: .5;
  }

  75% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }

  /* from{opacity: 0;}
  to {opacity: 1;} */

}

.tour .frame-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 50px;
  position: relative;
}

.tour .v-tour-step {
  white-space: unset !important;
}

.tour .v2-tour-step {
  white-space: unset !important;
}