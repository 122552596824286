.form-frame {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 100%;
  padding: 0vh 2vw;
  align-items: center;
  /* overflow: scroll; */
  z-index: 1001;
}

.form-frame form {
  width: 100%;
}

.form-frame input,
.form-frame label {
  font-family: inherit !important;
  font-size: var(--span-xs-font-size) !important;
  color: var(--black);
}

.css-12hrykr-MuiFormControl-root,
.css-1cdnm0f-MuiFormControl-root,
.css-162wq0i-MuiFormControl-root {
  background-color: var(--white);
  border-radius: 5px;
}

.css-12hrykr-MuiFormControl-root label,
.css-1cdnm0f-MuiFormControl-root label,
.css-162wq0i-MuiFormControl-root label {
  font-family: inherit !important;
  color: var(--page_background);
}

.form-text-frame {
  color: var(--page_background);
  background-color: var(--white);
  width: 100%;
  border-radius: 5px;
  text-align: center;
}

.form-text-frame span {
  color: var(--page_background);
}

.select-options-box {
  /* position: absolute; */
  /* max-height: 10vh !important; */
  width: 100%;
}

.error-message {
  color: var(--red);
  font-style: italic;
  font-weight: 600 !important;
  width: fit-content;
}

.css-saulgj {
  background-color: var(--white);
}

.email-sent-message-light {
  color: var(--white);
}

.email-sent-message-dark {
  color: var(--page_background);
}

.MuiListItemText-root span {
  font-family: inherit !important;
}

.MuiTypography-root {
  font-family: inherit !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: var(--orange_accent);
}

.form-contact-us-topics-of-interest-frame {
  width: 100%;
  align-items: flex-start;
}

.form-contact-us-topics-of-interest-frame legend {
  width: 100%;
  text-align: start;
}

.css-1fwnb9c {
  margin: 0;
  padding: .5rem;
}