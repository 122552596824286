.hub-section-toggle-button-default {
  align-items: center;
  border-radius: var(--border_radius_bar);
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 1vh 1.5vw;
  position: relative;
  width: fit-content;
  cursor: pointer;
  top: unset;
}

@media (min-width: 769) {
  .hub-section-toggle-button-default {
    border-radius: var(--border_radius_bar);
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-section-toggle-button-default {
    border-radius: var(--border_radius_container);
  }
}

@media (max-width: 480px) {
  .hub-section-toggle-button-default {
    border-radius: var(--border_radius_container);
  }
}



.hub-section-toggle-button-user-selected {
  background-color: var(--black);
}

.hub-section-toggle-button-user-not-selected {
  background-color: inherit;
}

.hub-section-toggle-button-user-not-selected:hover {
  background-color: var(--light-grey);
}

.hub-section-toggle-button-text {
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  white-space: normal;
  text-align: center;
  width: fit-content;
}

.hub-section-toggle-button-selected-text {
  color: var(--white);
}