.frame-screen {
  align-items: flex-start;
  background-color: #115453;
  border: 1px none;
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  position: relative;
}

.frame-screen .frame-63 {
  left: 811px !important;
  position: absolute !important;
  top: 1482px !important;
}

.frame-screen .icon-12 {
  height: 156.1px !important;
  min-width: 170.08px !important;
  position: relative !important;
}

.frame-screen .icon-13 {
  height: 156.1px !important;
  min-width: 170.08px !important;
  position: relative !important;
}
