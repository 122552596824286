.header-search-bar-item-container {
  align-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 1px 3px #00000033;
  display: flex;
  gap: 10px;
  overflow: hidden;
  position: relative;
  width: inherit;
  height: inherit;
  /* max-height: 4vh; */
}

/* Not currently using this! */
/* .header-search-bar-enter-an-address {
  color: #00000066;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
} */

.google-auto-complete {
  width: inherit;
  height: inherit;
}

.pac-input {
  background-color: #fff;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

.pac-container {
  font-size: 1rem;
  position: absolute;
  bottom: 2vh !important;
  width: clamp(300px, 35vw, 500px) !important;
  /* left: 0 !important; */
  /* right: 0 !important; */
  /* margin-left: auto !important; */
  /* margin-right: auto !important; */
  height: fit-content !important;
  box-sizing: content-box;
}

@media (max-width: 480px) {
  .pac-container {
    left: 0 !important;
    right: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.header-search-bar {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  position: relative;
  /* padding: 15px; */
  border: none;
  border-radius: 15px;
  text-align: center;
  width: inherit;
  height: inherit;
  font-size: 1rem;
  font-family: 'halyard-display';
}

.header-search-bar:focus {
  outline: none !important;
}

.header-search-bar-icon-container {
  align-items: center;
  background-color: var(--accent_orange);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: .5em;
  width: fit-content;
  max-height: 80%;
}

.header-search-bar-icon {
  position: relative;
  object-fit: contain;
  scale: .5;
}