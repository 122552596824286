.hub-projects-filter-bar-frame {
  /* height: 25px; */
  z-index: 3;
  align-self: stretch;
  align-items: flex-start;
  gap: 10vw;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.hub-projects-filters-wrapper {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: 10px;
  padding: 0 10px 0 0;
  display: flex;
  position: relative;
  cursor: pointer;
}

.hub-projects-filter-menus-container {
  display: flex;
  gap: 2vw;
  align-items: flex-start;
}

.hub-projects-display-toggle-and-showing-text {
  display: flex;
  flex-direction: row;
}

.hub-projects-filter-button {
  /* color: #468347; */
  color: var(--page_background)
}

.hub-projects-filter-text {
  color: var(--page_background);
  letter-spacing: .36px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5rem;
  font-weight: 600;
  /* line-height: 16.8px; */
  position: relative;
  /* flex: 1; */
  align-self: center;
}

.hub-projects-filter-text-mobile {
  text-align: center;
}

.hub-projects-filter-bar-frame select {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
}

.hub-projects-filter-box option {
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 200px;
}

.hub-project-filter-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  justify-content: center;
}

.hub-project-filter-icon {
  color: var(--page_background)
}

.hub-project-filter-item-text {
  color: var(--page_background);
  font-style: italic;
  font-size: 1.25rem;
  text-wrap: balance;
  text-align: center;
}