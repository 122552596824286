.pagination-bar {
    width: 90%;
    display: flex;
    justify-content: center;
}

.pagination-bar button,
.pagination-bar li,
.pagination-bar div.MuiPaginationItem-root {
    color: var(--page_background);
    font-size: 1.5rem;
}

.resources-text-styles {
    /* color: var(--white); */
    text-align: center;
    text-wrap: balance;
    /* color: var(--page_background); */
}


.resources-header-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* 
.resources-header-frame p,h1 {
    color: var(--white);
} */

.resource-filter-section {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.resource-filter-selection-input {
    width: clamp(150px, 10vw, 300px) !important;
    background-color: white;
    color: var(--page_background) !important;
    font-weight: 600;
    border-radius: 5px;
}

.resource-filter-selection-input span,
.resource-filter-selection-input label {
    color: var(--page_background) !important;
    /* text-align: center; */
    font-family: inherit !important;
    font-size: var(--span-xs-font-size) !important
}

.resource-search-bar-headline {
    font-family: larken, sans-serif !important;
    font-style: italic;
}

.input-container {
    position: relative;
    width: 100%;
}

.resource-filter-bar-container {
    display: flex;
    flex-direction: row;
    /* width: fit-content; */
    position: relative;
    justify-content: center;
    align-items: center;
}

.resource-search-bar {
    font-family: inherit !important;
    display: flex;
    flex-direction: row;
    gap: 5vw;
    width: 75%;
    height: 6vh;
    padding: 0% 5%;
    border-radius: 50px;
    border: none;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.resource-search-bar:focus {
    outline-width: 0;
}

.cancel-icon {
    position: absolute;
    right: calc(15%);
    /* Adjust the position as needed */
    /* top: 50%; */
    /* Vertically center the icon */
    /* transform: translateY(-50%); */
    /* Vertically center the icon */
    cursor: pointer;
    color: var(--page_background);
    font-size: 2rem !important;
    /* Icon color */
}

.resource-filter-container {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--modal_background);
    border-radius: 5px;
    padding: .5vh .5vw;
}

.resource-filter-bar-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 1vw;
    /* padding: .5vh .5vw; */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.resource-filter-bar-header-text {
    /* color: var(--white); */
    font-weight: 600;
}



.resource-filter-bar {
    display: flex;
    flex-direction: row;
    gap: 5vw;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.resource-filter-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    justify-content: center;
}

.resource-filter-item select {
    font-size: 1rem;
    text-align: center;
    width: 7vw;
    font-family: inherit;
}

.resource-filter-item option {
    font-size: 1rem;
}

.resource-filter-item-text {
    /* color: var(--white); */
    font-style: italic;
    font-size: 1.25rem;
}

.resource-filter-item-icon-container {
    display: flex;
    height: 5vh;
}


.resource-filter-item-icon {
    color: var(--accent_orange);
    font-size: 2.25rem;
}

.resource-filter-item-clear {
    color: var(--white);
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: var(--page_background);
    padding: 1vh 2vw;
    /* inset: 1vh 2vw; */
    border-radius: 5px;
}

.resource-filter-item-clear:hover {
    background-color: var(--accent_orange);
    color: var(--page_background);
}