.content-footer-container {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 1vh;
  /* padding: 5vw 20vw; */
  display: flex;
  position: relative;
}

.got-questions-button {
  margin-bottom: 0vh;
}