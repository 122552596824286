.hidden-anchor {
  
}

.hub-logo-quoll {
  /* height: 454px;
  width: 501px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius:10px; */
  border-radius: 10px;
  width: 85%;
  height: 85%;
  position: absolute;
  top: -18%;
  right: -22%;
  filter: grayscale(100%);
  opacity: .035;
}