.what-now-icon-frame {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 15vh; */
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    aspect-ratio: 16/9;
    /* gap: 5px; */
}

.what-now-icon-frame-image-overlay {
    background-color: #00000096;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* padding: 0 2vw; */
    display: flex;
    position: absolute;
}

.what-now-icon-frame-image-overlay-not-selected {
    background-color: #000000de !important;

}