.projects-list-frame {
  min-width: -webkit-fill-available;
  /* margin-bottom: 10px; */
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 1vh;
}
.project-cards-frame {
  align-self: stretch;
  align-items: stretch;
  column-gap: 1vw;
  row-gap: 3vh;
  position: relative;
  /* grid-template-columns: repeat(auto-fill, minmax(20rem, max-content)); */
  grid-template-columns: repeat(auto-fit, minmax(10rem, 21rem));
  width: 100%;
  display: grid;
  justify-content: space-around;
}

.recommended-project-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.recommended-project-checklist-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: fit-content;
  align-self: center;
}

.recommended-project-why-this-project-text {
  font-weight: 600;
  color: var(--page_background);
  text-align: center;
}

.recommended-project-checklist-item {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  align-items: center;
}

.recommended-project-checklist-item span {
  color: var(--page_background);
}

.recommended-project-checklist-icon {
  font-size: var(--span-m-font-size);
  color: var(--page_background);
}
