.climate-factors-home-hub-container {
  align-items: center;
  /* align-self: stretch; */
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 10px 30px;
  position: relative;
  padding: 0px 7px 21px;
}

.climate-factors-home-hub-container-mobile {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  padding: 0px 7px 5px;
}

.climate-factors-home-hub-image {
  object-fit: cover;
  position: relative;
}

.overview-hub-v5-home-overview {
  color: #262626;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 36px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.climate-factors-mobile-list-container {
  gap: .75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.hub-house-category-text-projects-list {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  color: #262626;
  font-size: .75rem;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  display: flex;
  align-items: flex-start;
}

.hub-house-category-text-projects-list-delineator {
  /* font-size: 2rem; */
}