.legal-page-header {
    justify-content: center;
    align-items: left;
    background-position: 50%;
    background-size: contain;
    flex-direction: column;
    width: 100%;
    min-height: 15vw;
    display: flex;
    position: relative;
}

/* .privacy-page {
    overflow-y: scroll;
    height: 400px;
}

.privacy-page::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    width: unset !important;
    height: unset !important;
    right: unset !important;
}

.privacy-page::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.privacy-page::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px var(--page_background);
} */

/* .privacy-page::-webkit-scrollbar-button:start:increment {
    height: .5%;
    display: block;
    background: transparent;
}

.privacy-page::-webkit-scrollbar-button:end:increment {
    height: .5%;
    display: block;
    background: transparent;
} */