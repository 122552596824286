.risk-level-indicator-frame {
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 15px;
  display: flex;
  gap: 5px;
  /* height: 20px; */
  overflow: hidden;
  padding: 10px 10px 10px 10px;
  position: relative;
  width: fit-content;
}

.risk-level-indicator-ellipse {
  border-radius: 4px;
  height: 8px;
  min-width: 8px;
  position: relative;
}

.risk-level-indicator-rating-text {
  color: var(--black);
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: 0.11px;
  line-height: 15.4px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
