.now-what-header {
  color: var(--page_background);
  text-align: center;
}


.what-now-frame {
  background-color: var(--hub_background);
  align-items: flex-start;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: fixed;
  gap: 10px;
  max-width: 80vw;
  /* padding: 3vw; */
  padding-top: 2vh;
  padding-bottom: 2vh;
  z-index: 1002;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90vh;
  top: calc(5%);
}

.what-now-frame-first-view-true {
  top: 33%;
  max-width: 40vw;
}


.what-now-frame-tablet {
  width: calc(100% - 100px);
  padding: 2vw;
  max-width: 85vw;
}

.what-now-frame-mobile {
  width: calc(100% - 20px);
  padding: 2vh 1vw;
  max-width: 90vw;
}

.now-what-background-overlay {
  z-index: 1000;
  background-color: #4845455e;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}