.climate-blurb-risk-level-container {
  align-items: flex-start;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  padding: .5rem;
  position: relative;
  width: fit-content;
}

.climate-blurb-risk-level-medium {
  background-color: #fbb500;
}

.climate-blurb-risk-level-low {
  background-color: #4f7b64;
}

.climate-blurb-risk-level-high {
  background-color: #e73b15;
}

.climate-blurb-risk-level-text {
  color: #ffffff;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.55px;
  line-height: 15.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

