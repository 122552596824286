.modal-project-header-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1vh;
}

.modal-project-breadcrumb-bar {
  align-self: stretch;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: .5vw;
}

.modal-project-breadcrumb-item {
  color: var(--page_background);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 19.5px; */
}

.modal-project-breadcrumb-item-clickable {
  cursor: pointer;
}

.modal-project-image-container {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
}

.modal-project-image {
  object-fit: cover;
  height: inherit;
  width: inherit;
  border-radius: 15px;
}

.modal-project-title-bar {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  align-items: center;
}

.modal-project-professional-v-diy-text {
  color: #6b658b;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 18px; */
  position: relative;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.modal-project-description {
  text-align: center;
  /* width: 75%; */
}

.modal-project-user-project-mgt-buttons-bar {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  padding: 5px 0px;
  position: relative;
  width: fit-content;
}

.modal-project-user-project-mgt-button-container {
  align-items: center;
  background-color: var(--accent_orange);
  border-radius: 5px;
  display: flex;
  gap: 10px;
  padding: 1vh .5vw;
  position: relative;
  width: fit-content;
  cursor: pointer;
}

.modal-project-user-project-mgt-button-container-saved-true {
  background-color: var(--page_background);
}

.modal-project-user-project-mgt-button-container-saved-false {
  background-color: var(--accent_orange);
}

.modal-project-user-project-mgt-button-text {
  color: var(--hub_background);
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  line-height: inherit !important;
}

.meter-bar-frame {
  align-self: stretch;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  position: relative;
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  padding: 2vh 1vw;
}

.modal-project-accordians-container {
  display: flex;
  flex-direction: column;
  gap: .5vh;
  width: 100%;
}

.project-saved-checkmark {
  height: inherit;
}