.hub-sections-button-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  width: 40%;
  align-items: center;
}


.hub-sections-button-location-ident-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  /* width: 40%; */
  align-items: center;
}

.hub-sections-button-location-ident-hide {
  display: none !important;
}

.hub-sections-button-location-ident-text {
  color: var(--page_background);
  font-weight: 600;
}

.hub-sections-button-frame {
  align-items: center;
  border-radius: 22px;
  display: flex;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 2vh 1vw;
  position: relative;
  align-self: stretch;
  width: 100%;
  max-height: 80%;
}

.hub-button-selected-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  width: inherit;
  width: 100%;
}

.hub-button-selected-container span {
  font-weight: 600;
}

.hub-sections-button-frame-selection-true {
  background-color: var(--page_background);
  color: var(--white);
}

.hub-sections-button-frame-selection-true span {
  color: var(--white);
}

.hub-sections-button-frame-selection-false {
  cursor: pointer;
  background-color: var(--light-grey);
  color: var(--black);
}

.hub-sections-button-frame-selection-false span {
  color: var(--black);
}

.hub-sections-button-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: normal;
  text-align: center;
  width: fit-content;
  text-wrap: balance;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}