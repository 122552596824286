.discount-card-frame {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  gap: 1.5vh;
  padding: 1rem;
  text-decoration: none;
  /* border: 1px solid; */
}

.discount-card-image-container {
  align-items: center;
  display: flex;
  /* gap: 1.5vh; */
  position: relative;
  justify-content: center;
  width: 100%;
  height: 10vh;
  overflow: hidden;
}

.discount-card-image {
  background-position: 50% 50%;
  background-size: cover;
  /* border-radius: 30px; */
  /* height: 100%; */
  /* width: 60px; */
  position: relative;
}

.discount-card-text-description {
  font-size: 1rem;
  font-style: italic;
  text-align: center;
  color: var(--page_background);
}

.discount-card-text-link {
  font-size: .75rem;
  color: var(--page_background);
  /* font-style: italic; */
}
