.hub-value-category-stat-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
    padding: 2%;
    /* border: 1px solid var(--black); */
    border-radius: 15px;
  }
  
  .hub-value-category-stat-container-general {
    border: 1px solid var(--black);
  }
  
  .hub-value-category-stat-container-extra-spacing {
  gap: 5vh;
}


.what-and-why-to-care {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--page_background);
  gap: .5vw;
  width: 100%;
  justify-content: space-between;
  padding: 0vh .25vw;
}

.what-and-why-to-care-climate {
  width: unset !important;
}

.what-and-why-to-care-text {
  font-style: italic;
}

.financial-learn-more-header {
  font-size: 2rem;
  /* text-decoration: underline; */
  font-style: italic;
  color: var(--white);
}

.financial-learn-more-text {
  font-size: 1rem;
  color: var(--white);
}

.financial-datapoint-hover-learn-more-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* border: 1px solid var(--black); */
  padding: 1vh 1vw;
  border-radius: 25px;
  min-height: 15vh;
  background-color: var(--page_background);
  color: var(--white);
  text-wrap: balance;
  z-index: 50000;
  gap: 2vh;
  width: fit-content;
  max-width: 25vw;
  text-align: center;
  /* top: 2rem; */
  /* right: 0; */
  transform: translateY(-20vh);
  /* left: 25%; */
  /* bottom: 25%; */
}

.datapoint-hover-i {
  cursor: pointer;
}