.desktop-home-report-frame {
  align-items: center;
  background-color: #ffffff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 834px;
  position: relative;
}

.desktop-home-report-content-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 10px;
  position: relative;
}

.analysis-loading-image-container {
  position: fixed;
  z-index: 1002;
  top: 25%;
  width: 30vw;
  left: 35%;
  border-radius: 15px;
  overflow: hidden;
}

.analysis-loading-image {
  object-fit: contain;
  /* z-index: 1001; */
}