.frame-130-property-home {
  align-items: center;
  background-color: var(--hub_background);
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  justify-content: space-between;
  height: inherit;
  align-self: stretch;
  align-items: center;
  padding: 5px 10px;
  width: inherit;
  cursor: pointer;
}


.frame-130-property-home-form {
  padding: 0px;
  border: none;
  border-radius: 15px;
  overflow: hidden;
}

.frame-130-property-home-selected {
  background-color: none;
  border-top: 2px solid var(--page_background);
  border-left: 2px solid var(--page_background);
}

.frame-130-image-overlay {
  height: 100%;
  width: 100%;
  background-color: #0000009c;
  /* border-bottom: 1px solid; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2vw;
  display: flex;
  position: absolute;
  /* border-radius: 15px; */
}

.frame-130-image-overlay-text {
  color: var(--hub_background);
  letter-spacing: 0;
  white-space: normal;
  text-align: center;
  align-self: stretch;
  font-weight: 600;
  position: relative;
  text-wrap: balance;
}

.frame-130-image-overlay-text-not-selected {
  background-color: #000000de;
}

.frame-130-icons-container {
  display: flex;
  height: 10vh;
  max-height: 15vh;
  position: relative;
  justify-content: center;
}

.frame-130-icons-home {
  /* height: 85px; */
  /* min-width: 85px; */
  position: relative;
  object-fit: contain;
}

.frame-130-text-wrapper {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 25.2px; */
  position: relative;
  text-align: center;
  white-space: wrap;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.frame-130-element-projects {
  color: var(--page_background);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  position: relative;
  font-style: italic;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  display: flex;
}

.frame-130-Home {
  top: 20px;
}

.frame-130-class {
  top: 217px;
}