.report-title-title {
  align-items: center; 
  display: flex;
  gap: 3vw;
  position: relative;
  align-self: stretch;
  justify-content: center;
  left: unset;
  top: unset;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  flex-direction: row;
}

.report-title-text-frame {
  /* display: flex; */
  flex: 1;
  /* flex-direction: column; */
  gap: 15px;
  position: relative;
  /* align-items: flex-start; */
  height: inherit;
  /* min-width: 70vw; */
}

.report-title-share-save-frame {
  display: flex;
  gap: 1vh .75vw;
  justify-content: center;
}

@media (max-width: 480px) {
  .report-title-title {
    flex-direction: column;
  }

  .report-title-share-save-frame {
    gap: 1vh 3vw;
    justify-content: center;
  }
}


.report-address-text{
  align-self: unset;
  font-weight: 500;
  letter-spacing: -0.21px;
  line-height: inherit;
  position: relative;
  white-space: break-spaces;
  text-align: center;
  width: fit-content;
  color: var(--hub_background);
  text-wrap: balance;
}

.title-unclaimed-text {
  align-self: unset;
  color: transparent;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  position: relative;
  white-space: nowrap;
}

.title-unclaimed-span-wrapper {
  color: #777777;
  font-size: .8rem;
}

.title-span {
  color: #777777;
  font-size: .8rem;
}

.title-text-wrapper-2 {
  color: #262626;
  text-decoration: underline;
  font-size: .8rem;
}
