.careers-icons-grid {
    flex: 1;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 4fr));
    justify-content: center;
    place-items: center;
    gap: 5vh 10vw;
    /* padding: 0 5vw; */
    display: grid;
    width: 90%;
}

.careers-icon-frame {
    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    gap: 2vh;
    display: flex;
}

.careers-icon-frame span {
    text-align: center;
    text-wrap: balance;
}

.careers-icon-container {
    height: 10vh;
}

.careers-icon-image {
    object-fit: contain;
    height: inherit;
}

.careers-icon-headline {
    font-weight: 600;
}

.careers-icon-text {
    flex: 1;
    display: flex;
    align-items: center;
}

.careers-resume-drop-form {
    display: flex;
    flex-direction: column;
    padding: 2vh 0vw;
    border: 1px solid var(--page_background);
    border-radius: 15px;
    gap: 2vh;
}