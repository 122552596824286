.tour-step-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  padding: 5vh 0px;
  position: relative;
  width: inherit;
}

.tour-step-container-mobile {
  align-items: center;
}

.tour-step-container-mobile h2,
.tour-step-container-mobile h3,
.tour-step-container-mobile p {
  text-align: center;
  width: 100%;
}


.tour-step-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  gap: 1vh;
}

.tour-step-context {
  color: var(--hub_background);
  font-size: 28px;
  font-style: italic;
  font-weight: 300;
  position: relative;
  white-space: normal;
}

.tour-step-headline {
  color: var(--hub_background);
  font-size: 54px;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  text-align: left;
}

.tour-step-description {
  color: var(--hub_background);
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25.5px;
  position: relative;
}

.tour-step-questions-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  /* padding: 0px 40px 0px 0px; */
  position: relative;
}

.tour-step-question {
  color: var(--hub_background);
  font-style: italic;
  letter-spacing: 0;
  position: relative;
  white-space: normal;
  font-weight: 300;
}

.tour-step-takeaways-button-frame {
  border: 1px solid var(--white);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  height: 5vh;
  display: flex;
  position: relative;
  padding: 1vh 3%;
}

.tour-step-takeaways-button-text {
  color: var(--white);
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  line-height: normal;
  position: relative;
  text-align: center;
}