.faq-toggle-accordian-frame {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.faq-toggle-accordian-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--page_background);
    border-radius: 15px;
    padding: 0vh 1vw;
    cursor: pointer;
}

.faq-accordian-header-text {
    color: var(--white) !important;
}
.faq-accordian-header-icon {
    color: var(--white) !important;
    font-size: var(--h2-font-size) !important;
}

.faq-accordian-questions-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 2vh 2vw;
}

.faq-accordian-questions-container p {
    text-wrap: unset !important
}

.faq-accordian-individual-faq-container {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}
