@keyframes snackbarInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

/* .snack-bar-container-universal-placement {
  right: 5vw;
  bottom: -6vh;
  position: fixed;
} */

.snack-bar-container {
  max-width: 560px;
  min-width: 300px;
  display: flex;
  z-index: 5500;
  background-color: var(--hub_background);
  justify-content: start;
  border-radius: 8px;
  padding: 0.75rem;
  font-weight: 600;
  border: 1px solid var(--page_background);
  position: fixed;
  left: auto;
  transition: transform 0.3s ease-out;
  /* animation: snackbarInRight 200ms; */
  /* animation: 200ms; */
}

.snack-bar-text {
  color: var(--page_background);
}