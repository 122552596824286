.now-what-guidance-take-action-frame {
    display: flex;
    flex-direction: row !important;
    gap: .5vw;
    /* justify-content: left; */
    width: 100%;
}

.now-what-guidance-checklist-container {
    display: flex;
    flex-direction: column !important;
    gap: .5vh;
    justify-content: flex-start;
    width: 50%;
}

.now-what-guidance-checklist-container-mobile {
    width: 100%;
}

.now-what-guidance-cta-container {
    width: 50%;
    flex-direction: column !important;
    display: flex;
    border: 1px solid var(--page_background);
    padding: 1vw;
    gap: 5vh;
    background: var(--page_background);
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.now-what-guidance-cta-container-mobile {
    width: 100%;
    flex-direction: column !important;
    display: flex;
    /* border-top: 1px solid var(--white); */
    padding: 1vw;
    gap: 5vh;
    background: var(--page_background);
    justify-content: center;
    align-items: center;
    /* border-radius: 15px; */
}

.now-what-guidance-cta-container > span {
    color: var(--white)
}

.now-what-guidance-cta-container-mobile > span {
    color: var(--white)
}

.now-what-guidance-checklist-item-header {
    font-weight: 600;
    color: var(--white);
}

.now-what-guidance-checklist-item-header-mobile {
    font-weight: 600;
}



.now-what-guidance-checklist-item-text {
    color: var(--white);
    white-space: break-spaces;
}