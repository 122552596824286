.about-us-image {
    height: 30vh;
    border-radius: 15px;
}

.about-us-got-questions-text {
    color: var(--page_background);
    font-size: 1.5rem;
}

.clip-svg {
    width: 1280px;
    /* Adjust the width to match your SVG */
    height: 1182px;
    /* Adjust the height to match your SVG */
    position: absolute;
    /* Position the SVG container */
    top: 0;
    left: 0;
    z-index: -1;
    /* Place it behind other content */
}

.clipped {
    /* clip-path: url(#weird_shape);
    overflow-clip-margin: content-box;
    overflow: clip; */
    /* Use the ID of the clip path */
}