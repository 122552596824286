.cost-list-cost-list {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  /* left: -5517px; */
  /* top: 1087px; */
  /* gap: 20px; */
}

.cost-list-line {
  align-self: stretch;
  height: 48px;
  min-width: 1px;
  object-fit: cover;
  position: relative;
}
