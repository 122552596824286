.auth-user-header-text {
    color: var(--page_background)
}

.button-text {
    color: var(--white);
    text-align: center;
    width: 100%;
    min-width: 10vw;
}

.auth-modal-return-to-previous-container {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    background-color: var(--accent_orange);
    color: var(--white);
    cursor: pointer;
    display: flex;
    margin-left: 2%;
    padding: .5vh 1rem;
    border-radius: 5px;
    align-items: center;
}

.auth-modal-return-to-previous-container-text {
    font-weight: 800;
    color: var(--white);
}

.auth-user-choices-container {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    background: var(--hub_background);
    padding: 2vw;
    border-radius: 2vw;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.auth-user-choices-container-modal {
    background: none;
}

.auth-user-choices-container-desktop {
    width: 70vw;
}
.auth-user-choices-container-tablet {
    width: 90vw;
}
.auth-user-choices-container-mobile {
    width: 95vw;
}

.user-auth-choice-box {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    align-items: center;
    width: 100%;
    text-align: center;
}

.auth-user-reasons-to-join-container {
    flex-direction: column;
    /* display: flex; */
    gap: 1vh;
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(4vw, 4fr)); */
    grid-template-columns: repeat(auto-fit, minmax(7rem, 2fr));
    width: 100%;
    justify-items: center;
    justify-content: center;
}

.auth-user-reason-to-join-box{
    display: flex;
    gap: 1vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.auth-user-reason-to-join-text {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 1rem;
    text-wrap: balance;
}

.auth-user-reason-to-join-check{
    color: var(--page_background);
}

.user-auth-choice-button {
    display: flex;
    background: var(--accent_orange);
    cursor: pointer;
    border-radius: 5px
}

.user-auth-view-toggle-text {
    color: var(--page_background);
    font-size: var( --h4-font-size) !important;
    cursor: pointer;
    text-align: center;
}

.user-auth-view-toggle-text:hover {
    cursor: pointer;
    color: var(--black);
}

.user-auth-form-error-message {
    color: red;
    font-style: italic;
}

/* .user-auth-signup-form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
} */