
.hub-header-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 2vh 0vw;
  position: relative;
  justify-content: space-between;
  z-index: 2;
  flex-wrap: wrap;
  /* padding: 0px 2.5vw; */
}

@media (min-width: 769) {
  .hub-header-frame {
    /* padding: 0px 2.5vw; */
    justify-content: center;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-header-frame {
    /* padding: 0px 1.5vw; */
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .hub-header-frame {
    padding: 0px 0px;
    justify-content: center;
  }
}

.hub-header-title {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  color: #262626;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.3px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.play-with-data-bar {
  cursor: pointer;
  display: flex;
  align-self: stretch;
}

.hub-header-toggle-bar-frame {
  align-items: center;
  align-self: inherit;
  min-height: 7vh;
  background-color: var(--white);
  /* border: 1px solid var(--white); */
  gap: 0vh 2vw;
  display: flex;
  position: relative;
  width: fit-content;
}


@media (min-width: 769) {
  .hub-header-toggle-bar-frame {
    border-radius: var(--border_radius_bar);
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-header-toggle-bar-frame {
    border-radius: var(--border_radius_container);
  }
}

@media (max-width: 480px) {
  .hub-header-toggle-bar-frame {
    border-radius: var(--border_radius_container);
    width: 100%;
    gap: 0vh .5vw;
    overflow: auto;
  }
}

.hub-header-tablet-desktop {
  display: flex;
  flex-direction: row;
}