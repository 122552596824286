.hub-saved-projects-collection-frame {
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(30rem, max-content));
  justify-content: space-evenly;
  align-self: stretch;
  align-items: stretch;
  gap: 10px;
  display: grid;
  position: relative;
}

.hub-saved-projects-table-row-label {
  /* color: var(--page_background) !important; */
  font-weight: 600 !important;
  width: 10vw;
  text-align: center !important;
  text-wrap: balance !important;
  border-right: 2px solid var(--black) !important;
  background-color: var(--white);
}

.hub-saved-projects-table-row-label-blank {
  background-color: var(--table-grey)
}

.hub-saved-projects-table-row-datapoint-cell {
  /* color: var(--page_background) !important; */
  /* font-weight: 600 !important; */
  width: clamp(150px, 20vw, 400px);
  text-align: center !important;
  align-items: center !important;
  /* text-wrap: balance !important; */
}

.with-border {
  border-right: 2px solid var(--black) !important;
}

.hub-saved-projects-table {
  max-width: 90%;
  overflow-x: auto !important;
  border-radius: 10px !important;
  box-shadow: 3px 3px 4px #00000073 !important;
  /* background-color: var(--white) !important; */
}

.hub-saved-projects-table::-webkit-scrollbar {
  height: 10px;
  position: absolute;
}

.hub-saved-projects-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.hub-saved-projects-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--page_background);
}

.hub-saved-projects-table::-webkit-scrollbar-button:start:increment {
  height: 2%;
  display: block;
  background: transparent;
}

.hub-saved-projects-table::-webkit-scrollbar-button:end:increment {
  height: 2%;
  display: block;
  background: transparent;
}

.hub-saved-project-image-and-name-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  align-items: center;
}

.hub-saved-project-name-text {
  font-weight: 600;
  color: var(--page_background);
  text-align: center;
  text-wrap: balance;
}

.hub-saved-project-image-container {
  height: 10vh;
  width: 90%;
  width: inherit;
  display: flex;
  justify-content: center;
}

.hub-saved-project-image {
  object-fit: cover;
  height: inherit;
  width: inherit;
  border-radius: 15px;
}

.hub-saved-project-open-container {
  background-color: var(--accent_orange);
  padding: .5vh 1vw;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 2vh;
}

.hub-saved-project-open-container {
  color: var(--white);
  font-weight: 600;
}

.hub-saved-project-description-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  align-items: center;
  width: 100%;
  padding: 0 1vw;
}

.hub-saved-project-description {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.hub-saved-project-description-expand-text {
  color: var(--accent_orange);
  font-weight: 600;
  cursor: pointer;
}

.hub-saved-project-house-category-container {
  flex-direction: row;
  align-items: center;
  gap: 2vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.hub-saved-project-house-category-image-container {
  height: 5vh;
  width: inherit;
  display: flex;
}

.hub-saved-project-house-category-text {
  font-weight: 600;
  /* color: var(--page_background); */
}

.hub-saved-projects-checkmark {
  color: var(--page_background);
}

.hub-saved-projects-checkmark-text {
  font-weight: 400;
  flex: 1;
}

.hub-saved-project-discount-icons-container {
  gap: 1vh 2vw;
  display: grid;
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(auto-fit, minmax(7rem, 2fr));
  width: 100%;
}

.hub-saved-project-discount-icons-image-container {
  height: clamp(50px, 10vh, 100px);
  /* width: 90%; */
  width: inherit;
  display: flex;
}

.hub-saved-project-discount-icons-image {
  object-fit: contain;
  height: inherit;
  width: inherit;
  border-radius: 15px;
}