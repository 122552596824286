.meter-container {
  box-shadow: unset;
  /* flex: 1 1 0%; */
  left: unset;
  top: unset;
  width: unset;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding: 10px;
  position: relative;
  display: flex;
  align-self: stretch;
}

.meter-headline {
  color: var(--page_background);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 22px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.meter-value {
  color: var(--black);
  letter-spacing: -.22px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-size: 1rem;
  font-weight: 600;
  line-height: 26.4px;
  position: relative;
}

.meter-bar-text {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
}