.sign-in-container {
    display: flex;
    /* flex-direction: row; */
    /* gap: 5vw; */
    width: 100%;
    justify-content: space-around;
}

.sign-in-container-column {
    flex-direction: column;
    gap: 2vh;
    align-items: center;
}

.sign-in-container-row {
    flex-direction: row;
}

.signin-up-or-text {
    font-weight: 400;
    font-style: italic;
    font-family: larken, sans-serif !important;
}

.sign-in-form-container {
    display: flex;
    flex-direction: column;
}

.user-auth-signup-form-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    /* width: 20vw; */
}

.user-auth-signup-form-container * {
    width: inherit;
}


.sso-container {
    display: flex;
    /* width: 25vw; */
    justify-content: center;
    align-items: center;
}

.sso-container iFrame {
    width: inherit;
}

.sso-image {
    height: 50%
}

.vertical-divider{
    border-left: 2px solid var(--page_background);
  /* height: 500px; */
}