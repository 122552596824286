.social-share-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
  gap: 1vw;
  top: 5rem;
  background-color: var(--white);
  padding: .75rem;
  border-radius: .25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 1;
  transform: translate(-4vw, -1vh);
  transition: opacity 150ms ease-in-out, transform 350ms ease-in-out;
}

.social-share-icon-formatting {
  color: var(--page_background);
  cursor: pointer;
}