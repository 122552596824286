.project-materials-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  gap: 2vh;
  padding: 2vh 1vw;
  text-align: center;
}

.project-card-accordian-table-container {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: flex;
  position: relative;
}

.project-card-accordian-table-frame {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;

}

.project-card-accordian-table-header {
  align-self: stretch;
  left: unset;
  top: unset;
  width: unset;
  align-items: flex-start;
  gap: 20px;
  padding: 4px 10px;
  display: flex;
  position: relative;
}

.project-card-accordion-table-header-content {
  color: #777;
  letter-spacing: .55px;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
}

.project-card-accordion-table-header-content-flex {
  color: #777;
  letter-spacing: .55px;
  white-space: nowrap;
  flex: 1;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
}

.project-card-accordian-table-data-row {
  align-self: stretch;
  left: unset;
  top: unset;
  width: unset;
  align-items: center;
  gap: 20px;
  padding: 4px 10px;
  display: flex;
  position: relative;
  border-color: #dedede;
  border-top-style: solid;
  border-top-width: 1px;
}

.project-card-accordian-table-data-row-icon-frame {
  width: 80px;
  align-items: flex-start;
  padding: 3px 0;
  display: flex;
  position: relative;
}

.project-card-accordian-table-data-row-icon {
  position: relative;
  background-size: 100% 100%;
  height: 40px;
  flex: 1 1 0%;
}

.project-card-accordian-table-data-row-description {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.project-card-accordian-table-data-row-cost {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 180px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: relative;
}

.project-card-accordian-table-data-row-button-frame {
  width: 50px;
  background-color: #468347;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3px 5px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project-card-accordian-table-data-row-button-text {
  color: #fff;
  letter-spacing: .36px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: relative;
}

/* .MuiDataGrid-colCellTitleContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .MuiDataGrid-colCellTitleContainer { */
  /* white-space: normal; */
  /* word-wrap: break-word; */
/* } */

.header-cell .MuiDataGrid-colCellTitle {
  text-overflow: unset;
}

.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
  overflow: visible !important;
  white-space: normal !important;
}

.data-table-cost {
  font-size: 1rem;
}

.MuiDataGrid-iconButtonContainer {
  visibility: hidden;
  /* width: auto; */
}