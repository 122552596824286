.start-button-frame {
  align-items: center;
  background-color: var(--accent_orange);
  display: flex;
  gap: 10px;
  height: 5vh;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  width: fit-content;
}

.start-button-frame-what-to-do {
  height: 4vh;
  cursor: pointer;
}

.start-button-frame-extra-padding-true {
  padding: .5vh 5vw;
}

.start-button-frame-extra-padding-false {
  padding: 1vh 1vw;
}

.start-button-frame-guidance-navigation-true {
  height: fit-content !important;
  padding: .5vh .5vw !important;
  width: fit-content !important;
}

.start-button-text {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: normal; */
  position: relative;
  width: fit-content;
}