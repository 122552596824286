.project-bar-view-frame {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  /* height: 80px; */
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 1px solid;
}

/* .project-bar-view-frame-mobile {
  height: 15vh;
  
} */

.project-bar-data-frame-mobile {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  justify-content: space-between;
  gap: 1.5vh;
}

.project-bar-view-image-overlay {
  background-color: rgba(0, 0, 0, 0.65);
  height: inherit;
  width: inherit;
  position: absolute;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.project-bar-view-image-container {
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 10px 20px 10px 10px;
  position: relative;
  justify-content: center;
}

.project-bar-view-image-container-mobile {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0px;
  display: flex;
  position: relative;
}

.project-bar-view-image {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  position: relative;
}

.project-bar-view-mobile-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-bar-view-header-container {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.project-bar-view-header-title {
  align-self: stretch;
  color: #262626;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25.2px;
  position: relative;
  white-space: normal;
}

.project-bar-view-header-title-mobile {
  text-align: center;
  color: var(--hub_background);
}

.project-bar-view-header-risks-container {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  position: relative;
  width: fit-content;
}