.value-hub-frame {
    background-color: #fff;
    border-radius: 5px;
    justify-content: center;
    align-self: stretch;
    align-items: flex-start;
    gap: 6vh;
    padding: 30px;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    position: relative;
}

.value-hub-intro-container {
    display: flex;
    flex-direction: column;
    gap: 3vw;
    text-align: center;
}

.value-hub-factors-container {
    display: flex;
    flex-direction: row;
    gap: 3vw;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
}

.value-hub-intro-paragraph {
    color: #262626;
    letter-spacing: 0;
    align-self: stretch;
    font-weight: 400;
    position: relative;
    text-align: center;
}

.value-hub-condition-bills-container {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    justify-content: space-evenly;
}

.value-hub-condition-bills-two-images-container {
    display: flex;
    flex-direction: row;
    height: 5vh;
    gap: 2vw;
}

.value-hub-condition-bills-images-container {
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 2vh 2vw;
    /* width: 80%; */
    padding: 0vh 10vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 4fr));
    justify-items: center;
}

.value-hub-condition-bills-image {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2vh;
    align-self: stretch;
    display: flex;
}

.hub-value-plus-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--accent_orange);
}

.hub-value-data-category-choices-container {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    cursor: pointer;
    width: 100%;
    align-items: center;
}

.hub-value-data-choices-grid {
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    justify-content: center;
    justify-items: center;
    gap: 1vw;
    row-gap: 1vh;
    width: 100%;
    display: grid;
    position: relative;
}

.hub-value-individual-dataset-container {
    height: 15vh;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    padding: 0px;
    border-radius: 15px;
    overflow: hidden;
}

.hub-value-individual-dataset-container-selected {
    background-color: none;
    border-top: 2px solid var(--page_background);
    border-left: 2px solid var(--page_background);
}

.hub-value-individual-dataset-container-overlay {
    height: 100%;
    width: 100%;
    background-color: #00000085;
    /* border-bottom: 1px solid; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 .5vw;
    display: flex;
    position: absolute;
    /* border-radius: 15px; */
}

.hub-value-individual-dataset-container-overlay-not-selected {
    background-color: #000000cc;
}

.hub-value-individual-dataset-container-overlay-text {
    color: var(--hub_background);
    letter-spacing: 0;
    white-space: normal;
    text-align: center;
    align-self: stretch;
    font-weight: 600;
    position: relative;
}

.hub-value-reminder-text-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.hub-value-reminder-text {
    text-align: center;
    font-size: 1.25rem;
    font-style: italic;
}

.related-category-shadow-selected {
    background-color: #115453;
    width: 5vw;
    height: 0.5vh;
    border-radius: 15px;
}

.related-category-shadow-explainer {
    background-color: #f27e7e70;
    width: 5vw;
    height: 0.5vh;
    border-radius: 15px;
}

.hub-value-category-stats-grid {
    /* flex-direction: row; */
    gap: 3vh 1vw;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 4fr));
}

.hub-value-category-context-text {
    text-align: center;
}

.affordability-hub-risk-score-container {
    display: flex;
    flex-direction: row;
    gap: 5%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.affordability-hub-risk-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
}

.affordability-hub-risk-text-header {
    font-size: 1rem;
    font-weight: 400;
}

.affordability-hub-risk-text-context {
    font-size: 1.25rem;
    font-weight: 600;
}