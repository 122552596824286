.climate-blurb-frame {
  align-items: center;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  padding: 10px 0px;
  position: relative;
  flex-direction: row;
  gap: 1vh;
  width: 80%;
}

.climate-blurb-frame-mobile {
  flex-direction: column;
  gap: 1vh;
  align-items: center;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  padding: 10px 0px;
  position: relative;
}

.climate-blurb-image-container {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
  width: 20vw;
  justify-content: center;
}

.climate-blurb-image-container-mobile {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
  height: inherit;
}

.climate-blurb-image {
  height: 100px;
  min-width: 100px;
  position: relative;
}

.climate-blurb-image-mobile {
  height: 100%;
  min-width: 100%;
  object-fit: contain;
  /* position: relative; */
}

.climate-blurb-text-frame {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2vh;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
}

.climate-blurb-headline-container {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.climate-blurb-headline-container-mobile {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  width: 100%;
  height: 3rem;
  justify-content: center;
}

.climate-blurb-risk-name {
  color: #262626;
  /* font-size: 22px; */
  font-weight: 600;
  /* letter-spacing: -0.22px; */
  /* line-height: 26.4px; */
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.climate-blurb-paragraph {
  align-self: stretch;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
}

.climate-blurb-paragraph-mobile {
  text-align: center;
}

.climate-blurb-expand-details {
  cursor: pointer;
  color: var(--page_background);
  /* font-size: 14px; */
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 19.6px; */
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
