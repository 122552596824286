.value-prop-item-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  padding: 10px 30px;
  position: relative;
  min-height: 35vh;
  /* max-height: 45vh; */
  /* width: 25vw; */
}

.value-prop-item-text {
  align-self: stretch;
  color: #115453;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  position: relative;
  text-align: center;
}

.value-item .icon {
  height: 156.69px !important;
  margin-top: -0.59px !important;
  min-width: 170px !important;
  position: relative !important;
}
