.cost-label-cost-label {
  align-items: center;
  /* border-radius: 5px; */
  gap: 1vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
}

.cost-label-estimated-cost {
  color: #777777;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.cost-label-element {
  color: #768b76;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
