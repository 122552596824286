.footer-frame {
  align-items: center;
  border-top: 1px solid var(--border_accent);
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-top: auto;
  padding: 2vh 1vw;
  position: relative;
  width: auto;
  width: 100%;
}

.footer-logo-company-description-container {
  justify-content: space-around;
  display: flex;
  gap: 5vw;
  position: relative;
  width: 100%;
  align-items: center;
}

.footer-logo-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 2px 20px 0px 0px;
  position: relative;
  max-width: 30%;
}

.footer-logo-img-container {
  min-width: 150px;
}

.footer-logo-image {
  max-height: 6vh;
  /* min-width: 10vw; */
  position: relative;
}

.footer-company-description {
  align-self: stretch;
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.footer-links-grid-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  gap: 0.5vh .5vw;
  row-gap: 1vh;
  display: grid;
  flex: 1;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.footer-links {
  color: var(--white);
  font-size: 1rem;
}

.footer-div {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
}

.footer-text-wrapper {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.footer-social-icons-container {
  align-items: center;
  display: flex;
  gap: 2vh 1vw;
  position: relative;
  width: fit-content;
  min-width: 10vw;
  justify-content: center;
}

@media (min-width: 1024px) {
  .footer-social-icons-container {
    flex-direction: row;
    padding: 10px 0px;
  }

}

@media (min-width: 770px) and (max-width: 1023px) {
  .footer-social-icons-container {
    flex-direction: row;
    padding: 10px 0px;
  }

}

@media (max-width: 769px) {
  .footer-social-icons-container {
    flex-direction: row;
  }

}

.footer-social-icon-box {
  border-radius: 20px;
  height: 30px;
  width: 30px;
  position: relative;
}

.footer-social-icon {
  object-fit: contain;
  height: inherit;
  width: inherit;
}

.footer-bottom-bar-frame {
  align-items: flex-start;
  border-color: #0000001a;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.footer-legal-links {
  display: flex;
  position: relative;
  gap: 1vw;
}

.footer-legal-links-span {
  color: var(--white);
  font-size: .8rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
}

.footer-legal-links-seperator {
  color: var(--white);
  font-size: .8rem;
  font-weight: 600;
}

.footer-text-wrapper-3 {
  color: var(--white);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
}

.footer-element-copyright-quoll-intelligence {
  color: var(--white);
  font-size: .8rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  text-wrap: balance;
}