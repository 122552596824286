.climate-icon-frame-default {
  border-radius: 80px;
  width: 5vw;
  height: 5vw;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 2.8px;
  /* top: 20px; */
}

@media (min-width: 1101) {
  .climate-icon-frame-default {
    width: 6vw;
    height: 6vw;
  }
}

@media (min-width: 770px) and (max-width: 1100px) {
  .climate-icon-frame-default {
    width: 8vw;
    height: 8vw;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .climate-icon-frame-default {
    width: 10vw;
    height: 10vw;
  }
}

@media (max-width: 480px) {
  .climate-icon-frame-default {
    width: 15vw;
    height: 15vw;
  }
}

.climate-icon-frame-selected {
  border: solid;

}

.climate-icon-color-background {
  align-items: center;
  align-self: stretch;
  border-radius: 80px;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
}

.climate-icon-color-high {
  background-color: #e73b15;
}

.climate-icon-color-medium {
  background-color: #FFDB33;
}

.climate-icon-image {
  position: relative;
  height: 60%;
  /* min-width: 23.8px; */
}

.icon-climate-v5-Clear {
  height: 38px;
  min-width: 38px;
}

.icon-climate-v5-class {
  height: 34px;
  min-width: 34px;
}

.icon-climate-v5-Clear-circle {
  background-color: #dedede;
}

.icon-climate-v5-class-4 {
  background-color: #a5c941;
}

.icon-climate-v5-Variant4 {
  border: 1px solid;
  border-color: #262626;
  left: 296px;
}

.icon-climate-v5-Clear-property-high-selected {
  border: 1px solid;
  border-color: #777777;
  left: 204px;
}

.icon-climate-v5-HighDefault {
  left: 112px;
}

.icon-climate-v5-HighSelected {
  border: 1px solid;
  border-color: #777777;
  left: 20px;
}
