.about-us-frame {
  align-items: center;
}

.about-us-header {
  color: var(--hub_background);
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  align-self: center;
}

.about-us-container {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  flex-wrap: wrap;
  justify-content: center;
}

.about-us-image-container {
  /* width: 50vw; */
  display: flex;
  justify-content: center;
}

.about-us-text-container {
  color: white;
}

.about-us-text {
  color: var(--white);
  text-align: center;
}

.about-us .message-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px 10px 30px;
  position: relative;
}

.about-us-bio-container {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

.about-us .person-instance {
  flex: 1 !important;
  width: unset !important;
}

.about-us .person-2 {
  color: #f6e6db !important;
  font-size: 40px !important;
  font-weight: 400 !important;
  line-height: 60px !important;
}

.about-us .person-3 {
  color: #f6e6db !important;
}

.about-us .person-4 {
  color: #f6e6db !important;
}

.about-us .person-5 {
  flex: 1 !important;
  width: unset !important;
}

.about-us .person-6 {
  color: #f6e6db !important;
  font-size: 40px !important;
  font-weight: 400 !important;
  line-height: 60px !important;
}

.about-us .person-7 {
  color: #f6e6db !important;
}

.about-us .person-8 {
  color: #f6e6db !important;
}


