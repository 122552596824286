.what-now-button-frame-overlay {
    background-color: rgba(0, 0, 0, 0.65);
    height: inherit;
    width: inherit;
    position: absolute;
    border-bottom: 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 1vw;
}

.what-now-button-frame {
    background-color: var(--accent_orange);
    cursor: pointer;
    align-items: flex-start;
    border-radius: 60px;
    display: flex;
    /* border: 3px solid var(--black); */
    flex-direction: row;
    position: fixed;
    width: fit-content;
    height: 4rem;
    z-index: 1;
    /* bottom: calc(4%); */
    bottom: calc(15%);
    /* right: calc(3%); */
    right: calc(1%);
}

.what-now-button-icon {
    color: var(--accent_orange);
    font-size: var(--h2-font-size) !important;
}

.what-now-button-image-frame-tablet,
.what-now-button-frame-mobile {
    flex-direction: column;
    align-items: center;
    padding: 1%;
    border-radius: 20px;
}

.what-now-button-image-frame-desktop {
    height: 4rem;
    width: 4rem;
    left: -1rem;
    border-radius: 60px;
    overflow: hidden;
    z-index: 5;
    position: absolute;
    top: 50%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    outline: 3px solid var(--page_background);
}

.what-now-button-image-frame-tablet,
.what-now-button-image-frame-mobile {
    /* left: -1rem; */
    /* top: 50%; */
    /* -ms-transform: translateY(-50%); */
    /* transform: translateY(-50%); */
    /* z-index: 5; */
    height: 3rem;
    width: 3rem;
    border-radius: 60px;
    overflow: hidden;
    display: flex;
    position: relative;
}

.what-now-button-image {
    /* border: 3px solid var(--accent_orange); */
    object-fit: cover;
    height: 100%;
    /* Use 100% height to ensure the image takes the full height of the container */
    width: auto;
    /* Allow the image width to adjust proportionally */
}

.what-now-button-image-tablet,
.what-now-button-image-mobile {
    border: none;
}

.now-what-icon-text-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 3.5rem;
    /* Add padding to the left to account for the image */
}

.now-what-icon-text-container-tablet, 
.now-what-icon-text-container-mobile {
    /* flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; */
    padding-left: 0;
    /* Add padding to the left to account for the image */
}

.now-what-icon-text {
    color: var(--white);
    font-weight: 800;
    line-height: unset !important;
    margin-right: 1rem;
}

.now-what-icon-text-tablet, 
.now-what-icon-text-mobile {
    padding: 0 1vw;
    margin: 0;
    text-wrap: balance;
    max-width: 20vw;
}