.now-what-header-headline-text {
    font-weight: 800;
}

.now-what-score-container {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    align-items: center;

}

.now-what-score-container-text-container {
    display: flex;
    flex-direction: row;
    /* gap: 1vw; */
}


.now-what-score-container-text {
    font-weight: 600;
}

.now-what-score-description-text {
    color: var(--black);
    text-align: center;
  }
  