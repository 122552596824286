.project-impact-section-frame {
  display: flex;
  flex-direction: column;
  gap: 4vh;
  background-color: var(--white);
  border-radius: 5px;
  padding: 2vh 1vw;
  width: 100%;
}

.project-impact-solving-for-text {
  text-align: center;
}

.project-impact-frame {
  /* box-shadow: unset; */
  /* left: unset; */
  /* top: unset; */
  /* width: unset; */
  flex-direction: row;
  flex: 1;
  justify-content: space-evenly;
  align-self: stretch;
  align-items: center;
  gap: 2vh 1vw;
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.project-impact-bar-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.project-impact-bar {
  display: flex;
  width: 15vw;
  height: 1vh;
  background-color: var(--white);
  border-radius: 15px;
  overflow: hidden;
  align-self: center;
}

.project-impact-bar-title {
  width: 100%;
  color: var(--page_background);
  text-align: center;
  /* display: flex; */
  /* border-radius: 15px; */
  /* overflow: hidden; */
}

.project-impact-value {
  background-color: var(--accent_orange);
  width: 25%;
}

.project-impact-bar-scale-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.project-impact-bar-scale-text {
  font-size: .8rem;
  color: var(--page_background);
  font-style: italic;
}

.project-impact-checkmarks-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-top: 1vh;
}

.project-impact-checkmark-item {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  align-items: center;
}

.project-impact-checkmark-true {
  color: var(--accent_orange);
}

.project-impact-checkmark-false {
  color: var(--grey);
}

.project-impact-item-text-true {
  color: var(--accent_orange);
  font-size: 1rem;
  font-weight: 600;
}

.project-impact-item-text-false {
  color: var(--grey);
  font-size: 1rem;
  font-weight: 400;
}