.error-text {
    color: var(--white);
}

.user-auth-signup-form-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)); */
    justify-items: center;
    /* gap: 2vh 2vw; */
    width: 100%;
    /* display: grid; */
    position: relative;
}

.user-auth-signup-form-container-row > div {
    background-color: var(--white);
    border-radius: 5px;
    min-width: 35rem;
}

.terms-and-conditions-link {
    color: var(--page_background);
}

.input-label-top-view-container {
   background-color: var(--white);
   border-radius: 5px;
   padding: 0% 1% !important;
   color: var(--page_background) !important;
   font-weight: 600 !important;
   /* border: 1px solid var(--page_background); */
}

.input-label-top-view-container label {
    color: var(--page_background) !important;

}