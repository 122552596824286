.project-status-bar-property-to-do {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 30px;
}

.project-status-bar-frame {
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  height: 16px;
  min-width: 16px;
  position: relative;
}

.project-status-bar-icons-system {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}
