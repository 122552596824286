.report-score-and-maps-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  /* gap: .25vw; */
  height: clamp(150px, 10vh, 400px);
  justify-content: space-between;
  position: relative;
}

.report-score-streetview {
  background-position: 50% 50%;
  background-size: cover;
  left: 0;
  position: relative;
  border-radius: var(--border_radius_container);
  height: inherit;
  width: 66%;
}

.report-score-streetview-mobile {
  width: 100%;
}

.report-score-satellite-view {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: var(--border_radius_container);
  position: relative;
  height: inherit;
  width: 33%;
}

.missing-streetview-image-overlay {
  background-color: #000000b8;
  height: 100%;
  position: relative;
  width: 100%;
  border-radius: var(--border_radius_container);
}

.missing-streetview-image-text {
  font-style: italic;
  font-family: larken, sans-serif !important;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--hub_background);
}