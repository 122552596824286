.form-entry-container {
    background: var(--white);
    border: 1px solid #dedede;
    border-radius: 15px;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    padding: 5px 10px;
    display: flex;
    position: relative;
    justify-content: space-between;
    width: inherit;
    gap: 1vh;
}

.form-entry-frame {
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    position: relative;
    gap: .5rem;
}

.form-entry-headline {
    letter-spacing: .11px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 15.4px;
    position: relative;
    align-self: center;
    white-space: break-spaces;
    text-align: center;
}

.form-entry-input {
    color: #262626;
    letter-spacing: 0;
    white-space: nowrap;
    align-self: stretch;
    position: relative;
    align-self: center;
    display: flex;
    justify-content: center;
}

.form-entry-input-box {
    outline: none;
    border: 1px solid #dadce0;
    border-radius: 5px;
    text-align: center;
    width: 85%;
}


.did-you-know-icon {
    height: 30px;
    min-width: 30.31px;
    position: relative;
}

.did-you-know-text {
    color: #262626;
    letter-spacing: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    position: relative;
}

input:focus::placeholder {
    color: transparent;
    font-style: italic;
}