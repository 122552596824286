.what-now-quoll-guidance-frame {
    display: flex;
    flex-direction: column !important;
    gap: 2vh;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.what-no-account-button-container {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
}

.what-now-account-buttons-frame {
    padding-bottom: 3vh;
}