.blog-frame {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 4vh;
    padding: 5vw 10vw;
    display: flex;
    position: relative;
    /* background-color: var(--white); */
    /* padding-top: 5vh; */
}

.blog-header {
    display: flex;
    flex-direction: column;
    /* background-color: rgba(0, 0, 0, 0.4); */
    background-position: 50% 50%;
    background-size: contain;
    width: 100%;
    /* height: 20vw; */
    min-height: 25vw;
    justify-content: center;
    align-items: left;
    position: relative;
}

.blog-header-overlay {
    width: 100%;
    height: 100%;
    opacity: .7;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
}

.blog-image {
    object-fit: contain;
    height: inherit;
    width: 100%;
    opacity: .3;
    /* max-height: 25vw; */
    /* min-height: 20vw; */

}

.blog-header-text {
    display: flex;
    flex-direction: column;
    z-index: 2;
    gap: 2vh;
    padding: 1vh 10vw;
}

@media (min-width: 480px) {

    .blog-header-text span,
    .blog-header-text p,
    .blog-header-text h1,
    .blog-header-text h2 {
        text-align: left !important;
        text-wrap: balance;
    }
}

@media (max-width: 480px) {

    .blog-header-text span,
    .blog-header-text p,
    .blog-header-text h1,
    .blog-header-text h2 {
        text-align: center !important;
        text-wrap: balance;
    }

    .blog-tags {
        align-self: center;
    }

    .blog-frame {
        padding: 2vh 5vw;
    }
}

.blog-header-font {
    color: var(--white)
}

.blog-header-headline {
    color: var(--hub_background);
    font-size: 6vh;
    text-wrap: balance;
}

.blog-header-category {
    font-style: italic;
    color: var(--accent_orange)
}

.blog-header-author {
    color: var(--hub_background);
    font-size: 1rem;
}

.blog-tags {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    color: var(--hub_background);
}

.blog-paragraph {
    color: var(--black)
}

.blog-li {
    list-style-type: circle;
    list-style-position: inside;
}

.blog-ol {
    margin-left: 5%;
    list-style: disc;
    color: var(--page_background);
}

.italic {
    font-style: italic;
}

.blog-related-resources {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6vh;
}