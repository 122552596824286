.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    background: var(--hub_background);
    width: 100%;
    border-radius: var(--border_radius_container);
    padding: 30px;
}

.dashboard-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dashboard-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 4vw; */
    justify-content: center;
    gap: 1vh;
}

.dashboard-hero-image {
    width: inherit;
    object-fit: cover;
    max-height: 20vh;
    border-radius: 25px;
    opacity: .6;
}

.dashboard-title {
    color: var(--page_background);
}

.dashboard-signout-button-container {
    width: fit-content;
    padding: 0.5vh 1vw;
    /* background: var(--accent_orange); */
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
}

.dashboard-signout-button-container-signout {
    background: var(--accent_orange);
}

.dashboard-signout-button-container-book-time {
    background: var(--page_background);
}

.dashboard-signout-button-text {
    font-size: 1rem;
    font-weight: 600;
    color: var(--white);
}

.dashboard-my-saved-homes-container {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.dashboard-my-saved-homes-grid {
    row-gap: 2vh;
    column-gap: 2vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* justify-content: flex-start; */
}

.dashboard-my-saved-homes-grid-desktop {
    justify-content: flex-start;
}

.dashboard-my-saved-homes-grid-tablet {
    justify-content: center;
}

.dashboard-my-saved-homes-grid-mobile {
    justify-content: center;
}