:root {
    /* font-family: 'Arial', sans; */
    /* font-family: 'Karla', sans-serif !important; */
    /* font-family: halyard-display, sans-serif; */
    font-family: area-normal, sans-serif;
    font-size: 18px;

    /* Original Color Styling */
    --page_background: #ffffff;
    --hub_background: #f1f1f1;
    --modal_background: #bdbdbd;
    --accent_dark: #ffffff;
    --accent_light: #468347;
    --text-title: #000000;

    /* New Color Styling */
    --page_background: #115453;
    /* --hub_background: #ebdcc7; */
    /* --hub_background: #f2e1d4; */
    --hub_background: #F6E6DB;
    --modal_background: #d3d2d2;
    --accent_orange: #D8A270;
    --border_accent: #367e79;
    --accent_dark: #d3d3d3;
    --accent_light: #7b9478;
    --not_selected_text: #a8a7a7;
    --text-title: #ffffff;
    /* --yellow: #efd978; */
    --yellow: #fcb500;
    --grey: #777777;
    --table-grey: #cac6c6;
    --light-grey: #dadce0;
    /* --black: #333333; */
    --black: #454545;
    --white: #f8f5f5;
    --red: #F14D4D;
    --related_red: #f27e7e;
    /* --red: #e55534; */

    /* Borders + Border Radius */

    --border_radius_container: 10px;
    --border_radius_bar: 50px;

    /* Breakpoints */
    --small-screen-max: 700px;

    /* Font Sizes */
    --h1-font-size: clamp(2rem, 3.5vw, 4.5rem) !important;
    --h2-font-size: clamp(1.5rem, 3.25vw, 2.5rem) !important;
    --h3-font-size: clamp(1.25rem, 2.75vw, 2rem) !important;
    --h4-font-size: clamp(1rem, 2vw, 1.5rem) !important;
    --p-font-size: clamp(1rem, 1.4vw, 1.4rem) !important;
    --span-xxs-font-size: clamp(.5rem, .5vw, .5rem) !important;
    --span-xs-font-size: clamp(.7rem, .8vw, 1rem) !important;
    --span-s-font-size: clamp(.75rem, .9vw, 1.1rem) !important;
    --span-m-font-size: clamp(.8rem, 1.25vw, 1.2rem) !important;
    --span-l-font-size: clamp(1rem, 1.4vw, 1.4rem) !important;
    --span-xl-font-size: clamp(1.2rem, 1.75vw, 1.75rem) !important;
    --span-xxl-font-size: clamp(2rem, 3vw, 3rem) !important;

}

.app-style {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
}

/* Globals based on content type */

html {
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100vh;
    background-color: var(--page_background);
    overflow-x: hidden;
}

h1 {
    font-size: var(--h1-font-size) !important;
    color: var(--text-title);
    font-family: halyard-display, sans-serif !important;
    font-weight: 300 !important;
    font-style: normal;
    line-Height: 1.1em !important;
    letter-spacing: normal !important;
}

h2 {
    font-family: halyard-display, sans-serif !important;
    font-size: var(--h2-font-size) !important;
    line-Height: 1.1rem;
    line-height: normal;
    font-weight: 300 !important;
    text-wrap: balance !important;
    text-align: center;
}

h1 span,
h2 span {
    line-height: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    font-family: larken, sans-serif !important;
    color: inherit !important;
}

h3 {
    font-family: halyard-display, sans-serif !important;
    /* font-size: clamp(1.25rem, 2.75vw, 2rem) !important; */
    font-size: var(--h3-font-size) !important;
    line-Height: 1.1rem;
    font-weight: 400;
    text-wrap: balance !important;
}

h4 {
    font-family: halyard-display, sans-serif !important;
    /* font-size: clamp(1rem, 2vw, 1.5rem) !important; */
    font-size: var(--h4-font-size) !important;
    line-Height: 1.1rem;
    font-weight: 400 !important;
}

p {
    /* font-size: clamp(1rem, 1.4vw, 1.4rem) !important; */
    font-size: var(--p-font-size) !important;
    color: var(--black);
    line-height: 1.4em !important;
    font-weight: 500;
    text-wrap: balance !important;
}

input {
    font-size: var(--span-s-font-size) !important;
    color: var(--black);
    /* line-height: 1.4em !important; */
    font-weight: 400;
}

i {
    font-style: italic;
}

svg {
    overflow: visible;
}

span {
    /* font-size: .5rem; */
    /* font-size: .8vw !important; */
    line-height: 1.4em !important;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: clamp(.8rem, 1.25vw, 1.2rem);
    color: var(--black)
}

.span-xxs {
    font-size: var(--span-xxs-font-size) !important;
}

.span-xs {
    font-size: var(--span-xs-font-size) !important;
}

.span-s {
    /* font-size: clamp(.75rem, .9vw, 1.1rem) !important; */
    font-size: var(--span-s-font-size) !important;
}

.span-m {
    /* font-size: clamp(.8rem, 1.25vw, 1.2rem) !important; */
    font-size: var(--span-m-font-size) !important;
}

.span-l {
    /* font-size: clamp(1rem, 1.4vw, 1.4rem) !important; */
    font-size: var(--span-l-font-size) !important;
}

.span-xl {
    /* font-size: clamp(1.2rem, 1.75vw, 1.75rem) !important; */
    font-size: var(--span-xl-font-size) !important;
}

.span-xxl {
    /* font-size: clamp(2rem, 3vw, 3rem) !important; */
    font-size: var(--span-xxl-font-size) !important;
}

.accent-text {
    font-family: larken, sans-serif !important;
}

a:hover {
    text-decoration: underline;
    text-decoration-color: var(--hub_background);
    /* color: var(--white) */
}

img {
    max-width: 100% !important;
    display: block !important;
}

.no-wrap {
    white-space: nowrap !important;
}

.italic-text {
    font-style: italic;
}

.hub-finance-icons-text {
    color: var(--page_background);
    text-align: center;
    text-wrap: balance;
}

.center-text {
    text-align: center;
}

/* Globals for pages and containers */

.page-frame-static {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* .page-frame-border {
    border-top: 1px solid var(--border_accent);
} */

.page-overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #484545c4;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.page-container {
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    display: flex;
    position: relative;
}

.page-container-static {
    background-color: var(--white);
    background-image: url("./assets/texture-background.png");
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    display: flex;
    position: relative;
    color: var(--page_background);
}

/* Globals for home page */

.home-section-container {
    width: 100%;
    /* gap: 5vh; */
    padding: 5vh 2vw;
    border-radius: var(--border_radius_container);
}

.home-section-container-hero-mobile {
    width: 100%;
    /* gap: 5vh; */
    padding: 3vh 2vw;
    border-radius: var(--border_radius_container);
}

.home-section-row-container {
    /* width: 100%; */
    gap: 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* padding: 5vh 1vw; */
    /* border-radius: var(--border_radius_container); */
}

.home-section-column-container {
    /* width: 100%; */
    gap: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* padding: 5vh 1vw; */
    /* border-radius: var(--border_radius_container); */
}


/* Globals for hub */

.hub-frame {
    align-items: center;
    background-color: var(--hub_background);
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
    /* width: 95%; */
    width: -webkit-fill-available;
    border-radius: var(--border_radius_container);
    padding: 30px;
    overflow: hidden;
}

.hub-content-frame {
    align-items: center;
    background-color: unset;
    border-radius: 3.5px;
    display: flex;
    flex-direction: column;
    gap: 6vh;
    /* overflow: hidden; */
    padding: 21px;
    position: relative;
    width: 80%;
}

.hub-selection-frame {
    align-items: center;
    align-self: stretch;
    /* border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #777777; */
    /* padding: 0px 0px 20px; */
    /* gap: 17.5px; */
    /* gap: 20px; */
    display: flex;
    flex-direction: column;
    gap: 6vh;
    padding: 2vh 0 2vh 0;
    position: relative;
}

.hub-selection-title-container {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    position: relative;
    justify-content: center;
}

.hub-selection-title-text {
    color: #262626;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0;
    position: relative;
    text-align: center;
    white-space: normal;
    width: fit-content;
}

/* Globals for modal */

.modal-frame {
    background-color: var(--modal_background);
    align-items: flex-start;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: calc(3%);
    gap: 10px;
    width: 60vw;
    /* padding: 3vw; */
    padding-top: 4vh;
    padding-bottom: 4vh;
    z-index: 1002;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 95vh;
    left: 50%;
    transform: translateX(-50%);
}


.modal-frame-tablet {
    width: calc(100% - 100px);
    padding: 2vw;
}

.modal-frame-mobile {
    width: calc(100% - 20px);
    padding: 1vw;
}

.modal-exit-icon-container {
    cursor: pointer;
    z-index: 1000;
    justify-content: flex-end;
    width: -moz-fit-content;
    width: fit-content;
    height: 4vh;
    padding-right: 1vw;
    display: flex;
    position: absolute;
    top: 25px;
    right: .1%;
}

.modal-exit-icon-container>.css-i4bv87-MuiSvgIcon-root {
    font-size: 2.5rem;
}

.modal-exit-icon-container-mobile {
    position: absolute;
    cursor: pointer;
    top: 1px;
    left: calc(100% - 40px);
    z-index: 1000;
}

.modal-exit-icon-container-tablet {
    position: absolute;
    cursor: pointer;
    top: 1px;
    left: calc(100% - 40px);
    z-index: 1000;
}

.modal-exit-icon-container-desktop {
    position: absolute;
    cursor: pointer;
    top: 1px;
    left: calc(100% - 40px);
    z-index: 1000;
}

.modal-content-frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    position: relative;
    padding: 1vh 3vw;
    width: 100%;
    align-items: center;
    overflow-y: auto;
}

/* .modal-content-frame::-webkit-scrollbar {
    position: absolute;
    width: 2px;
    height: auto;
    color: white;
    right: 1px;
} */

.modal-content-frame::-webkit-scrollbar {
    width: 10px;
    position: absolute;
}

.modal-content-frame::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* -webkit-box-shadow: inset 0 0 16px var(--grey); */
    border-radius: 10px;
}

.modal-content-frame::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px var(--page_background);
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}

.modal-content-frame::-webkit-scrollbar-button:start:increment {
    height: 2%;
    display: block;
    background: transparent;
}

.modal-content-frame::-webkit-scrollbar-button:end:increment {
    height: 2%;
    display: block;
    background: transparent;
}

.modal-header {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 5vw;
    position: relative;
    justify-content: center;
}

.modal-title {
    align-self: auto;
    color: #262626;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: inherit;
    position: relative;
    white-space: break-spaces;
    /* width: 100%; */
    text-align: center;
}

.modal-div {
    /* border-bottom-style: solid;
    border-bottom-width: 1px; */
    border-color: #262626;
    border-top-style: solid;
    border-top-width: 1px;
    width: 100%;
}

.modal-subsection-header {
    /* font-size: 50px; */
    letter-spacing: -0.5px;
    line-height: 3rem;
}

.background-color-high-risk {
    /* background-color: #e55534 !important */
    /* background-color: #F14D4D !important; */
    background-color: var(--red) !important;
}

.background-color-medium-risk {
    /* background-color: #f6d958 !important; */
    background-color: var(--yellow) !important
}

.background-color-low-risk {
    /* background-color: #96c594 !important */
    /* background-color: #81c56a !important; */
    background-color: var(--accent_light) !important
}


.text-underline {
    text-decoration: underline;
}

.div-line-seperator {
    height: 1px !important;
    border-bottom: 1px solid var(--grey);
    width: 100%;
}

.drop-shadow-standard {
    filter: drop-shadow(0 0 30px #333);
}


.box-shadow-standard {
    box-shadow: 3px 3px 4px 0px #00000073;
}

.box-shadow-standard-left {
    box-shadow: -3px 3px 4px 0px #00000073;
}

.box-shadow-light {
    box-shadow: 1px 1px 4px 0px #00000073;
}

.box-shadow-hover {
    box-shadow: 3px 3px 4px 0px var(--page_background);
    text-decoration: none;
}

.box-shadow-related {
    box-shadow: 3px 3px 4px 0px var(--related_red);
}

.not-selected-text {
    color: var(--not_selected_text)
}

.text-align-center {
    text-align: center;
    width: 100%;
}

/* ::-webkit-scrollbar {
    position: absolute;
    width: 0px;
    height: 0px;
    right: 1px;
} */

.statistic-header {
    color: var(--black);
    letter-spacing: 0;
    text-align: center;
    white-space: normal;
    /* flex: 1; */
    align-items: center;
    gap: 2px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    display: flex;
    position: relative;
    text-wrap: balance;
}

.statistic-header-climate {
    flex: 0 !important;
}

.statistic-datapoint {
    color: var(--black);
    letter-spacing: -.5px;
    text-align: center;
    white-space: normal;
    flex-direction: column;
    /* flex: 1; */
    justify-content: flex-end;
    align-self: stretch;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    position: relative;
    margin-bottom: 1vh;
}

.statistic-datapoint-High {
    color: var(--related_red)
}

.statistic-datapoint-Medium {
    color: var(--yellow)
}

.statistic-datapoint-Low {
    color: var(--accent_light)
}

.static-page-header-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.statistic-geography-text {
    font-size: 1rem;
    color: var(--page_background);
    width: 95%;
    font-style: italic;
}

.statistic-datapoint-type-text-container {
    flex-direction: row;
    gap: 1vh;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
}

/* .page-container-static div p, */
.page-container-static div h1,
.page-container-static div h3 {
    color: var(--page_background);
    text-align: center;
    padding: 2vh 1vw;
}

.static-page-header-text {
    color: var(--white);
    text-align: center;
}

.page-content-container-static {
    display: flex;
    flex-direction: column;
    gap: 8vh;
    width: 90%;
    /* flex-wrap: wrap; */
    align-items: center;
}

.page-content-container-row-static {
    display: flex;
    flex-direction: row;
    gap: 5vw;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.page-content-container-row-static-right {
    flex: 1;
}

.page-content-container-flexbox {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
}

.page-content-vertical-divider {
    border-left: 2px solid var(--page_background);
}

.page-content-horizontal-divider {
    border-bottom: 2px double var(--page_background);
    width: 50%;
    align-self: center;
    border-style: double;
}

.page-content-horizontal-divider-white {
    border-bottom: 2px double var(--white);
    width: 50%;
    align-self: center;
    border-style: double;
}

.page-content-horizontal-divider-full {
    border-bottom: 2px double var(--page_background);
    width: 90%;
    align-self: center;
    border-style: double;
}

.page-content-horizontal-divider-full-left-align {
    border-bottom: 2px double var(--page_background);
    width: 90%;
    align-self: flex-start;
    border-style: double;
}

.page-content-horizontal-divider-full-tall {
    border-bottom: 12px double var(--page_background);
    width: 90%;
    align-self: center;
    border-style: double;
}

.page-content-horizontal-divider-full-orange {
    border-bottom: 2px double var(--accent_orange);
    width: 90%;
    align-self: center;
    border-style: double;
}

select {
    text-align: center;
    font-family: inherit;
    width: 7vw;
    min-width: 100px;
    font-size: var(--span-xs-font-size);
    font-family: inherit;
    border-radius: 5px !important;
    border-color: var(--light-grey) !important;
}

.hub-financial-subsection-header {
    color: var(--page_background);
    align-self: center;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column !important;
    gap: 1vh;
}

.hub-finaicial-section-frame {
    background-color: #fff;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 6vh;
    padding: 5vh 3vw;
    display: flex;
    position: relative;
    overflow: hidden;
}

.hub-financial-section-keep-in-mind-text {
    color: var(--black);
    letter-spacing: 0;
    text-align: center;
    align-self: stretch;
    font-size: clamp(.8rem, 1.25vw, 1.2rem) !important;
    font-weight: 400;
    /* line-height: 24px; */
    position: relative;
    text-wrap: balance;
}

.image-container-flexbox {
    min-width: 50%;
    padding: 3%;
}

.methodology-layer-image {
    object-fit: fill;
    height: inherit;
    border-radius: 25px;
    max-height: 25vh;
    min-height: 20vh;
    width: clamp(225px, 35vw, 500px);
}

.header-center-text {
    text-align: center;
    text-wrap: balance;
}

.hub-bar-font {
    font-size: clamp(.8rem, 1.25vw, 1.2rem) !important;
}

@media (min-width: 769) {
    .hub-frame {
        padding: 30px;
    }

    .hub-content-frame {
        width: 100%;
    }

    .page-container {
        gap: 2vh;
        padding: 2vh 4.5%;
    }

    .page-container-static {
        gap: 6vh;
        padding: 4vh 6.5%;
    }

    .hub-projects-filter-bar-frame {
        gap: 5vw;
    }

    .modal-frame {
        width: 70vw;
    }

}

@media (min-width: 480px) and (max-width: 769px) {
    .hub-frame {
        padding: 20px;
    }

    .hub-content-frame {
        width: 100%;
        padding: 10px;
    }

    .page-container {
        gap: 2vh;
        padding: 2vh 4vw;
    }

    .page-container-static {
        gap: 4vh;
        padding: 2vh 4vw;
    }

    .hub-projects-filter-bar-frame {
        gap: 5vw;
    }

    .modal-frame {
        width: 85vw;
    }
}

@media (max-width: 480px) {
    .hub-frame {
        padding: 1vh 1.5vw;
        /* width: 100%; */
    }

    .hub-content-frame {
        width: 100%;
        padding: 0;
        gap: 10px;
    }

    .page-container {
        gap: 2vh;
        padding: 2vh 2vw;
    }

    .page-container-static {
        gap: 4vh;
        padding: 2vh 2vw;
    }

    .modal-frame {
        width: 90vw;
    }
}

.underline-text {
    text-decoration: underline;
}

.text-wrap-balance {
    text-wrap: balance;
}

.text-strikethrough {
    text-decoration: line-through
}

.clickable {
    cursor: pointer;
    margin-bottom: 1vh;
}

.haAclf {
    display: block;
}

.text-align-center {
    text-align: center;
}

.border-none {
    border: 0px !important;
}

.break-spaces {
    white-space: break-spaces;
}

sup {
    vertical-align: super;
    font-size: smaller;
}

.full-width {
    width: 100%;
}