.modal-climate-house-image-container {
  /* width: 100%; */
  /* padding-right: 2vw; */
  /* padding-left: 2vw; */
  height: 30vh;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: var(--hub_background); */
  border-radius: 15px;
  /* overflow: hidden; */
}

.modal-climate-house-image {
  object-fit: cover;
  background-color: var(--hub_background);
  height: inherit;
  width: inherit;
  /* border: 1px solid var(--page_background); */
  border-radius: 15px;
}

.modal-climate-house-icon {
  height: 4vh;
}

.modal-climate-title-bar {
  display: flex;
  flex-direction: row;
  gap: 1vh;
  align-items: center;
}

.modal-climate-frame {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #262626;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 0px 10px;
  position: relative;
}

.modal-climate-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 3rem;
  position: relative;
}

.modal-climate-title {
  align-self: center;
  color: #262626;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -0.5px;
  position: relative;
  white-space: nowrap;
}

.modal-climate-blurb {
  align-self: stretch;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.modal-climate-stats-frame {
  align-self: stretch;
  /* align-items: flex-start; */
  /* gap: 10px; */
  /* padding: 20px 0; */
  display: flex;
  /* flex-wrap: wrap; */
  position: relative;
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  justify-content: space-evenly;
  background-color: var(--white);

  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  padding: 2vh 1vw;
}

.modal-climate-subsection-header {
  font-size: 50px;
  letter-spacing: -0.5px;
  line-height: 60px;
}

.modal-climate-areas-of-home-affected-container {
  gap: 10px;
  width: 100%;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(100px,2fr)); */
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  justify-items: center;
  gap: 10px;
  width: 100%;
  display: grid;
  position: relative;
}