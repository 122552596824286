.play-with-data-button-container {
  align-items: center;
  background-color: var(--white);
  /* border: 3px solid var(--page_background); */
  border-radius: var(--border_radius_bar);
  display: flex;
  flex-direction: row;
  gap: .5vw;
  padding: 1vh 1vw;
  position: relative;
  width: fit-content;
  color: var(--accent_orange);
}

.play-with-data-button-text-bar {
  color: transparent;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  font-style: bold;
  line-height: 16.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.play-with-data-button-text-wrapper {
  color: var(--black);
  text-align: center;
  
}

.play-with-data-button-span {
  color: #ffffff;
}
