.climate-factors-icons-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 2vw;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: fit-content;
  align-self: center;
}

.select-a-climate-factor-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: center;
  position: relative;

}

.select-a-climate-factor-text {
  color: #777777;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  position: relative;
  text-align: center;
  white-space: normal;
  width: fit-content;
  text-wrap: balance;
}

.clear-selection-text {
  cursor: pointer;
}

.details-HurricaneHover {
  top: 182px;
}

.details-HurricaneSelected {
  top: 668px;
}

.details-ClearHurricane {
  top: 1154px;
}

.details-ClearWind {
  top: 1316px;
}

.details-ClearStorm {
  top: 1478px;
}

.details-WindSelected {
  top: 830px;
}

.details-StormSelected {
  top: 992px;
}

.details-WindHover {
  top: 344px;
}

.details-StormHover {
  top: 506px;
}

.climate-data-stats-bar-container {
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 3fr)); */
}