.modal-house-frame {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #262626;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 0px 10px;
  position: relative;
}

.modal-house-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 5px;
  position: relative;
}

.modal-house-title {
  align-self: stretch;
  color: #262626;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 60px;
  position: relative;
  white-space: nowrap;
}

.modal-climate-p {
  align-self: stretch;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.modal-climate-frame-72 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #262626;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  gap: 30px;
  padding: 20px 0px;
  position: relative;
}

.modal-climate-frame-69 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.modal-climate-element {
  align-self: stretch;
  color: #262626;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 48px;
  position: relative;
  text-align: center;
}

.modal-climate-span {
  color: #262626;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 48px;
}

.modal-climate-text-wrapper-2 {
  font-size: 50px;
  letter-spacing: -0.5px;
  line-height: 60px;
}

.modal-climate-chance-of-experiencing-tornado-level-wind {
  align-self: stretch;
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
  text-align: center;
}

.modal-climate-text-wrapper-3 {
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.6px;
}

.modal-climate-text-wrapper-4 {
  font-weight: 600;
}

.modal-climate-box-climate-risk-icon-x {
  height: 16px;
  left: 773px;
  position: absolute;
  top: 12px;
  width: 16px;
}

.modal-climate-text-wrapper-5 {
  align-self: stretch;
  color: #262626;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.5px;
  position: relative;
  white-space: nowrap;
}

.modal-climate-frame-133 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 10px;
  padding: 20px 0px;
  position: relative;
}

.div-wrapper-text-wrapper-3 {
  align-self: stretch;
  color: #262626;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.5px;
  position: relative;
  white-space: nowrap;
}