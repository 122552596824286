.problem-hero-container {
    display: flex;
    flex-direction: column;
    height: 94vh;
    justify-content: center;
    align-items: center;
}

.problem-hero-image {
    object-fit: cover;
    opacity: .2;
    height: inherit;
    width: 100vw;
    position: absolute;
    object-position: 0% 75%;
}

.problem-hero-headline {
    z-index: 2;
    color: var(--white);
    /* width: 60%; */
    text-align: center;
    text-wrap: balance;
}

.problem-hero-text-container {
    display: flex;
    flex-direction: column;
    animation: fadeIn 6s;
    height: 80%;
    width: 75%;
    justify-content: space-around;
    align-items: center;
}

.problem-hero-scroll-notice-container {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    width: fit-content;
    align-items: center;
    color: var(--white);
    cursor: pointer;
}

.problem-hero-scroll-notice-text {
    z-index: 2;
    font-size: 2rem;
    color: var(--white);
}

.problem-section-container {
    display: flex;
    flex-direction: column;
    gap: 8vh;
    align-items: center;
}

.problem-section-sub-header-text {
    font-weight: 500;
}

.problem-section-sub-header-context-text {
    font-style: italic;
    color: var(--black);
    /* width: 90%; */
    text-align: center;
    font-size: 1rem;
    text-wrap: balance;
    align-self: center;
    width: 75%;
}

.problem-section-conclusion-text {
    color: var(--black);
    /* width: 90%; */
    text-align: center;
    width: 90%;
}

.problem-section-inner-row {
    justify-content: end;
    gap: 3vw;
    align-self: center;
    width: 75%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    align-items: center;
    justify-items: center;
}

.problem-section-inner-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    /* padding: 4vh 0vw; */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.how-we-talk-about-it-container {
    height: 40vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.how-we-talk-about-it-container-overlay {
    height: inherit;
    width: inherit;
    background-color: #000000cf;
    flex-direction: column;
    justify-content: center;
    display: flex;
    position: absolute;
}

.how-we-talk-about-it-text {
    text-align: center;
    z-index: 2;
    width: 80%;
    text-wrap: balance;
    align-self: center;
    color: var(--white);
}

.how-we-talk-about-it-container,
.problem-section-container {
    padding: 0vh 0vw;
}
