.header-frame {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  position: sticky;
  background-color: var(--page_background);
  mix-blend-mode: normal;
  height: 4.5vh;
  top: 0;
  z-index: 1000;
}

.header-frame-border {
  border-bottom: 1px solid var(--border_accent);
}

@media (min-width: 1400px) {
  .header-frame {
    padding: 4vh 3%;
  }
}

@media (min-width: 701px) and (max-width: 1399px) {
  .header-frame {
    padding: 4vh 2vw;
  }
}

@media (max-width: 700px) {
  .header-frame {
    padding: 4vh 2vw;
    justify-content: space-around;
  }
}

.link-container {
  height: inherit;
  width: inherit;
}

.quoll-logo-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: inherit;
  justify-content: center;
  padding: 2px 0px 0px;
  position: relative;
  width: fit-content;
}

.company-mark-container-mobile {
  width: inherit;
  height: inherit;
}

.quoll-logo-img {
  position: relative;
  height: 100%;
  min-width: 100%;
}

.header-search-bar-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  position: relative;
  /* width: 30vw; */
  /* height: 90%; */
}

.header-search-bar-container-mobile {
  width: 40vw;
  height: 5vh;
}

.header-search-bar-container-desktop {
  width: 25vw;
  height: 3.5vh;
}


.header-buttons-and-links-container {
  align-items: center;
  display: flex;
  gap: 1vw;
  justify-content: flex-end;
  position: relative;
  height: inherit;
}

.header-mobile-menu {
  background-color: var(--accent_orange);
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  border: 0;
  border-radius: 5px;
  display: flex;
}

.link {
  background: none;
  border: none;
  text-decoration: none;
  color: red;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
}

.link:hover {
  color: black;
}

.dropdown{
  position: relative;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.dropdown:focus {
  border: 1px red;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 2rem;
  background-color: var(--white);
  padding: .75rem;
  border-radius: .25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 1;
  transform: translateY(-10px);
  /* pointer-events: none; */
  transition: opacity 150ms ease-in-out, transform 350ms ease-in-out;
}

.dropdown-menu.active {
  opacity: 1;
}

.dropdown > .header-mobile-menu:focus + .dropdown-menu {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}