.hero-bar-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 2vw;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.hero-bar-text-column {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.hero-bar-text-container {
  /* align-items: flex-start; */
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  position: relative;
  justify-content: center;
}

.text-container-desktop {
  gap: 5vh;
  align-items: flex-start;
}

.text-container-mobile {
  gap: 3vh;
  align-items: center;
}

.hero-bar-headline {
  align-self: stretch;
  color: var(--white);
  font-size: 70px;
  font-weight: 400;
  position: relative;
  letter-spacing: .05rem;
  text-wrap: balance;
}

.hero-bar-headline span {
  color: inherit !important;
}

.hero-bar-headline-emphasis {
  font-size: inherit;
  line-height: inherit;
  font-family: 'lark';
}

.hero-bar-paragraph {
  align-self: stretch;
  color: #f6e6db;
  font-size: 18px;
  /* font-weight: 600; */
  letter-spacing: 0;
  /* line-height: 27px; */
  position: relative;
}

.mobile-text {
  text-align: center;
}

.hero-bar-image-container {
  align-self: stretch;
  position: relative;
  height: 100%;
  min-width: 35%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}

.hero-bar-image-desktop {
  height: inherit;
  /* max-height: 5vh; */
  width: inherit;
  object-fit: contain;
}

.hero-bar-image-mobile {
  /* height: inherit; */
  max-height: 25vh;
  width: inherit;
  object-fit: cover;
}

.hero-search-bar-container-desktop {
  width: clamp(300px, 25vw, 500px);
  height: 5.5vh;
}

.hero-search-bar-container-desktop div {
  /* max-height: 4.5vh; */
  display: flex;
  align-items: center;
}

.hero-search-bar-container-mobile {
  width: 75vw;
  height: 4vh;
}