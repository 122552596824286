.hub-sections-buttons-bar-container {
  display: flex;
  flex-direction: column;
}

.hub-sections-buttons-bar {
  display: flex;
  align-items: stretch;
  gap: 1vw;
  width: -moz-fit-content;
  display: grid;
  justify-content: space-evenly;
  flex: 1;
  position: relative;
  width: 50%;
  justify-items: center;
}

@media (min-width: 769) {
  .hub-sections-buttons-bar {
    width: 90%;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-sections-buttons-bar {
    align-items: center;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .hub-sections-buttons-bar {
    grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;
  }
}

.MuiBreadcrumbs-li {
  width: fit-content;
}