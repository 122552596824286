.what-we-do-icons-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* min-width: 700px; */
    max-width: 1200px;
    /* padding: 0 7px 21px; */
    display: flex;
    position: relative;
}

.what-we-do-icons-image {
    object-fit: cover;
    position: relative;
}

.what-we-do-icon-image {
    object-fit: contain;
    position: relative;
    height: inherit;
    width: inherit;
}

.what-we-do-icon-container {
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.what-we-do-icon-text-box {
    max-width: 20vw;
    position: absolute;
    align-items: center;
    text-wrap: balance;
    text-align: center;
    overflow: hidden;

}

.what-we-do-icon-text {
    text-align: center;
}

.what-we-do-icon-1-box {
    left: -10%;
    bottom: 5%;
}

.methodology-icons-grid {
    grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));
    justify-content: center;
    justify-items: center;
    gap: 2vh 4vw;
    width: 100%;
    display: grid;
    position: relative;  
}

.methodology-text-icon-frame {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2vh;
    display: flex;
}

.methodology-icon-container {
    height: 5vh;
}

.methodology-icon {
    object-fit: contain;
    height: inherit;
}

.methodology-icon-text {
    text-align: center;
    font-size: .9rem;
    font-style: italic;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.methodology-layer-text {
    text-align: center;
    text-wrap: balance;
}

.methodology-flexbox-secton {
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    gap: 2vh 3vw;
    display: flex;
}

.methodology-flexbox-secton-reverse {
    flex-flow: wrap-reverse;
}

.methodology-flexbox-text {
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    display: flex;
    width: clamp(225px, 35vw, 600px);
    /* flex: 1 0 50%; */
}