.dashboard-home-card-frame {
  display: flex;
  flex-direction: column;
  background: var(--white);
  gap: 2vh;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 1vh;
}

.dashboard-home-card-frame-desktop {
  max-width: 40vw;
}

.dashboard-home-card-frame-tablet {
  max-width: 70vw;
}

.dashboard-home-card-image-container {
  display: flex;
  height: 20vh;
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  border-top-left-radius: 30px;
  /* padding: 0.5vw; */
}

.dashboard-home-card-score-bookmark-container {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.dashboard-home-card-bookmark-container {
  /* height: 100%; */
  display: flex;
  background-color: var(--accent_light);
  flex-direction: column;
  padding: 0.75vw;
  border-bottom-left-radius: 15px;
  color: var(--white);
  justify-content: center;
  z-index: 50;
}

.dashboard-home-card-bookmark-container:hover{
  background-color: var(--accent_orange);
}

.dashboard-home-card-image {
  height: 100%;
  object-fit: cover;
}

.dashboard-home-card-address-bar {
  display: flex;
  flex-direction: column;
  padding-right: 5%;
  padding-left: 5%;
  flex: 1;
}

.dashboard-home-card-address-text {
  text-align: center;
  color: var(--page_background);
}

.dashboard-home-card-projects-bar {
  display: flex;
  flex-direction: column;
}

.dashboard-home-card-quoll-score-container {
  text-align: center;
  border-bottom-right-radius: 15px;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 1vw;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
}

.dashboard-home-card-projects-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 0vw;
  padding-left: 2%;
  padding-right: 2%;
}

.dashboard-home-card-projects-bar-item {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  align-items: center;
}

.dashboard-home-card-projects-bar-item-headline {
  text-align: center;
  color: var(--grey);
  text-wrap: wrap;
}