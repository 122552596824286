.project-card-resources-container {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0;
  display: flex;
  position: relative;
  width: 100%;
}

.video-play-container {
  height: fit-content;
  font-size: 3rem;
  position: absolute;
  top: 15%;
  left: 40%;
  background: #e36255;
  display: flex;
  border-radius: 5px;
  align-items: center;
}

.video-play-icon {
  font-size: 3rem;
  color: var(--white);
}

.project-card-resources-header {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.2px;
  position: relative;
}

.project-card-resources-cards-frame {
  display: grid;
  grid-gap: 2rem;

  /* grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

  width: 100%;
}

.resource-cards-justify-center {
  justify-content: center;
}

.resource-cards-justify-left {
  /* justify-content: flex-start; */
  justify-content: space-around;
}

.resource-card-article-frame {
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 2vh;
  padding-bottom: 1vh;
  display: flex;
  position: relative;
  background-color: var(--hub_background);
}

.resource-card-background-light {
  background-color: var(--hub_background);
}

.resource-card-background-dark {
  background-color: var(--page_background);
}

.resource-card-background-dark>h4 {
  color: var(--white);
}

.resource-card-background-dark>span {
  color: var(--hub_background);
}

.resource-card-thumbnail {
  height: 160px;
  max-width: 240px;
  background-position: 50%;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-self: stretch;
  position: relative;
  object-fit: cover;
}

.resource-card-text {
  color: var(--black);
  letter-spacing: 0;
  align-self: stretch;
  font-weight: 600;
  position: relative;
  text-align: center;
  padding: 1vh 1vw;
  flex: 1;
  text-wrap: balance;
  /* display: flex; */
  /* align-items: flex-start; */
}

.resource-card-hashtags {
  padding: 0vh 1vw;
  font-size: .8rem;
  font-style: italic;
  color: var(--page_background);
  text-wrap: balance;
  text-align: center;
}

.resource-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* or whatever height you need */
  /* add any other styles needed to ensure consistent sizing */
  outline: none;
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}

.resource-wrapper:hover {
  color: var(--accent_orange);
  text-decoration: none;
}

.resource-wrapper:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--accent_orange);
  /* Replace with your own focus style */
}

.video-player-overlay {
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: #000000;
  opacity: .9;
}

.video-player-container {
  height: 75vh;
  width: 75vw;
  z-index: 1002;
  top: 5%;
  right: 5%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  /* border: 1px solid var(--white); */
}

.video-player-close-button-container {
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  right: 1%;
  top: 1%;
  background-color: var(--accent_orange);
  border: 3px solid var(--white);
  cursor: pointer;
}

.video-player-close-button {
  font-size: 2vw;
  color: var(--white);
}

.resource-hashtag {
  font-weight: 600;
}