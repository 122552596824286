.scrollytelling-container {
  display: flex;
  flex-direction: row;
  /* gap: 2vw; */
  margin-top: 10vh;
  width: 100%;
  justify-content: space-evenly;
}

.scrollytelling-text-container {
  z-index: 2;
  flex-direction: column;
  gap: 30vh;
  width: clamp(150px, 35vw, 500px);
  margin: 0;
  padding-top: 15vh;
  padding-bottom: 15vh;
  display: flex;
}

.scrolly-telling-image-container {
  justify-content: space-between;
  align-self: self-start;
  /* width: clamp(150px, 35vw, 500px); */
  max-width: 40vw;
  height: 50vh;
  animation: 5s fadein;
  display: flex;
  position: sticky;
  top: 15vh;
  border-radius: 15px;
  align-items: center;
}

.scrollytelling-image {
  object-fit: contain;
  position: relative;
  border-radius: 15px;
  height: fit-content;
}

.scrollytelling-text {
  padding: 5vh 0%;
  text-align: center;
  text-wrap: balance;
}

.scrollytelling-container-mobile {
  flex-direction: column;
  width: 100%;
  margin-top: 10vh;
  height: 40vh;
  display: flex;
  /* background-image: url(https://d2dhzsfgxco53w.cloudfront.net/nyt-us-climate-risk-map.jpg); */
  object-fit: contain;
  overflow: scroll;
  background-size: contain;
  background-repeat: no-repeat;
  position: sticky;
}

.scrollytelling-image-mobile {
  object-fit: contain;
  position: relative;
  border-radius: 15px;
  height: fit-content;
  position: sticky;
  top: 30vh;
}

.scrolly-telling-image-container-mobile {
  border-radius: 15px;
  justify-content: space-between;
  align-self: self-start;
  align-items: center;
  width: 100%;
  height: 50vh;
  animation: 5s fadein;
  display: flex;
  /* position: sticky; */
  top: 15vh;
  overflow: scroll;
  flex-direction: column;
}

.scrollytelling-text-container-mobile {
  flex-direction: column;
  /* gap: 30vh; */
  width: 100%;
  margin: 0;
  /* padding-bottom: 15vh; */
  /* padding-top: 15vh; */
  display: flex;
  z-index: 2;
  position: sticky;
}

.scrollytelling-text-mobile {
  text-align: center;
  text-wrap: balance;
  /* padding: 5vh 0%; */
  color: var(--page_background);
  width: fit-content;
  background-color: var(--white);
  border-radius: 15px;
  padding: 1vw 1vw;
  background-image: url("../../assets/texture-background.png");
}