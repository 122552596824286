.project-card-view-frame {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.project-card-bookmark-container {
  position: absolute;
  top: -5px;
  right: 2px;
  z-index: 50;
}

.project-card-bookmark-default {
  /* color: var(--white); */
  color: var(--accent_orange);
  font-size: var(--h3-font-size) !important;
}

.project-card-bookmark-saved {
  color: var(--accent_orange);
  font-size: var(--h3-font-size) !important;
}

.project-card-data-frame {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: stretch;
  justify-content: space-between;
  gap: 1.5vh;
  padding-bottom: 1vh;
}

.project-card-view-image-container {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  justify-content: center;
}

.project-card-view-disconts-icon {
  color: var(--accent_orange);
  position: absolute;
  top: 0;
  left: 1px;
}

.project-card-view-image-overlay {
  background-color: rgba(0, 0, 0, 0.65);
  height: inherit;
  width: inherit;
  position: absolute;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 1vw;
}

.project-bar-view-image {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  position: relative;
}

.project-card-view-header-title {
  align-self: stretch;
  color: var(--black);
  letter-spacing: 0;
  text-wrap: balance;
  position: relative;
  text-align: center;
  color: var(--hub_background);
  font-size: clamp(1rem, 1.5vw, 1.5rem) !important;
}

.project-card-view-header-risks-container {
  align-items: flex-start;
  display: flex;
  gap: .75vw;
  position: relative;
  width: fit-content;
}

.project-card-view-see-more-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-card-view-see-more-text {
  font: .5rem;
  color: var(--page_background);
}

.project-card-view-see-more-icon {
  font: .5rem;
  color: var(--page_background);
}