.project-card-discount-frame {
  background-color: var(--white);
  flex-direction: column;
  flex: 1;
  /* justify-content: space-evenly; */
  align-self: stretch;
  align-items: center;
  gap: 5vh;
  padding: 2vh 1vw;
  display: flex;
  position: relative;
  border-radius: 5px;
  text-align: center;
  max-height: 50vh;
  overflow-y: auto;
}

.project-card-individual-discount-container {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding: 0 1vw; */
}

.project-card-individual-discount-name {
  color: var(--page_background);
  font-weight: 600;
}

.project-card-discount-frame::-webkit-scrollbar {
  width: 10px;
  position: absolute;
}

.project-card-discount-frame::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* -webkit-box-shadow: inset 0 0 16px var(--grey); */
  border-radius: 10px;
}

.project-card-discount-frame::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--black);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}

.project-card-discount-frame::-webkit-scrollbar-button:start:increment {
  height: 2%;
  display: block;
  background: transparent;
}

.modal-content-frame::-webkit-scrollbar-button:end:increment {
  height: 2%;
  display: block;
  background: transparent;
}

.project-card-discounts-container {
  flex-direction: row;
  gap: 1vw;
  display: grid;
  /* flex-wrap: wrap; */
  width: 100%;
  /* grid-template-columns: repeat(auto-fit, minmax(10vw, 2fr)); */
  grid-template-columns: repeat(auto-fill, minmax(15rem, 5fr));
  justify-content: center;
}
