.modal-user-form-description-text {
  font-size: 1rem;
  text-align: center;
  color: var(--page_background);
  text-wrap: balance;
}

.modal-user-form-selection-reminder-text-container {
  display: flex;
  flex-direction: column;
  /* align-content: center; */
  width: 100%;
  align-items: center;
}

.modal-user-form-selection-reminder-text {
  text-align: center;
  font-size: 1rem;
  font-style: italic;
}

.modal-user-input-form-text {
  align-self: stretch;
  color: #262626;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.modal-user-input-form-data-categories-bar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 10px;
  padding: 20px 0px;
  position: relative;
}

.modal-user-input-form-data-radio-button-bar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 5rem;
  padding: 20px 0px;
  position: relative;
}

.modal-user-input-form-datapoints-frame {
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(15rem, max-content));
  justify-content: space-evenly;
  align-self: stretch;
  align-items: stretch;
  gap: 10px;
  display: grid;
  position: relative;
}

.modal-user-input-form-datapoints-frame-mobile {
  grid-template-columns: repeat(auto-fill, minmax(30vw, max-content));
}

.modal-user-input-form-datapoints-frame-tablet {
  grid-template-columns: repeat(auto-fill, minmax(23vw, max-content));
}

.modal-user-input-form-datapoints-frame-desktop {
  grid-template-columns: repeat(auto-fill, minmax(15vw, max-content));
}

.modal-user-form-submit-button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-bottom: 2vh;
  align-items: center;
  gap: 1vh;
}

.modal-user-form-submit-button {
  background-color: var(--page_background);
  color: var(--hub_background);
  border-radius: 5px;
  border: none;
  padding: 1vh 2vh;
  width: fit-content;
  cursor: pointer;
  font-size: 1.2rem;
}

.modal-user-form-house-category-cards-grid {
  flex-flow: wrap;
  gap: 1vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 3fr));
  width: 100%;
  /* padding: 0px 5%; */
}

.modal-user-form-house-category-card-frame {
  display: flex;
  flex-direction: column;
  background-color: var(--hub_background);
  padding: 1vh 1vw;
  border-radius: 15px;
  align-items: center;
  justify-content: space-between;
  gap: 1vh;
  cursor: pointer;
}

.modal-user-form-house-category-card-frame-default {
  background-color: var(--hub_background);
}

.modal-user-form-house-category-card-frame-not-selected {
  background-color: var(--grey);
}

.modal-user-form-house-category-card-image-container {
  display: flex;
  height: 5vh;
}

.modal-user-form-house-category-card-image {
  object-fit: contain;
}

.modal-user-form-house-category-card-category-text {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.modal-user-form-house-category-card-category-context-text {
  font-size: .85rem;
  font-weight: 600;
  font-style: italic;
  color: var(--page_background);
}

.modal-user-form-user-inputs-count-text {
  font-size: .75rem;
  font-style: italic;
  color: var(--page_background);
}