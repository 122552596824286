.title-button-container {
  align-items: center;
  border-radius: 7.5px;
  display: flex;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 0px 1vw;
  position: relative;
  background-color: var(--accent_orange);
  height: 4vh;
  cursor: pointer;
  min-width: 3em;
}

@media (max-width: 480px) {
  .title-button-container-selected,
  .title-button-container {
    height: 4vh;
    width: 20vw;
  }

}


.title-button-container:hover {
  cursor: pointer;
  background-color: var(--accent_light);
}

.title-button-container-selected {
  align-items: center;
  border-radius: 7.5px;
  display: flex;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 0px 15px;
  height: 4vh;
  min-width: 3em;
  position: relative;
  width: fit-content;
  outline: 0.7px solid var(--black);
  background-color: var(--accent_light);
  /* min-height: 4vh;
  max-height: 5.5vh; */
  cursor: pointer;
}

.title-button-container-selected:hover {
  cursor: pointer;
  /* background-color: var(--accent_light); */
}

.title-button-text {
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-size: 1rem;
  letter-spacing: .25px;
  /* line-height: 16.8px; */
  font-weight: 600;
  color: var(--white);
}
