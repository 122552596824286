.checklist-item-frame {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  padding: 2vh 1vw;
  border: 1px solid var(--black);
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
}

.checklist-item-frame-mobile {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  padding: 2vh 1vw;
  border: 1px solid var(--black);
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
}

.checklist-item-header-frame-mobile {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  align-items: center;
  width: 100%
}

.checklist-item-header-frame-mobile-selected span,
.checklist-item-header-frame-mobile-selected div {
  color: var(--white);
}

.checklist-item-frame-mobile-selected,
.checklist-item-frame-selected {
  background-color: var(--page_background);
}

.checklist-item-frame-selected {
  background-color: var(--page_background);
}

.checklist-item-frame-mobile-selected {
  background-color: var(--page_background);
}

.checklist-item-frame-selected span,
.checklist-item-frame-selected div {
  color: var(--white);
}

/* .now-what-guidance-cta-container-mobile span {
  color: inherit !important;
} */

.checklist-item-frame-mobile-true {
  height: fit-content;
  color: var(--page_background);
  cursor: pointer;
}

.checklist-item-frame-mobile-false {
  height: fit-content;
  color: var(--page_background);
  cursor: pointer;
}

.checklist-item-icon-container-true {
  width: fit-content;
  height: fit-content;
  color: var(--page_background);
  cursor: pointer;
}

.checklist-item-icon-container-false {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  color: var(--grey);
}

.checklist-item-text-container {
  display: flex;
  flex-direction: column;
  gap: .5vh;
}

.checklist-item-headline {
  font-weight: 600;
}

.checklist-item-headline-selected {
  color: var(--white);
}
