.hub-house-category-container {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  /* height: 36px; */
  overflow: hidden;
  padding: 0px 3px 0px 0px;
  position: absolute;
  width: fit-content;
  cursor: pointer;
  /* border: 2px solid; */
}

.hub-house-category-container-dot {
  align-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  display: flex;
  height: 20px;
  overflow: hidden;
  /* padding: 0px 3px 0px 0px; */
  position: absolute;
  /* width: fit-content; */
  width: 20px;
  cursor: pointer;
  /* border: 2px solid; */
}

.hub-house-category-container-list {
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  gap: 2vw;
  display: flex;
  position: relative;
  /* height: 36px; */
  overflow: hidden;
  /* padding: 0px 3px 0px 0px; */
  /* width: fit-content; */
  width: inherit;
  cursor: pointer;
  /* border: 2px solid; */
}

/* General Property */
.hub-house-category-container-dot.General-Property {
  left: 0%;
  top: 20%;
}

.hub-house-category-container.General-Property {
  left: 00%;
  top: 20%;
}

/* Roof */
.hub-house-category-container-dot.Roof {
  left: 30%;
  top: 15%;
}

.hub-house-category-container.Roof {
  left: 30%;
  top: 10%;
}

/* Roof */
.hub-house-category-container-dot.Plumbing {
  bottom: 0%;
  right: 20%;
}

.hub-house-category-container.Plumbing {
  top: 80%;
  left: 67%;
}

/* Landscaping */
.hub-house-category-container-dot.Landscaping {
  left: 2%;
  top: 25%;
}

.hub-house-category-container.Landscaping {
  left: 2%;
  top: 75%;
}

/* Siding */
.hub-house-category-container-dot.Siding {
  top: 40%;
  left: 78%;
}

.hub-house-category-container.Siding {
  left: 82%;
  top: 50%;
}

/* Plumbing
.hub-house-category-container-dot.Plumbing {
  bottom: 2%;
  right: 5%;
}
.hub-house-category-container.Plumbing {
  right: 5%;
  bottom: 2%;
} */

/* HVAC */
.hub-house-category-container-dot.HVAC {
  top: 20%;
  left: 62%;
}

.hub-house-category-container.HVAC {
  top: 20%;
  left: 62%;
}

/* Doors */

.hub-house-category-container.Doors {
  left: 26%;
  top: 73%;
}

.hub-house-category-container-dot.Doors {
  left: 29%;
  top: 55%;
}

/* Windows */
.hub-house-category-container.Windows {
  top: 43%;
  left: 60%;
}

.hub-house-category-container-dot.Windows {
  top: 53%;
  left: 61%;
}

/* Electrical */
.hub-house-category-container.Electrical {
  left: 39%;
  top: 39%;
}

.hub-house-category-container-dot.Electrical {
  left: 39%;
  top: 39%;
}

/* Foundation */
.hub-house-category-container.Foundation {
  left: 45%;
  top: 80%;
}

.hub-house-category-container-dot.Foundation {
  top: 70%;
  left: 45%;
}

.hub-house-category-risk-level {
  align-self: stretch;
  min-width: 10px;
  position: relative;
  /* border-right: 1px solid */
}

.hub-house-category-risk-level.High {
  background-color: #ff2f00;
}

.hub-house-category-risk-level.Medium {
  background-color: #ebeb06;
}

.hub-house-category-risk-level.Low {
  background-color: #ebeb06;
}

.hub-house-category-text-container {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  /* padding: 0px 5px 0px 8px; */
  padding: .5vh .5vw;
  /* padding: 5px; */
  position: relative;
  gap: .75vh;
}

.hub-house-category-text-name {
  align-items: center;
  display: flex;
  gap: 5px;
  position: relative;
  width: fit-content;
  color: #262626;
  font-size: .9rem;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.hub-house-category-text-projects {
  color: #262626;
  font-size: .75rem;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  gap: .25vh;
  flex-direction: column;
}

.hub-house-category-arrow {
  height: 14px;
  min-width: 14px;
  position: relative;
}