.header-menu-item-container {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: fit-content;
  text-align: center;
}

.header-menu-item {
  color: var(--accent_orange);
  font-size: clamp(1.25rem, 2vw, 1.5rem);;
  font-weight: 300;
  letter-spacing: 0;
  line-height: inherit;
  position: relative;
  width: fit-content;
  font-family: 'halyard-display';
}
