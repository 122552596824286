
.financial-impact-header-frame {
    align-self: center;
    left: unset;
    /* margin-left: -1px; */
    /* margin-right: -1px; */
    /* padding: 0 10px; */
    top: unset;
    width: fit-content;
    /* border-color: #777;
    border-bottom-style: solid;
    border-bottom-width: 1px; */
    justify-content: center;
    align-items: flex-start;
    gap: 5vw;
    display: flex;
    position: relative;
    align-self: center;
}

.financial-impact-header-general {
    letter-spacing: 0;
    cursor: pointer;
    font-weight: 500;
    width: fit-content;
    align-items: normal;
    gap: 10px;
    padding: 2vh 2vw;
    display: flex;
    background-color: var(--white);
    flex-direction: column;
    position: relative;
    text-align: center;
    border-radius: 15px;
    align-self: stretch;
}

.financial-impact-header-active {
    color: var(--page_background);    
    border-color: var(--page_background);
    border-bottom-style: solid;
    border-bottom-width: .5rem;
    font-weight: 600;
}

.financial-impact-header-headline {
    font-style: italic;
    font-weight: 400 !important;
}

.financial-impact-toggle-header {
    font-weight:600;
}