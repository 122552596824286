.project-card-accordian-container {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0 10px;
  display: flex;
  position: relative;
  border-radius: 15px;
  padding-left: 1vw;
  padding-right: 1vw;
  background: var(--page_background);
}

/* .project-card-accordian-header {
  align-self: stretch;
  align-items: center;
  padding: 0 0px 0 0;
  display: flex;
  position: relative;
  gap: 16px;
  cursor: pointer;
} */

/* .project-card-accordian-headline {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
} */

/* .project-card-accordian-value-description {
  color: #777;
  letter-spacing: .55px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 15px;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
} */

/* .project-card-accordian-value {
  color: #468347;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
} */

.project-card-accordian-button-container {
  /* height: 30px; */
  /* width: 30px; */
  /* border: 2px solid var(--hub_background); */
  color: var(--hub_background);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: flex;
  position: relative;
}

.project-card-accordian-button {
  color: var(--hub_background);
  /* letter-spacing: -.22px; */
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  /* margin: -9px -3px -7px; */
  font-size: 1rem;
  font-weight: 600;
  /* line-height: 26.4px; */
  position: relative;
}