.now-what-guidance-subsection-header {
    font-weight: 600;
    color: var(--page_background);
}

.what-now-header-frame {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
    width: 100%;
}

.what-now-guidance-path-button {
    align-self: flex-start;
}