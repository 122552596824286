.card-background-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #484545e0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.card-container-x-icon {
  /* height: 3rem;
  width: 3rem; */
  color: var(--black);
}

.modal-selector-boxes-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2vh;
  align-items: center;
}

.modal-selector-boxes-grid {
  /* gap: 10px; */
  /* width: 100%; */
  /* display: flex; */
  flex-direction: row;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  justify-items: center;
  /* gap: 2vh 2vw; */
  width: 100%;
  /* display: grid; */
  position: relative;
}

@media (min-width: 769) {
  .modal-selector-boxes-grid {
    gap: 2vh 5vw;
    width: 90%;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .modal-selector-boxes-grid {
    gap: 2vh 3vw;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .modal-selector-boxes-grid {
    justify-content: space-evenly;
    gap: 2vh 2vw;
    width: 100%;
  }
}