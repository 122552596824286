.save-share-bar-frame {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  padding: 0 2vw;
  justify-content: flex-end;
  /* height: 5vh; */
  /* position: absolute; */
  /* width: 80%; */
  /* z-index: 1000; */
}