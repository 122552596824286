.hub-projects-filters-wrapper {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  gap: 10px;
  padding: 0 10px 0 0;
  display: flex;
  position: relative;
}

.hub-selection-title-context {
  text-align: center;
  width: 100%;
  font-weight: 400;
  text-wrap: balance;
}

.hub-projects-category-toggle-bar {
  gap: 2vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(2rem, 8rem));
  width: 100%;
  justify-content: space-evenly;
  margin: 2vh 0vw;
}

.hub-projects-category-toggle-button {
  display: flex;
  border-radius: 5px;
  flex: direction;
  flex-direction: column;
  gap: 1vh;
  /* padding: 1vh 1vw; */
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.hub-projects-category-toggle-button-icon {
  display: inline;
  width: clamp(3rem, 10vw, 5rem);
}

.hub-projects-category-toggle-button-text {
  display: flex;
  color: var(--page_background);
  font-weight: 600;
  flex: 1;
  align-items: center;
}

.no-show {
  border-color: transparent !important;
}