.video-responsive {
  /* height: 160px; */
  /* overflow: hidden; */
  /* pointer-events: none; */
  background: none !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0; */
}

.click-enabled {
  pointer-events: auto;
  overflow: visible;
  height: inherit;
}

.click-disabled {
  pointer-events: none;
  overflow: hidden;
  height: 160px;
}
/* 
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
} */
