.sorry-page-container {
    gap: 3vh;
}

.sorry-image-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    width: 100%;
    /* flex-wrap: wrap; */
}

.sorry-image-container {
    display: flex;
    height: 25vh;
}

.sorry-image {
    object-fit: contain;
    /* height: 100%; */
}

.sorry-text {
    width: 90%;
    color: var(--white);
    text-align: center;
}

.misclassified-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.sorry-text-email {
    width: 90%;
    font-size: 1.5rem;
    color: var(--accent_orange);
    text-align: center;
}