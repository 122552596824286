.risk-stat-risk-stat {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 2vh;
  height: inherit;
  align-self: stretch;
  display: flex;
  position: relative;
  justify-content: flex-start;
}

.risk-stat-element {
  align-self: stretch;
  color: var(--black);
  font-size: 2rem;
  font-weight: 600;
  position: relative;
  text-align: center;
}

.risk-stat-chance-of-experiencing-tornado-level-wind {
  color: var(--black);
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  flex: 1;
  text-wrap: balance;
}