.icon-climate-property-high-risk {
  align-items: flex-start;
  border-radius: 25px;
  display: flex;
  height: 50px;
  padding: 7px;
  position: relative;
  width: 50px;
}

.icon-climate-icons-climate {
  align-self: stretch;
  flex: 1;
  height: 36px;
  min-width: 36px;
  position: relative;
}

.icon-climate-OutlineWhite {
  border: 2px solid;
  border-color: #fafafa;
  left: 20px;
  top: 230px;
}

.icon-climate-OutlineBlack {
  border: 2px solid;
  border-color: #262626;
  left: 20px;
  top: 294px;
}

.icon-climate-MediumRisk {
  background-color: #fbb500;
  left: 20px;
  top: 90px;
}

.icon-climate-MediumRiskDeselected {
  background-color: #fbb500;
  left: 85px;
  opacity: 0.3;
  top: 90px;
}

.icon-climate-LowRisk {
  background-color: #468347;
  left: 20px;
  top: 160px;
}

.icon-climate-LowRiskDeselected {
  background-color: #468347;
  left: 85px;
  opacity: 0.3;
  top: 160px;
}

.icon-climate-HighRiskDeselected {
  background-color: #e73b15;
  left: 85px;
  opacity: 0.3;
  top: 20px;
}

.icon-climate-HighRisk {
  background-color: #e73b15;
  left: 20px;
  top: 20px;
}

.icon-climate-Hover {
  background-color: #262626;
  left: 169px;
  top: 20px;
}
