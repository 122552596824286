.deep-breath-frame {
  align-items: center;
  align-self: stretch;
  background-color: #f6e6db;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

/* .deep-breath {
  align-items: flex-start;
  background-color: #115453;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 1400px;
} */

.deep-breath-headline {
  color: var(--page_background);
  /* font-size: 60px; */
  /* font-weight: 400; */
  letter-spacing: 0;
  /* line-height: 70px; */
  position: relative;
  text-align: center;
  white-space: normal;
  /* width: 800px; */
}



.deep-breath-paragraph {
  display: flex;
  flex-direction: column;
  gap: inherit;
}


.deep-breath-paragraph-text {
  color: #115453;
  font-size: 18px;
  /* font-weight: 600; */
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  /* width: 708px; */
}

.deep-breath-search-bar-container-desktop {
  width: 40vw;
  height: 4.5vh;
}

.deep-breath-search-bar-container-tablet {
  width: 40vw;
  height: 4.5vh;
}

.deep-breath-search-bar-container-mobile {
  width: 70vw;
  height: 4.5vh;
}
