.value-prop {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  justify-content: center;
  /* gap: 40px;
  padding: 50px 10px;
  width: 100%; */
  background-color: var(--hub_background);
  border-radius: var(--border_radius_container);
}

.value-prop .message-2 {
  background-color: #f6e6db;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 50px 10px;
  position: relative;
}

.value-prop-title-container {
  color: var(--page_background);
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  align-self: center;
  /* width: 1176px; */
}

.value-prop-items-bar {
  gap: 10px;
  align-self: stretch;
  justify-content: center;
  padding: 10px 40px 30px;
  position: relative;
  display: grid;
  gap: 5vw;
  /* overflow: hidden; */
  /* padding: 10px 40px 30px; */
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.value-prop .v-value-item {
  flex: 1 !important;
  width: unset !important;
}

.value-prop .icon-8 {
  height: 156.69px !important;
  margin-top: -0.59px !important;
  min-width: 170px !important;
  position: relative !important;
}

.value-prop .v2-value-item {
  flex: 1 !important;
  width: unset !important;
}

.value-prop .value-item-instance {
  flex: 1 !important;
  width: unset !important;
}

.value-prop-methodology-bar {
  align-items: flex-start;
  align-self: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ce9870;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 3px 0px;
  position: relative;
  width: fit-content;
}

.value-prop-methodology-text {
  color: var(--accent_orange);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.value-prop .text-wrapper-2 {
  color: #ce9870;
}

.value-prop .text-wrapper-3 {
  color: #ce9870;
  font-weight: 600;
}

.value-prop .icon-9 {
  height: 156.1px !important;
  min-width: 170.08px !important;
  position: relative !important;
}

.value-prop .icon-10 {
  height: 156.1px !important;
  min-width: 170.08px !important;
  position: relative !important;
}
