/* .project-card-accordian-labor-container {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: flex;
  position: relative;
} */

.project-card-accordian-labor-frame {
  align-self: stretch;
  align-items: center;
  gap: 30px;
  padding: 20px 0;
  display: flex;
  position: relative;
  flex-direction: column;

}

.project-card-accordian-labor-container {
  box-shadow: unset;
  left: unset;
  top: unset;
  width: unset;
  background-color: var(--white);
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 4vh;
  padding: 10px;
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}

.project-card-accordian-labor-type-container {
  flex-direction: column;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  padding: 1%;
  border-radius: 15px;
  border: 1px solid var(--page_background);
}

.project-card-accordian-labor-type-icon-container {
  height: 10vh;
  display: flex;
  /* flex-direction: column; */
}

.project-card-accordian-labor-icon {
  height: inherit;
  /* min-width: 100px; */
  position: relative;
}

.project-card-accordian-labor-vendors-frame {
  gap: 2vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.project-card-accordian-labor-vendors-grid {
  flex-direction: row;
  grid-template-columns: repeat(auto-fit, minmax(10vw, 2fr));
  gap: 1vw;
  width: 100%;
  display: grid;
}

.project-card-accordian-labor-vendor-container {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 1.5vh;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
}

.project-card-accordian-labor-vendor-icon {
  background-position: 50%;
  background-size: cover;
  position: relative;
}