.affordability-hub-frame {
    background-color: #fff;
    border-radius: 5px;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    gap: 6vh;
    padding: 30px;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    position: relative;
}

.affordability-hub-frame-mobile {
    padding: 10px;
}

.affordability-hub-title-container {
    flex-direction: column;
    align-self: center;
    align-items: center;
    gap: 2vh;
    /* padding: 0 0 20px; */
    padding: 1%;
    border-radius: 15px;
    display: flex;
    position: relative;
    max-width: 85%;
    background-color: var(--modal_background);
}

.affordability-hub-title-text {
    color: var(--page_background);
}

.affordability-hub-title-headline {
    color: var(--page_background);
    align-self: center;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    position: relative;
}

.affordability-hub-title-headline-style {
    letter-spacing: -.3px;
    font-weight: 600;
    line-height: 36px;
}

.affordability-hub-title-subheadline-style {
    letter-spacing: 0;
    font-size: 22px;
    font-weight: 300;
    line-height: 30.8px;
    padding-left: 30px;
}

.value-hub-condition-image-container {
    height: 5vh;
}

.value-hub-condition-image {
    object-fit: contain;
    height: inherit;
}

.affordability-hub-title-paragraph {
    color: #262626;
    letter-spacing: 0;
    align-self: stretch;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    text-align: center;
}

.affordability-hub-risk-container{
    flex-direction: column;
    justify-content: center;
    gap: 4vw;
    width: 50%;
    padding: 1%;
    display: flex;
    border-radius: 15px;
    align-items: center;
}

@media (min-width: 769) {
    .affordability-hub-risk-container {
        width: 80%;
    }
}

@media (min-width: 480px) and (max-width: 769px) {
    .affordability-hub-risk-container {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .affordability-hub-risk-container {
        width: 100%;
    }
}

.affordability-hub-risk-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vh;
}

.affordability-hub-chart-container {
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    gap: 20px;
    padding: 0 0 20px;
    display: flex;
    position: relative;
    justify-content: space-between;
}

.tooltip-frame {
    background: var(--white);
    padding: 20px;
    width: fit-content;
    flex: wrap;
    gap: 1vh;
    border-radius: 10px;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
}

.tooltip-description {
    color: #262626;
    letter-spacing: 0;
    align-self: stretch;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    gap: 2vh;
    position: relative;
    margin-bottom: 1rem;
}

.tooltip-frame-datapoints-container {
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.affordability-hub-risk-label-container {
    display: flex;
    flex-direction: column;
    /* gap: 1vw; */
    align-items: center;
    width: fit-content;
}

.affordability-hub-risk-label-context-text {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--page_background);
    text-wrap: balance;
    text-align: center;
}

.assumed-income-container {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    align-items: center;
}
.assumed-income-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.assumed-income-text {
    font-size: 1.5rem;
    color: var(--page_background);
    font-weight: 600;
}

.did-you-know-text.bold {
    font-weight: 600;
}

.financial-section-text {
    text-align: center;
}

.gradient-bar-container {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    padding: 0vh 5vw;
}

.affordability-gradient-bar {
    width: 100%;
    height: 3vh;
    background-image: linear-gradient(to right, var(--accent_light), var(--yellow), var(--related_red));
    border-radius: 15px;
}