.play-with-data-button-container {
  background-color: var(--white);
  border-radius: var(--border_radius_bar);
  color: var(--accent_orange);
  flex-direction: row;
  align-items: center;
  gap: .5vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vh 1vw;
  display: flex;
  position: relative;
}

.play-with-data-button-text-bar {
  color: rgba(0, 0, 0, 0);
  letter-spacing: 0;
  font-size: 12px;
  font-weight: bold;
  font-style: bold;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 16.8px;
  position: relative;
}

.play-with-data-button-text-wrapper {
  color: var(--black);
  text-align: center;
}

.play-with-data-button-span {
  color: #fff;
}

.hub-section-toggle-button-default {
  border-radius: var(--border_radius_bar);
  cursor: pointer;
  top: unset;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  padding: 1vh 1.5vw;
  display: flex;
  position: relative;
}

@media (min-width: 769px) {
  .hub-section-toggle-button-default {
    border-radius: var(--border_radius_bar);
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-section-toggle-button-default {
    border-radius: var(--border_radius_container);
  }
}

@media (max-width: 480px) {
  .hub-section-toggle-button-default {
    border-radius: var(--border_radius_container);
  }
}

.hub-section-toggle-button-user-selected {
  background-color: var(--black);
}

.hub-section-toggle-button-user-not-selected {
  background-color: inherit;
}

.hub-section-toggle-button-user-not-selected:hover {
  background-color: var(--light-grey);
}

.hub-section-toggle-button-text {
  letter-spacing: 0;
  white-space: normal;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  position: relative;
}

.hub-section-toggle-button-selected-text {
  color: var(--white);
}

.hub-header-frame {
  z-index: 2;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 2vh 0;
  display: flex;
  position: relative;
}

@media (min-width: 769px) {
  .hub-header-frame {
    justify-content: center;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-header-frame {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .hub-header-frame {
    justify-content: center;
    padding: 0;
  }
}

.hub-header-title {
  color: #262626;
  letter-spacing: -.3px;
  white-space: nowrap;
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.play-with-data-bar {
  cursor: pointer;
  align-self: stretch;
  display: flex;
}

.hub-header-toggle-bar-frame {
  align-items: center;
  align-self: inherit;
  background-color: var(--white);
  gap: 0 2vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 7vh;
  display: flex;
  position: relative;
}

@media (min-width: 769px) {
  .hub-header-toggle-bar-frame {
    border-radius: var(--border_radius_bar);
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-header-toggle-bar-frame {
    border-radius: var(--border_radius_container);
  }
}

@media (max-width: 480px) {
  .hub-header-toggle-bar-frame {
    border-radius: var(--border_radius_container);
    gap: 0 .5vw;
    width: 100%;
    overflow: auto;
  }
}

.hub-header-tablet-desktop {
  flex-direction: row;
  display: flex;
}

.hub-sections-button-container {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 40%;
  display: flex;
}

.hub-sections-button-location-ident-container {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.hub-sections-button-location-ident-hide {
  display: none !important;
}

.hub-sections-button-location-ident-text {
  color: var(--page_background);
  font-weight: 600;
}

.hub-sections-button-frame {
  border-radius: 22px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-height: 80%;
  padding: 2vh 1vw;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hub-button-selected-container {
  width: inherit;
  flex-direction: column;
  gap: 2vh;
  width: 100%;
  display: flex;
}

.hub-button-selected-container span {
  font-weight: 600;
}

.hub-sections-button-frame-selection-true {
  background-color: var(--page_background);
  color: var(--white);
}

.hub-sections-button-frame-selection-true span {
  color: var(--white);
}

.hub-sections-button-frame-selection-false {
  cursor: pointer;
  background-color: var(--light-grey);
  color: var(--black);
}

.hub-sections-button-frame-selection-false span {
  color: var(--black);
}

.hub-sections-button-text {
  color: #fff;
  letter-spacing: 0;
  white-space: normal;
  text-align: center;
  text-wrap: balance;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.arrow {
  border: 3px solid #000;
  border-width: 0 3px 3px 0;
  padding: 3px;
  display: inline-block;
}

.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hub-sections-buttons-bar-container {
  flex-direction: column;
  display: flex;
}

.hub-sections-buttons-bar {
  flex: 1;
  justify-content: space-evenly;
  align-items: stretch;
  justify-items: center;
  gap: 1vw;
  width: 50%;
  display: grid;
  position: relative;
}

@media (min-width: 769px) {
  .hub-sections-buttons-bar {
    grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
    align-items: center;
    width: 90%;
    display: grid;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-sections-buttons-bar {
    flex-direction: column;
    grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
    justify-content: center;
    align-items: center;
    width: 80%;
    display: grid;
  }
}

@media (max-width: 480px) {
  .hub-sections-buttons-bar {
    flex-direction: column;
    grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
    justify-content: center;
    align-items: center;
    width: 95%;
  }
}

.MuiBreadcrumbs-li {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.climate-icon-frame-default {
  border-radius: 80px;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 5vw;
  padding: 2.8px;
  display: flex;
  position: relative;
}

@media (min-width: 1101px) {
  .climate-icon-frame-default {
    width: 6vw;
    height: 6vw;
  }
}

@media (min-width: 770px) and (max-width: 1100px) {
  .climate-icon-frame-default {
    width: 8vw;
    height: 8vw;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .climate-icon-frame-default {
    width: 10vw;
    height: 10vw;
  }
}

@media (max-width: 480px) {
  .climate-icon-frame-default {
    width: 15vw;
    height: 15vw;
  }
}

.climate-icon-frame-selected {
  border: solid;
}

.climate-icon-color-background {
  border-radius: 80px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.climate-icon-color-high {
  background-color: #e73b15;
}

.climate-icon-color-medium {
  background-color: #ffdb33;
}

.climate-icon-image {
  height: 60%;
  position: relative;
}

.icon-climate-v5-Clear {
  min-width: 38px;
  height: 38px;
}

.icon-climate-v5-class {
  min-width: 34px;
  height: 34px;
}

.icon-climate-v5-Clear-circle {
  background-color: #dedede;
}

.icon-climate-v5-class-4 {
  background-color: #a5c941;
}

.icon-climate-v5-Variant4 {
  border: 1px solid #262626;
  left: 296px;
}

.icon-climate-v5-Clear-property-high-selected {
  border: 1px solid #777;
  left: 204px;
}

.icon-climate-v5-HighDefault {
  left: 112px;
}

.icon-climate-v5-HighSelected {
  border: 1px solid #777;
  left: 20px;
}

.climate-blurb-risk-level-container {
  border-radius: 5px;
  align-items: flex-start;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: .5rem;
  display: flex;
  position: relative;
}

.climate-blurb-risk-level-medium {
  background-color: #fbb500;
}

.climate-blurb-risk-level-low {
  background-color: #4f7b64;
}

.climate-blurb-risk-level-high {
  background-color: #e73b15;
}

.climate-blurb-risk-level-text {
  color: #fff;
  letter-spacing: .55px;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
}

.project-card-accordian-header {
  cursor: pointer;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  padding: 0;
  display: flex;
  position: relative;
}

.project-card-accordian-header-mobile {
  flex-direction: column;
  gap: 1vh;
}

.project-card-accordian-header-tablet {
  flex-direction: column;
  gap: .5vh;
}

.project-card-accordian-header-desktop {
  flex-direction: row;
}

.project-card-accordian-header-row-spaced {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 1vw;
  width: 100%;
  display: flex;
}

.project-card-accordian-header-row-close {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  width: 100%;
  display: flex;
}

.project-card-accordian-headline {
  color: var(--hub_background);
  letter-spacing: 0;
  white-space: normal;
  text-align: left;
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

@media (max-width: 480px) {
  .project-card-accordian-headline {
    text-align: center;
  }

  .report-title-share-save-frame {
    justify-content: center;
    gap: 1vh 3vw;
  }
}

.project-card-accordian-value-description {
  color: var(--hub_background);
  letter-spacing: .55px;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
}

.project-card-accordian-value {
  color: #468347;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .75rem;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.project-materials-table-container {
  background-color: var(--white);
  text-align: center;
  flex-direction: column;
  gap: 2vh;
  width: 100%;
  padding: 2vh 1vw;
  display: flex;
}

.project-card-accordian-table-container {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  display: flex;
  position: relative;
}

.project-card-accordian-table-frame {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.project-card-accordian-table-header {
  left: unset;
  top: unset;
  width: unset;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  padding: 4px 10px;
  display: flex;
  position: relative;
}

.project-card-accordion-table-header-content {
  color: #777;
  letter-spacing: .55px;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
}

.project-card-accordion-table-header-content-flex {
  color: #777;
  letter-spacing: .55px;
  white-space: nowrap;
  flex: 1;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
}

.project-card-accordian-table-data-row {
  left: unset;
  top: unset;
  width: unset;
  border-color: #dedede;
  border-top-style: solid;
  border-top-width: 1px;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  padding: 4px 10px;
  display: flex;
  position: relative;
}

.project-card-accordian-table-data-row-icon-frame {
  align-items: flex-start;
  width: 80px;
  padding: 3px 0;
  display: flex;
  position: relative;
}

.project-card-accordian-table-data-row-icon {
  background-size: 100% 100%;
  flex: 1;
  height: 40px;
  position: relative;
}

.project-card-accordian-table-data-row-description {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.project-card-accordian-table-data-row-cost {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 180px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  position: relative;
}

.project-card-accordian-table-data-row-button-frame {
  background-color: #468347;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50px;
  padding: 3px 5px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project-card-accordian-table-data-row-button-text {
  color: #fff;
  letter-spacing: .36px;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  position: relative;
}

.header-cell .MuiDataGrid-colCellTitle {
  text-overflow: unset;
}

.MuiDataGrid-root .MuiDataGrid-colCellTitleContainer {
  white-space: normal !important;
  overflow: visible !important;
}

.data-table-cost {
  font-size: 1rem;
}

.MuiDataGrid-iconButtonContainer {
  visibility: hidden;
}

.project-card-accordian-labor-frame {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.project-card-accordian-labor-container {
  box-shadow: unset;
  left: unset;
  top: unset;
  width: unset;
  background-color: var(--white);
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4vh;
  width: 100%;
  padding: 10px;
  display: flex;
  position: relative;
}

.project-card-accordian-labor-type-container {
  border: 1px solid var(--page_background);
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1%;
  display: flex;
}

.project-card-accordian-labor-type-icon-container {
  height: 10vh;
  display: flex;
}

.project-card-accordian-labor-icon {
  height: inherit;
  position: relative;
}

.project-card-accordian-labor-vendors-frame {
  text-align: center;
  flex-direction: column;
  gap: 2vh;
  width: 100%;
  display: flex;
}

.project-card-accordian-labor-vendors-grid {
  flex-direction: row;
  grid-template-columns: repeat(auto-fit, minmax(10vw, 2fr));
  gap: 1vw;
  width: 100%;
  display: grid;
}

.project-card-accordian-labor-vendor-container {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1.5vh;
  padding: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project-card-accordian-labor-vendor-icon {
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.video-responsive {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: none !important;
}

.click-enabled {
  pointer-events: auto;
  height: inherit;
  overflow: visible;
}

.click-disabled {
  pointer-events: none;
  height: 160px;
  overflow: hidden;
}

.project-card-resources-container {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.video-play-container {
  background: #e36255;
  border-radius: 5px;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 3rem;
  display: flex;
  position: absolute;
  top: 15%;
  left: 40%;
}

.video-play-icon {
  color: var(--white);
  font-size: 3rem;
}

.project-card-resources-header {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.2px;
  position: relative;
}

.project-card-resources-cards-frame {
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  width: 100%;
  display: grid;
}

.resource-cards-justify-center {
  justify-content: center;
}

.resource-cards-justify-left {
  justify-content: space-around;
}

.resource-card-article-frame {
  background-color: var(--hub_background);
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 2vh;
  padding-bottom: 1vh;
  display: flex;
  position: relative;
}

.resource-card-background-light {
  background-color: var(--hub_background);
}

.resource-card-background-dark {
  background-color: var(--page_background);
}

.resource-card-background-dark > h4 {
  color: var(--white);
}

.resource-card-background-dark > span {
  color: var(--hub_background);
}

.resource-card-thumbnail {
  object-fit: cover;
  background-position: 50%;
  background-size: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-self: stretch;
  max-width: 240px;
  height: 160px;
  position: relative;
}

.resource-card-text {
  color: var(--black);
  letter-spacing: 0;
  text-align: center;
  text-wrap: balance;
  flex: 1;
  align-self: stretch;
  padding: 1vh 1vw;
  font-weight: 600;
  position: relative;
}

.resource-card-hashtags {
  color: var(--page_background);
  text-wrap: balance;
  text-align: center;
  padding: 0 1vw;
  font-size: .8rem;
  font-style: italic;
}

.resource-wrapper {
  color: var(--black);
  cursor: pointer;
  outline: none;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.resource-wrapper:hover {
  color: var(--accent_orange);
  text-decoration: none;
}

.resource-wrapper:focus {
  box-shadow: 0 0 0 3px var(--accent_orange);
  outline: none;
}

.video-player-overlay {
  z-index: 1000;
  opacity: .9;
  background: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.video-player-container {
  z-index: 1002;
  width: 75vw;
  height: 75vh;
  position: fixed;
  top: 5%;
  left: 50%;
  right: 5%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.video-player-close-button-container {
  background: #fff;
  background-color: var(--accent_orange);
  border: 3px solid var(--white);
  cursor: pointer;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  position: absolute;
  top: 1%;
  right: 1%;
}

.video-player-close-button {
  color: var(--white);
  font-size: 2vw;
}

.resource-hashtag {
  font-weight: 600;
}

.meter-container {
  box-shadow: unset;
  left: unset;
  top: unset;
  width: unset;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 1vh;
  padding: 10px;
  display: flex;
  position: relative;
}

.meter-headline {
  color: var(--page_background);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 22px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.meter-value {
  color: var(--black);
  letter-spacing: -.22px;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  font-weight: 600;
  line-height: 26.4px;
  position: relative;
}

.meter-bar-text {
  text-align: center;
  flex: 1;
  align-items: center;
  display: flex;
}

.project-impact-section-frame {
  background-color: var(--white);
  border-radius: 5px;
  flex-direction: column;
  gap: 4vh;
  width: 100%;
  padding: 2vh 1vw;
  display: flex;
}

.project-impact-solving-for-text {
  text-align: center;
}

.project-impact-frame {
  flex-flow: wrap;
  flex: 1;
  justify-content: space-evenly;
  align-self: stretch;
  align-items: center;
  gap: 2vh 1vw;
  display: flex;
  position: relative;
}

.project-impact-bar-container {
  flex-direction: column;
  gap: 1vh;
  display: flex;
}

.project-impact-bar {
  background-color: var(--white);
  border-radius: 15px;
  align-self: center;
  width: 15vw;
  height: 1vh;
  display: flex;
  overflow: hidden;
}

.project-impact-bar-title {
  color: var(--page_background);
  text-align: center;
  width: 100%;
}

.project-impact-value {
  background-color: var(--accent_orange);
  width: 25%;
}

.project-impact-bar-scale-container {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.project-impact-bar-scale-text {
  color: var(--page_background);
  font-size: .8rem;
  font-style: italic;
}

.project-impact-checkmarks-container {
  flex-direction: column;
  gap: 1vh;
  margin-top: 1vh;
  display: flex;
}

.project-impact-checkmark-item {
  flex-direction: row;
  align-items: center;
  gap: 1vw;
  display: flex;
}

.project-impact-checkmark-true {
  color: var(--accent_orange);
}

.project-impact-checkmark-false {
  color: var(--grey);
}

.project-impact-item-text-true {
  color: var(--accent_orange);
  font-size: 1rem;
  font-weight: 600;
}

.project-impact-item-text-false {
  color: var(--grey);
  font-size: 1rem;
  font-weight: 400;
}

.discount-card-frame {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 1.5vh;
  padding: 1rem;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.discount-card-image-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

.discount-card-image {
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.discount-card-text-description {
  text-align: center;
  color: var(--page_background);
  font-size: 1rem;
  font-style: italic;
}

.discount-card-text-link {
  color: var(--page_background);
  font-size: .75rem;
}

.project-card-discount-frame {
  background-color: var(--white);
  text-align: center;
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  gap: 5vh;
  max-height: 50vh;
  padding: 2vh 1vw;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.project-card-individual-discount-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3vh;
  width: 100%;
  display: flex;
}

.project-card-individual-discount-name {
  color: var(--page_background);
  font-weight: 600;
}

.project-card-discount-frame::-webkit-scrollbar {
  width: 10px;
  position: absolute;
}

.project-card-discount-frame::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.project-card-discount-frame::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--black);
  border-radius: 10px;
}

.project-card-discount-frame::-webkit-scrollbar-button:start:increment {
  background: none;
  height: 2%;
  display: block;
}

.modal-content-frame::-webkit-scrollbar-button:end:increment {
  background: none;
  height: 2%;
  display: block;
}

.project-card-discounts-container {
  flex-direction: row;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 5fr));
  justify-content: center;
  gap: 1vw;
  width: 100%;
  display: grid;
}

.risk-stat-risk-stat {
  height: inherit;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 2vh;
  display: flex;
  position: relative;
}

.risk-stat-element {
  color: var(--black);
  text-align: center;
  align-self: stretch;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
}

.risk-stat-chance-of-experiencing-tornado-level-wind {
  color: var(--black);
  letter-spacing: 0;
  text-align: center;
  text-wrap: balance;
  flex: 1;
  align-items: center;
  font-weight: 400;
  display: flex;
  position: relative;
}

.frame-130-property-home {
  background-color: var(--hub_background);
  height: inherit;
  width: inherit;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  display: flex;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, .1);
}

.frame-130-property-home-form {
  border: none;
  border-radius: 15px;
  padding: 0;
  overflow: hidden;
}

.frame-130-property-home-selected {
  background-color: none;
  border-top: 2px solid var(--page_background);
  border-left: 2px solid var(--page_background);
}

.frame-130-image-overlay {
  background-color: rgba(0, 0, 0, .61);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2vw;
  display: flex;
  position: absolute;
}

.frame-130-image-overlay-text {
  color: var(--hub_background);
  letter-spacing: 0;
  white-space: normal;
  text-align: center;
  text-wrap: balance;
  align-self: stretch;
  font-weight: 600;
  position: relative;
}

.frame-130-image-overlay-text-not-selected {
  background-color: rgba(0, 0, 0, .87);
}

.frame-130-icons-container {
  justify-content: center;
  height: 10vh;
  max-height: 15vh;
  display: flex;
  position: relative;
}

.frame-130-icons-home {
  object-fit: contain;
  position: relative;
}

.frame-130-text-wrapper {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  white-space: wrap;
  flex: 1;
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  position: relative;
}

.frame-130-element-projects {
  color: var(--page_background);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  display: flex;
  position: relative;
}

.frame-130-Home {
  top: 20px;
}

.frame-130-class {
  top: 217px;
}

.risk-level-indicator-frame {
  background-color: #f1f1f1;
  border-radius: 15px;
  align-items: center;
  gap: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.risk-level-indicator-ellipse {
  border-radius: 4px;
  min-width: 8px;
  height: 8px;
  position: relative;
}

.risk-level-indicator-rating-text {
  color: var(--black);
  letter-spacing: .11px;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .75rem;
  font-weight: 600;
  line-height: 15.4px;
  position: relative;
}

.cost-label-cost-label {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding: 0;
  display: flex;
  position: relative;
}

.cost-label-estimated-cost {
  color: #777;
  letter-spacing: 0;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.cost-label-element {
  color: #768b76;
  letter-spacing: 0;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  position: relative;
}

.cost-list-cost-list {
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.cost-list-line {
  object-fit: cover;
  align-self: stretch;
  min-width: 1px;
  height: 48px;
  position: relative;
}

.project-status-bar-property-to-do {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100px;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.project-status-bar-frame {
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  min-width: 16px;
  height: 16px;
  position: relative;
}

.project-status-bar-icons-system {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.project-card-view-frame {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project-card-bookmark-container {
  z-index: 50;
  position: absolute;
  top: -5px;
  right: 2px;
}

.project-card-bookmark-default, .project-card-bookmark-saved {
  color: var(--accent_orange);
  font-size: var(--h3-font-size) !important;
}

.project-card-data-frame {
  flex-direction: column;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  gap: 1.5vh;
  width: 100%;
  height: 100%;
  padding-bottom: 1vh;
  display: flex;
}

.project-card-view-image-container {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.project-card-view-disconts-icon {
  color: var(--accent_orange);
  position: absolute;
  top: 0;
  left: 1px;
}

.project-card-view-image-overlay {
  height: inherit;
  width: inherit;
  background-color: rgba(0, 0, 0, .65);
  border-bottom: 1px solid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1vw;
  display: flex;
  position: absolute;
}

.project-bar-view-image {
  background-position: 50%;
  background-size: cover;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: relative;
}

.project-card-view-header-title {
  color: var(--hub_background);
  letter-spacing: 0;
  text-wrap: balance;
  text-align: center;
  align-self: stretch;
  position: relative;
  font-size: max(1rem, min(1.5vw, 1.5rem)) !important;
}

.project-card-view-header-risks-container {
  align-items: flex-start;
  gap: .75vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.project-card-view-see-more-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.project-card-view-see-more-text, .project-card-view-see-more-icon {
  font: .5rem;
  color: var(--page_background);
}

.project-card-accordian-container {
  background: var(--page_background);
  border-radius: 15px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 1vw;
  display: flex;
  position: relative;
}

.project-card-accordian-button-container {
  color: var(--hub_background);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: flex;
  position: relative;
}

.project-card-accordian-button {
  color: var(--hub_background);
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
}



.modal-climate-house-image-container {
  border-radius: 15px;
  justify-content: center;
  width: 100%;
  height: 30vh;
  display: flex;
}

.modal-climate-house-image {
  object-fit: cover;
  background-color: var(--hub_background);
  height: inherit;
  width: inherit;
  border-radius: 15px;
}

.modal-climate-house-icon {
  height: 4vh;
}

.modal-climate-title-bar {
  flex-direction: row;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.modal-climate-frame {
  border-color: #262626;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  padding: 0 0 10px;
  display: flex;
  position: relative;
}

.modal-climate-header {
  align-self: stretch;
  align-items: center;
  gap: 3rem;
  display: flex;
  position: relative;
}

.modal-climate-title {
  color: #262626;
  letter-spacing: -.5px;
  white-space: nowrap;
  align-self: center;
  font-size: 50px;
  font-weight: 600;
  position: relative;
}

.modal-climate-blurb {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.modal-climate-stats-frame {
  background-color: var(--white);
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 5px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1vw;
  padding: 2vh 1vw;
  display: flex;
  position: relative;
}

.modal-climate-subsection-header {
  letter-spacing: -.5px;
  font-size: 50px;
  line-height: 60px;
}

.modal-climate-areas-of-home-affected-container {
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 10px;
  width: 100%;
  display: grid;
  position: relative;
}

.climate-blurb-frame {
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  gap: 1vh;
  width: 80%;
  padding: 10px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.climate-blurb-frame-mobile {
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding: 10px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.climate-blurb-image-container {
  justify-content: center;
  align-items: flex-start;
  width: 20vw;
  display: flex;
  position: relative;
}

.climate-blurb-image-container-mobile {
  height: inherit;
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.climate-blurb-image {
  min-width: 100px;
  height: 100px;
  position: relative;
}

.climate-blurb-image-mobile {
  object-fit: contain;
  min-width: 100%;
  height: 100%;
}

.climate-blurb-text-frame {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 2vh;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.climate-blurb-headline-container {
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.climate-blurb-headline-container-mobile {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 3rem;
  display: flex;
  position: relative;
}

.climate-blurb-risk-name {
  color: #262626;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  position: relative;
}

.climate-blurb-paragraph {
  color: var(--black);
  letter-spacing: 0;
  align-self: stretch;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.climate-blurb-paragraph-mobile {
  text-align: center;
}

.climate-blurb-expand-details {
  cursor: pointer;
  color: var(--page_background);
  letter-spacing: 0;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  position: relative;
}

.hub-house-category-container {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 3px 0 0;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.hub-house-category-container-dot {
  cursor: pointer;
  background-color: #fff;
  border-radius: 25px;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.hub-house-category-container-list {
  width: inherit;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  gap: 2vw;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hub-house-category-container-dot.General-Property, .hub-house-category-container.General-Property {
  top: 20%;
  left: 0%;
}

.hub-house-category-container-dot.Roof {
  top: 15%;
  left: 30%;
}

.hub-house-category-container.Roof {
  top: 10%;
  left: 30%;
}

.hub-house-category-container-dot.Plumbing {
  bottom: 0%;
  right: 20%;
}

.hub-house-category-container.Plumbing {
  top: 80%;
  left: 67%;
}

.hub-house-category-container-dot.Landscaping {
  top: 25%;
  left: 2%;
}

.hub-house-category-container.Landscaping {
  top: 75%;
  left: 2%;
}

.hub-house-category-container-dot.Siding {
  top: 40%;
  left: 78%;
}

.hub-house-category-container.Siding {
  top: 50%;
  left: 82%;
}

.hub-house-category-container-dot.HVAC, .hub-house-category-container.HVAC {
  top: 20%;
  left: 62%;
}

.hub-house-category-container.Doors {
  top: 73%;
  left: 26%;
}

.hub-house-category-container-dot.Doors {
  top: 55%;
  left: 29%;
}

.hub-house-category-container.Windows {
  top: 43%;
  left: 60%;
}

.hub-house-category-container-dot.Windows {
  top: 53%;
  left: 61%;
}

.hub-house-category-container.Electrical, .hub-house-category-container-dot.Electrical {
  top: 39%;
  left: 39%;
}

.hub-house-category-container.Foundation {
  top: 80%;
  left: 45%;
}

.hub-house-category-container-dot.Foundation {
  top: 70%;
  left: 45%;
}

.hub-house-category-risk-level {
  align-self: stretch;
  min-width: 10px;
  position: relative;
}

.hub-house-category-risk-level.High {
  background-color: #ff2f00;
}

.hub-house-category-risk-level.Medium, .hub-house-category-risk-level.Low {
  background-color: #ebeb06;
}

.hub-house-category-text-container {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: .75vh;
  padding: .5vh .5vw;
  display: flex;
  position: relative;
}

.hub-house-category-text-name {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  align-items: center;
  gap: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .9rem;
  font-weight: 600;
  display: flex;
  position: relative;
}

.hub-house-category-text-projects {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  flex-direction: column;
  align-items: flex-start;
  gap: .25vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .75rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

.hub-house-category-arrow {
  min-width: 14px;
  height: 14px;
  position: relative;
}

.climate-factors-home-hub-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 1200px;
  padding: 0 7px 21px;
  display: flex;
  position: relative;
}

.climate-factors-home-hub-container-mobile {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 0 7px 5px;
  display: flex;
  position: relative;
}

.climate-factors-home-hub-image {
  object-fit: cover;
  position: relative;
}

.overview-hub-v5-home-overview {
  color: #262626;
  letter-spacing: -.3px;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  position: relative;
}

.climate-factors-mobile-list-container {
  flex-direction: column;
  align-items: center;
  gap: .75vh;
  width: 90%;
  display: flex;
}

.hub-house-category-text-projects-list {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  flex-direction: row;
  align-items: flex-start;
  gap: 1vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .75rem;
  font-weight: 400;
  display: flex;
  position: relative;
}

.hub-value-category-stat-container {
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  padding: 2%;
  display: flex;
}

.hub-value-category-stat-container-general {
  border: 1px solid var(--black);
}

.hub-value-category-stat-container-extra-spacing {
  gap: 5vh;
}

.what-and-why-to-care {
  color: var(--page_background);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .5vw;
  width: 100%;
  padding: 0 .25vw;
  display: flex;
}

.what-and-why-to-care-climate {
  width: unset !important;
}

.what-and-why-to-care-text {
  font-style: italic;
}

.financial-learn-more-header {
  color: var(--white);
  font-size: 2rem;
  font-style: italic;
}

.financial-learn-more-text {
  color: var(--white);
  font-size: 1rem;
}

.financial-datapoint-hover-learn-more-container {
  background-color: var(--page_background);
  color: var(--white);
  text-wrap: balance;
  z-index: 50000;
  text-align: center;
  border-radius: 25px;
  flex-direction: column;
  gap: 2vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 25vw;
  min-height: 15vh;
  padding: 1vh 1vw;
  display: flex;
  position: absolute;
  -webkit-transform: translateY(-20vh);
  transform: translateY(-20vh);
}

.datapoint-hover-i {
  cursor: pointer;
}

.climate-factors-icons-container {
  cursor: pointer;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 2vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.select-a-climate-factor-container {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.select-a-climate-factor-text {
  color: #777;
  letter-spacing: 0;
  text-align: center;
  white-space: normal;
  text-wrap: balance;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.clear-selection-text {
  cursor: pointer;
}

.details-HurricaneHover {
  top: 182px;
}

.details-HurricaneSelected {
  top: 668px;
}

.details-ClearHurricane {
  top: 1154px;
}

.details-ClearWind {
  top: 1316px;
}

.details-ClearStorm {
  top: 1478px;
}

.details-WindSelected {
  top: 830px;
}

.details-StormSelected {
  top: 992px;
}

.details-WindHover {
  top: 344px;
}

.details-StormHover {
  top: 506px;
}

.climate-data-stats-bar-container {
  flex-flow: wrap;
  justify-content: space-around;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.icon-climate-property-high-risk {
  border-radius: 25px;
  align-items: flex-start;
  width: 50px;
  height: 50px;
  padding: 7px;
  display: flex;
  position: relative;
}

.icon-climate-icons-climate {
  flex: 1;
  align-self: stretch;
  min-width: 36px;
  height: 36px;
  position: relative;
}

.icon-climate-OutlineWhite {
  border: 2px solid #fafafa;
  top: 230px;
  left: 20px;
}

.icon-climate-OutlineBlack {
  border: 2px solid #262626;
  top: 294px;
  left: 20px;
}

.icon-climate-MediumRisk {
  background-color: #fbb500;
  top: 90px;
  left: 20px;
}

.icon-climate-MediumRiskDeselected {
  opacity: .3;
  background-color: #fbb500;
  top: 90px;
  left: 85px;
}

.icon-climate-LowRisk {
  background-color: #468347;
  top: 160px;
  left: 20px;
}

.icon-climate-LowRiskDeselected {
  opacity: .3;
  background-color: #468347;
  top: 160px;
  left: 85px;
}

.icon-climate-HighRiskDeselected {
  opacity: .3;
  background-color: #e73b15;
  top: 20px;
  left: 85px;
}

.icon-climate-HighRisk {
  background-color: #e73b15;
  top: 20px;
  left: 20px;
}

.icon-climate-Hover {
  background-color: #262626;
  top: 20px;
  left: 169px;
}

.modal-house-frame {
  border-color: #262626;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  padding: 0 0 10px;
  display: flex;
  position: relative;
}

.modal-house-header {
  align-self: stretch;
  align-items: center;
  gap: 5px;
  display: flex;
  position: relative;
}

.modal-house-title {
  color: #262626;
  letter-spacing: -.5px;
  white-space: nowrap;
  align-self: stretch;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  position: relative;
}

.modal-climate-p {
  color: #262626;
  letter-spacing: 0;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.modal-climate-frame-72 {
  border-color: #262626;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.modal-climate-frame-69 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.modal-climate-element {
  color: #262626;
  letter-spacing: -.4px;
  text-align: center;
  align-self: stretch;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  position: relative;
}

.modal-climate-span {
  color: #262626;
  letter-spacing: -.4px;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.modal-climate-text-wrapper-2 {
  letter-spacing: -.5px;
  font-size: 50px;
  line-height: 60px;
}

.modal-climate-chance-of-experiencing-tornado-level-wind {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  position: relative;
}

.modal-climate-text-wrapper-3 {
  color: #262626;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

.modal-climate-text-wrapper-4 {
  font-weight: 600;
}

.modal-climate-box-climate-risk-icon-x {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  left: 773px;
}

.modal-climate-text-wrapper-5 {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  position: relative;
}

.modal-climate-frame-133 {
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.div-wrapper-text-wrapper-3 {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  position: relative;
}

.form-entry-container {
  background: var(--white);
  width: inherit;
  border: 1px solid #dedede;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 1vh;
  padding: 5px 10px;
  display: flex;
  position: relative;
}

.form-entry-frame {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
  position: relative;
}

.form-entry-headline {
  letter-spacing: .11px;
  white-space: break-spaces;
  text-align: center;
  align-self: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  line-height: 15.4px;
  position: relative;
}

.form-entry-input {
  color: #262626;
  letter-spacing: 0;
  white-space: nowrap;
  justify-content: center;
  align-self: center;
  display: flex;
  position: relative;
}

.form-entry-input-box {
  text-align: center;
  border: 1px solid #dadce0;
  border-radius: 5px;
  outline: none;
  width: 85%;
}

.did-you-know-icon {
  min-width: 30.31px;
  height: 30px;
  position: relative;
}

.did-you-know-text {
  color: #262626;
  letter-spacing: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

input:focus::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0);
  font-style: italic;
}

input:focus::placeholder {
  color: rgba(0, 0, 0, 0);
  font-style: italic;
}

.modal-user-form-description-text {
  text-align: center;
  color: var(--page_background);
  text-wrap: balance;
  font-size: 1rem;
}

.modal-user-form-selection-reminder-text-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.modal-user-form-selection-reminder-text {
  text-align: center;
  font-size: 1rem;
  font-style: italic;
}

.modal-user-input-form-text {
  color: #262626;
  letter-spacing: 0;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.modal-user-input-form-data-categories-bar {
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.modal-user-input-form-data-radio-button-bar {
  align-self: stretch;
  align-items: flex-start;
  gap: 5rem;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.modal-user-input-form-datapoints-frame {
  grid-template-columns: repeat(auto-fill, minmax(15rem, max-content));
  justify-content: space-evenly;
  align-self: stretch;
  align-items: stretch;
  gap: 10px;
  width: 100%;
  display: grid;
  position: relative;
}

.modal-user-input-form-datapoints-frame-mobile {
  grid-template-columns: repeat(auto-fill, minmax(30vw, max-content));
}

.modal-user-input-form-datapoints-frame-tablet {
  grid-template-columns: repeat(auto-fill, minmax(23vw, max-content));
}

.modal-user-input-form-datapoints-frame-desktop {
  grid-template-columns: repeat(auto-fill, minmax(15vw, max-content));
}

.modal-user-form-submit-button-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  width: 100%;
  padding-bottom: 2vh;
  display: flex;
}

.modal-user-form-submit-button {
  background-color: var(--page_background);
  color: var(--hub_background);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vh 2vh;
  font-size: 1.2rem;
}

.modal-user-form-house-category-cards-grid {
  flex-flow: wrap;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 3fr));
  gap: 1vw;
  width: 100%;
  display: grid;
}

.modal-user-form-house-category-card-frame {
  background-color: var(--hub_background);
  cursor: pointer;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1vh;
  padding: 1vh 1vw;
  display: flex;
}

.modal-user-form-house-category-card-frame-default {
  background-color: var(--hub_background);
}

.modal-user-form-house-category-card-frame-not-selected {
  background-color: var(--grey);
}

.modal-user-form-house-category-card-image-container {
  height: 5vh;
  display: flex;
}

.modal-user-form-house-category-card-image {
  object-fit: contain;
}

.modal-user-form-house-category-card-category-text {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
}

.modal-user-form-house-category-card-category-context-text {
  color: var(--page_background);
  font-size: .85rem;
  font-style: italic;
  font-weight: 600;
}

.modal-user-form-user-inputs-count-text {
  color: var(--page_background);
  font-size: .75rem;
  font-style: italic;
}

@-webkit-keyframes snackbarInRight {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes snackbarInRight {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.snack-bar-container {
  z-index: 5500;
  background-color: var(--hub_background);
  border: 1px solid var(--page_background);
  border-radius: 8px;
  justify-content: start;
  min-width: 300px;
  max-width: 560px;
  padding: .75rem;
  font-weight: 600;
  transition: -webkit-transform .3s ease-out, transform .3s ease-out;
  display: flex;
  position: fixed;
  left: auto;
}

.snack-bar-text {
  color: var(--page_background);
}

.modal-project-header-bar {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  row-gap: 1vh;
  width: 100%;
  display: flex;
}

.modal-project-breadcrumb-bar {
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  align-items: center;
  gap: .5vw;
  display: flex;
  position: relative;
}

.modal-project-breadcrumb-item {
  color: var(--page_background);
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: 600;
}

.modal-project-breadcrumb-item-clickable {
  cursor: pointer;
}

.modal-project-image-container {
  justify-content: center;
  width: 100%;
  height: 30vh;
  display: flex;
}

.modal-project-image {
  object-fit: cover;
  height: inherit;
  width: inherit;
  border-radius: 15px;
}

.modal-project-title-bar {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.modal-project-professional-v-diy-text {
  color: #6b658b;
  letter-spacing: 0;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  position: relative;
}

.modal-project-description {
  text-align: center;
}

.modal-project-user-project-mgt-buttons-bar {
  align-items: flex-start;
  gap: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 0;
  display: flex;
  position: relative;
}

.modal-project-user-project-mgt-button-container {
  background-color: var(--accent_orange);
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vh .5vw;
  display: flex;
  position: relative;
}

.modal-project-user-project-mgt-button-container-saved-true {
  background-color: var(--page_background);
}

.modal-project-user-project-mgt-button-container-saved-false {
  background-color: var(--accent_orange);
}

.modal-project-user-project-mgt-button-text {
  color: var(--hub_background);
  letter-spacing: 0;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .75rem;
  font-weight: 600;
  position: relative;
  line-height: inherit !important;
}

.meter-bar-frame {
  background-color: #fff;
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 5px;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 1vw;
  padding: 2vh 1vw;
  display: grid;
  position: relative;
}

.modal-project-accordians-container {
  flex-direction: column;
  gap: .5vh;
  width: 100%;
  display: flex;
}

.project-saved-checkmark {
  height: inherit;
}

.start-button-frame {
  background-color: var(--accent_orange);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 5vh;
  display: flex;
  position: relative;
}

.start-button-frame-what-to-do {
  cursor: pointer;
  height: 4vh;
}

.start-button-frame-extra-padding-true {
  padding: .5vh 5vw;
}

.start-button-frame-extra-padding-false {
  padding: 1vh 1vw;
}

.start-button-frame-guidance-navigation-true {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding: .5vh .5vw !important;
}

.start-button-text {
  color: var(--white);
  letter-spacing: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

.error-text {
  color: var(--white);
}

.user-auth-signup-form-container-row {
  flex-flow: wrap;
  justify-content: center;
  justify-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.user-auth-signup-form-container-row > div {
  background-color: var(--white);
  border-radius: 5px;
  min-width: 35rem;
}

.terms-and-conditions-link {
  color: var(--page_background);
}

.input-label-top-view-container {
  background-color: var(--white);
  border-radius: 5px;
  color: var(--page_background) !important;
  padding: 0% 1% !important;
  font-weight: 600 !important;
}

.input-label-top-view-container label {
  color: var(--page_background) !important;
}

.sign-in-container {
  justify-content: space-around;
  width: 100%;
  display: flex;
}

.sign-in-container-column {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}

.sign-in-container-row {
  flex-direction: row;
}

.signin-up-or-text {
  font-style: italic;
  font-weight: 400;
  font-family: larken, sans-serif !important;
}

.sign-in-form-container {
  flex-direction: column;
  display: flex;
}

.user-auth-signup-form-container {
  flex-flow: column wrap;
  justify-content: center;
  display: flex;
}

.user-auth-signup-form-container * {
  width: inherit;
}

.sso-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sso-container iFrame {
  width: inherit;
}

.sso-image {
  height: 50%;
}

.vertical-divider {
  border-left: 2px solid var(--page_background);
}

.auth-user-header-text {
  color: var(--page_background);
}

.button-text {
  color: var(--white);
  text-align: center;
  width: 100%;
  min-width: 10vw;
}

.auth-modal-return-to-previous-container {
  background-color: var(--accent_orange);
  color: var(--white);
  cursor: pointer;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  gap: 1vw;
  margin-left: 2%;
  padding: .5vh 1rem;
  display: flex;
}

.auth-modal-return-to-previous-container-text {
  color: var(--white);
  font-weight: 800;
}

.auth-user-choices-container {
  background: var(--hub_background);
  border-radius: 2vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  width: 100%;
  padding: 2vw;
  display: flex;
}

.auth-user-choices-container-modal {
  background: none;
}

.auth-user-choices-container-desktop {
  width: 70vw;
}

.auth-user-choices-container-tablet {
  width: 90vw;
}

.auth-user-choices-container-mobile {
  width: 95vw;
}

.user-auth-choice-box {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  width: 100%;
  display: flex;
}

.auth-user-reasons-to-join-container {
  flex-direction: column;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 2fr));
  justify-content: center;
  justify-items: center;
  gap: 1vh;
  width: 100%;
  display: grid;
}

.auth-user-reason-to-join-box {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1vw;
  display: flex;
}

.auth-user-reason-to-join-text {
  text-align: center;
  text-wrap: balance;
  flex: 1;
  align-items: center;
  font-size: 1rem;
  display: flex;
}

.auth-user-reason-to-join-check {
  color: var(--page_background);
}

.user-auth-choice-button {
  background: var(--accent_orange);
  cursor: pointer;
  border-radius: 5px;
  display: flex;
}

.user-auth-view-toggle-text {
  color: var(--page_background);
  cursor: pointer;
  text-align: center;
  font-size: var(--h4-font-size) !important;
}

.user-auth-view-toggle-text:hover {
  cursor: pointer;
  color: var(--black);
}

.user-auth-form-error-message {
  color: red;
  font-style: italic;
}

.card-background-overlay {
  z-index: 1000;
  background-color: rgba(72, 69, 69, .88);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.card-container-x-icon {
  color: var(--black);
}

.modal-selector-boxes-frame {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 100%;
  display: flex;
}

.modal-selector-boxes-grid {
  flex-direction: row;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  justify-content: center;
  justify-items: center;
  width: 100%;
  display: grid;
  position: relative;
}

@media (min-width: 769px) {
  .modal-selector-boxes-grid {
    gap: 2vh 5vw;
    width: 90%;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .modal-selector-boxes-grid {
    gap: 2vh 3vw;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .modal-selector-boxes-grid {
    justify-content: space-evenly;
    gap: 2vh 2vw;
    width: 100%;
  }
}

.tooltip-total-amount-text {
  font-weight: 800;
}

.test {
  font-size: 1rem;
}

.affordability-hub-frame {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6vh;
  padding: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.affordability-hub-frame-mobile {
  padding: 10px;
}

.affordability-hub-title-container {
  background-color: var(--modal_background);
  border-radius: 15px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 2vh;
  max-width: 85%;
  padding: 1%;
  display: flex;
  position: relative;
}

.affordability-hub-title-text {
  color: var(--page_background);
}

.affordability-hub-title-headline {
  color: var(--page_background);
  align-self: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.affordability-hub-title-headline-style {
  letter-spacing: -.3px;
  font-weight: 600;
  line-height: 36px;
}

.affordability-hub-title-subheadline-style {
  letter-spacing: 0;
  padding-left: 30px;
  font-size: 22px;
  font-weight: 300;
  line-height: 30.8px;
}

.value-hub-condition-image-container {
  height: 5vh;
}

.value-hub-condition-image {
  object-fit: contain;
  height: inherit;
}

.affordability-hub-title-paragraph {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.affordability-hub-risk-container {
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  width: 50%;
  padding: 1%;
  display: flex;
}

@media (min-width: 769px) {
  .affordability-hub-risk-container {
    width: 80%;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .affordability-hub-risk-container {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .affordability-hub-risk-container {
    width: 100%;
  }
}

.affordability-hub-risk-frame {
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  display: flex;
}

.affordability-hub-chart-container {
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  padding: 0 0 20px;
  display: flex;
  position: relative;
}

.tooltip-frame {
  background: var(--white);
  flex: wrap;
  border: 1px solid #000;
  border-radius: 10px;
  flex-direction: column;
  gap: 1vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px;
  display: flex;
}

.tooltip-description {
  color: #262626;
  letter-spacing: 0;
  align-self: stretch;
  gap: 2vh;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}

.tooltip-frame-datapoints-container {
  flex-direction: column;
  gap: 1vh;
  display: flex;
}

.affordability-hub-risk-label-container {
  flex-direction: column;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.affordability-hub-risk-label-context-text {
  color: var(--page_background);
  text-wrap: balance;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.assumed-income-container {
  flex-direction: row;
  align-items: center;
  gap: 1vw;
  display: flex;
}

.assumed-income-icon-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.assumed-income-text {
  color: var(--page_background);
  font-size: 1.5rem;
  font-weight: 600;
}

.did-you-know-text.bold {
  font-weight: 600;
}

.financial-section-text {
  text-align: center;
}

.gradient-bar-container {
  flex-direction: column;
  gap: 0;
  width: 100%;
  padding: 0 5vw;
  display: flex;
}

.affordability-gradient-bar {
  background-image: linear-gradient(to right, var(--accent_light), var(--yellow), var(--related_red));
  border-radius: 15px;
  width: 100%;
  height: 3vh;
}

.value-hub-frame {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 6vh;
  padding: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.value-hub-intro-container {
  text-align: center;
  flex-direction: column;
  gap: 3vw;
  display: flex;
}

.value-hub-factors-container {
  text-align: center;
  flex-flow: wrap;
  justify-content: center;
  gap: 3vw;
  display: flex;
}

.value-hub-intro-paragraph {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-weight: 400;
  position: relative;
}

.value-hub-condition-bills-container {
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4vh;
  display: flex;
}

.value-hub-condition-bills-two-images-container {
  flex-direction: row;
  gap: 2vw;
  height: 5vh;
  display: flex;
}

.value-hub-condition-bills-images-container {
  flex: 1;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 4fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 2vh 2vw;
  padding: 0 10vw;
  display: grid;
}

.value-hub-condition-bills-image {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.hub-value-plus-icon-container {
  color: var(--accent_orange);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.hub-value-data-category-choices-container {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 100%;
  display: flex;
}

.hub-value-data-choices-grid {
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 1vh 1vw;
  width: 100%;
  display: grid;
  position: relative;
}

.hub-value-individual-dataset-container {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 15vh;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hub-value-individual-dataset-container-selected {
  background-color: none;
  border-top: 2px solid var(--page_background);
  border-left: 2px solid var(--page_background);
}

.hub-value-individual-dataset-container-overlay {
  background-color: rgba(0, 0, 0, .52);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 .5vw;
  display: flex;
  position: absolute;
}

.hub-value-individual-dataset-container-overlay-not-selected {
  background-color: rgba(0, 0, 0, .8);
}

.hub-value-individual-dataset-container-overlay-text {
  color: var(--hub_background);
  letter-spacing: 0;
  white-space: normal;
  text-align: center;
  align-self: stretch;
  font-weight: 600;
  position: relative;
}

.hub-value-reminder-text-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  width: 100%;
  display: flex;
}

.hub-value-reminder-text {
  text-align: center;
  font-size: 1.25rem;
  font-style: italic;
}

.related-category-shadow-selected {
  background-color: #115453;
  border-radius: 15px;
  width: 5vw;
  height: .5vh;
}

.related-category-shadow-explainer {
  background-color: rgba(242, 126, 126, .44);
  border-radius: 15px;
  width: 5vw;
  height: .5vh;
}

.hub-value-category-stats-grid {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 4fr));
  gap: 3vh 1vw;
  width: 100%;
  display: grid;
}

.hub-value-category-context-text {
  text-align: center;
}

.affordability-hub-risk-score-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;
  width: 100%;
  display: flex;
}

.affordability-hub-risk-text-container {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.affordability-hub-risk-text-header {
  font-size: 1rem;
  font-weight: 400;
}

.affordability-hub-risk-text-context {
  font-size: 1.25rem;
  font-weight: 600;
}

.financial-impact-header-frame {
  left: unset;
  top: unset;
  justify-content: center;
  align-self: center;
  align-items: flex-start;
  gap: 5vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.financial-impact-header-general {
  letter-spacing: 0;
  cursor: pointer;
  background-color: var(--white);
  text-align: center;
  border-radius: 15px;
  flex-direction: column;
  align-self: stretch;
  align-items: normal;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2vh 2vw;
  font-weight: 500;
  display: flex;
  position: relative;
}

.financial-impact-header-active {
  color: var(--page_background);
  border-color: var(--page_background);
  border-bottom-style: solid;
  border-bottom-width: .5rem;
  font-weight: 600;
}

.financial-impact-header-headline {
  font-style: italic;
  font-weight: 400 !important;
}

.financial-impact-toggle-header {
  font-weight: 600;
}

.hub-projects-filters-wrapper {
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px 0 0;
  display: flex;
  position: relative;
}

.hub-selection-title-context {
  text-align: center;
  text-wrap: balance;
  width: 100%;
  font-weight: 400;
}

.hub-projects-category-toggle-bar {
  grid-template-columns: repeat(auto-fit, minmax(2rem, 8rem));
  justify-content: space-evenly;
  gap: 2vw;
  width: 100%;
  margin: 2vh 0;
  display: grid;
}

.hub-projects-category-toggle-button {
  flex: direction;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.hub-projects-category-toggle-button-icon {
  width: max(3rem, min(10vw, 5rem));
  display: inline;
}

.hub-projects-category-toggle-button-text {
  color: var(--page_background);
  flex: 1;
  align-items: center;
  font-weight: 600;
  display: flex;
}

.no-show {
  border-color: rgba(0, 0, 0, 0) !important;
}

.hub-projects-filter-bar-frame {
  z-index: 3;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-self: stretch;
  align-items: flex-start;
  gap: 10vw;
  display: flex;
  position: relative;
}

.hub-projects-filters-wrapper {
  cursor: pointer;
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px 0 0;
  display: flex;
  position: relative;
}

.hub-projects-filter-menus-container {
  align-items: flex-start;
  gap: 2vw;
  display: flex;
}

.hub-projects-display-toggle-and-showing-text {
  flex-direction: row;
  display: flex;
}

.hub-projects-filter-button {
  color: var(--page_background);
}

.hub-projects-filter-text {
  color: var(--page_background);
  letter-spacing: .36px;
  white-space: nowrap;
  align-self: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
}

.hub-projects-filter-text-mobile {
  text-align: center;
}

.hub-projects-filter-bar-frame select {
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.hub-projects-filter-box option {
  z-index: 1;
  width: 200px;
  display: block;
  position: absolute;
  top: 0;
}

.hub-project-filter-item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.hub-project-filter-icon {
  color: var(--page_background);
}

.hub-project-filter-item-text {
  color: var(--page_background);
  text-wrap: balance;
  text-align: center;
  font-size: 1.25rem;
  font-style: italic;
}

.project-bar-view-frame {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
  border-radius: 5px;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project-bar-data-frame-mobile {
  flex-direction: column;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  gap: 1.5vh;
  width: 100%;
  height: 100%;
  display: flex;
}

.project-bar-view-image-overlay {
  height: inherit;
  width: inherit;
  background-color: rgba(0, 0, 0, .65);
  border-bottom: 1px solid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.project-bar-view-image-container {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px 10px 10px;
  display: flex;
  position: relative;
}

.project-bar-view-image-container-mobile {
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
  display: flex;
  position: relative;
}

.project-bar-view-image {
  background-position: 50%;
  background-size: cover;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: relative;
}

.project-bar-view-mobile-info-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.project-bar-view-header-container {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.project-bar-view-header-title {
  color: #262626;
  letter-spacing: 0;
  white-space: normal;
  align-self: stretch;
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

.project-bar-view-header-title-mobile {
  text-align: center;
  color: var(--hub_background);
}

.project-bar-view-header-risks-container {
  align-items: flex-start;
  gap: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.projects-list-frame {
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 1vh;
  min-width: -webkit-fill-available;
  display: grid;
}

.project-cards-frame {
  grid-template-columns: repeat(auto-fit, minmax(10rem, 21rem));
  justify-content: space-around;
  align-self: stretch;
  align-items: stretch;
  gap: 3vh 1vw;
  width: 100%;
  display: grid;
  position: relative;
}

.recommended-project-container {
  flex-direction: column;
  gap: 2vh;
  display: flex;
}

.recommended-project-checklist-container {
  flex-direction: column;
  align-self: center;
  gap: 1vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.recommended-project-why-this-project-text {
  color: var(--page_background);
  text-align: center;
  font-weight: 600;
}

.recommended-project-checklist-item {
  flex-direction: row;
  align-items: center;
  gap: 1vw;
  display: flex;
}

.recommended-project-checklist-item span {
  color: var(--page_background);
}

.recommended-project-checklist-icon {
  font-size: var(--span-m-font-size);
  color: var(--page_background);
}

.hub-projects-filters-wrapper {
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px 0 0;
  display: flex;
  position: relative;
}

.hub-selection-title-context {
  text-align: center;
  text-wrap: balance;
  width: 100%;
  font-weight: 400;
}

.hub-projects-category-toggle-bar {
  grid-template-columns: repeat(auto-fit, minmax(2rem, 8rem));
  justify-content: space-evenly;
  gap: 2vw;
  width: 100%;
  margin: 2vh 0;
  display: grid;
}

.hub-projects-category-toggle-button {
  flex: direction;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.hub-projects-category-toggle-button-icon {
  width: max(3rem, min(10vw, 5rem));
  display: inline;
}

.hub-projects-category-toggle-button-text {
  color: var(--page_background);
  flex: 1;
  align-items: center;
  font-weight: 600;
  display: flex;
}

.no-show {
  border-color: rgba(0, 0, 0, 0) !important;
}

.project-card-view-frame {
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.project-card-bookmark-container {
  z-index: 50;
  position: absolute;
  top: -5px;
  right: 2px;
}

.project-card-bookmark-default, .project-card-bookmark-saved {
  color: var(--accent_orange);
  font-size: var(--h3-font-size) !important;
}

.project-card-data-frame {
  flex-direction: column;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  gap: 1.5vh;
  width: 100%;
  height: 100%;
  padding-bottom: 1vh;
  display: flex;
}

.project-card-view-image-container {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.project-card-view-disconts-icon {
  color: var(--accent_orange);
  position: absolute;
  top: 0;
  left: 1px;
}

.project-card-view-image-overlay {
  height: inherit;
  width: inherit;
  background-color: rgba(0, 0, 0, .65);
  border-bottom: 1px solid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1vw;
  display: flex;
  position: absolute;
}

.project-bar-view-image {
  background-position: 50%;
  background-size: cover;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: relative;
}

.project-card-view-header-title {
  color: var(--hub_background);
  letter-spacing: 0;
  text-wrap: balance;
  text-align: center;
  align-self: stretch;
  position: relative;
  font-size: max(1rem, min(1.5vw, 1.5rem)) !important;
}

.project-card-view-header-risks-container {
  align-items: flex-start;
  gap: .75vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.project-card-view-see-more-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.project-card-view-see-more-text, .project-card-view-see-more-icon {
  font: .5rem;
  color: var(--page_background);
}

.hub-saved-projects-collection-frame {
  grid-template-columns: repeat(auto-fill, minmax(30rem, max-content));
  justify-content: space-evenly;
  align-self: stretch;
  align-items: stretch;
  gap: 10px;
  width: 100%;
  display: grid;
  position: relative;
}

.hub-saved-projects-table-row-label {
  background-color: var(--white);
  width: 10vw;
  text-align: center !important;
  text-wrap: balance !important;
  border-right: 2px solid var(--black) !important;
  font-weight: 600 !important;
}

.hub-saved-projects-table-row-label-blank {
  background-color: var(--table-grey);
}

.hub-saved-projects-table-row-datapoint-cell {
  width: max(150px, min(20vw, 400px));
  text-align: center !important;
  align-items: center !important;
}

.with-border {
  border-right: 2px solid var(--black) !important;
}

.hub-saved-projects-table {
  max-width: 90%;
  border-radius: 10px !important;
  overflow-x: auto !important;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, .45) !important;
}

.hub-saved-projects-table::-webkit-scrollbar {
  height: 10px;
  position: absolute;
}

.hub-saved-projects-table::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.hub-saved-projects-table::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--page_background);
  border-radius: 10px;
}

.hub-saved-projects-table::-webkit-scrollbar-button:start:increment {
  background: none;
  height: 2%;
  display: block;
}

.hub-saved-projects-table::-webkit-scrollbar-button:end:increment {
  background: none;
  height: 2%;
  display: block;
}

.hub-saved-project-image-and-name-container {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.hub-saved-project-name-text {
  color: var(--page_background);
  text-align: center;
  text-wrap: balance;
  font-weight: 600;
}

.hub-saved-project-image-container {
  width: inherit;
  justify-content: center;
  width: 90%;
  height: 10vh;
  display: flex;
}

.hub-saved-project-image {
  object-fit: cover;
  height: inherit;
  width: inherit;
  border-radius: 15px;
}

.hub-saved-project-open-container {
  background-color: var(--accent_orange);
  cursor: pointer;
  color: var(--white);
  border-radius: 15px;
  margin-top: 2vh;
  padding: .5vh 1vw;
  font-weight: 600;
}

.hub-saved-project-description-container {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  width: 100%;
  padding: 0 1vw;
  display: flex;
}

.hub-saved-project-description {
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.hub-saved-project-description-expand-text {
  color: var(--accent_orange);
  cursor: pointer;
  font-weight: 600;
}

.hub-saved-project-house-category-container {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.hub-saved-project-house-category-image-container {
  width: inherit;
  height: 5vh;
  display: flex;
}

.hub-saved-project-house-category-text {
  font-weight: 600;
}

.hub-saved-projects-checkmark {
  color: var(--page_background);
}

.hub-saved-projects-checkmark-text {
  flex: 1;
  font-weight: 400;
}

.hub-saved-project-discount-icons-container {
  grid-template-columns: repeat(auto-fit, minmax(7rem, 2fr));
  gap: 1vh 2vw;
  width: 100%;
  display: grid;
}

.hub-saved-project-discount-icons-image-container {
  width: inherit;
  height: max(50px, min(10vh, 100px));
  display: flex;
}

.hub-saved-project-discount-icons-image {
  object-fit: contain;
  height: inherit;
  width: inherit;
  border-radius: 15px;
}

.what-now-icon-frame {
  cursor: pointer;
  aspect-ratio: 16 / 9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.what-now-icon-frame-image-overlay {
  background-color: rgba(0, 0, 0, .59);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.what-now-icon-frame-image-overlay-not-selected {
  background-color: rgba(0, 0, 0, .87) !important;
}

.form-frame {
  z-index: 1001;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  width: 100%;
  padding: 0 2vw;
  display: flex;
}

.form-frame form {
  width: 100%;
}

.form-frame input, .form-frame label {
  color: var(--black);
  font-family: inherit !important;
  font-size: var(--span-xs-font-size) !important;
}

.css-12hrykr-MuiFormControl-root, .css-1cdnm0f-MuiFormControl-root, .css-162wq0i-MuiFormControl-root {
  background-color: var(--white);
  border-radius: 5px;
}

.css-12hrykr-MuiFormControl-root label, .css-1cdnm0f-MuiFormControl-root label, .css-162wq0i-MuiFormControl-root label {
  color: var(--page_background);
  font-family: inherit !important;
}

.form-text-frame {
  color: var(--page_background);
  background-color: var(--white);
  text-align: center;
  border-radius: 5px;
  width: 100%;
}

.form-text-frame span {
  color: var(--page_background);
}

.select-options-box {
  width: 100%;
}

.error-message {
  color: var(--red);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-style: italic;
  font-weight: 600 !important;
}

.css-saulgj {
  background-color: var(--white);
}

.email-sent-message-light {
  color: var(--white);
}

.email-sent-message-dark {
  color: var(--page_background);
}

.MuiListItemText-root span, .MuiTypography-root {
  font-family: inherit !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: var(--orange_accent);
}

.form-contact-us-topics-of-interest-frame {
  align-items: flex-start;
  width: 100%;
}

.form-contact-us-topics-of-interest-frame legend {
  width: 100%;
}

.form-contact-us-topics-of-interest-frame legend:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.form-contact-us-topics-of-interest-frame legend:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.form-contact-us-topics-of-interest-frame legend:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.css-1fwnb9c {
  margin: 0;
  padding: .5rem;
}

.checklist-item-frame {
  border: 1px solid var(--black);
  cursor: pointer;
  border-radius: 15px;
  flex-direction: row;
  align-items: center;
  gap: 1vw;
  padding: 2vh 1vw;
  display: flex;
}

.checklist-item-frame-mobile {
  border: 1px solid var(--black);
  cursor: pointer;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  padding: 2vh 1vw;
  display: flex;
}

.checklist-item-header-frame-mobile {
  flex-direction: row;
  align-items: center;
  gap: 5vw;
  width: 100%;
  display: flex;
}

.checklist-item-header-frame-mobile-selected span, .checklist-item-header-frame-mobile-selected div {
  color: var(--white);
}

.checklist-item-frame-mobile-selected, .checklist-item-frame-selected, .checklist-item-frame-selected, .checklist-item-frame-mobile-selected {
  background-color: var(--page_background);
}

.checklist-item-frame-selected span, .checklist-item-frame-selected div {
  color: var(--white);
}

.checklist-item-frame-mobile-true, .checklist-item-frame-mobile-false {
  color: var(--page_background);
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.checklist-item-icon-container-true {
  color: var(--page_background);
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.checklist-item-icon-container-false {
  cursor: pointer;
  color: var(--grey);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.checklist-item-text-container {
  flex-direction: column;
  gap: .5vh;
  display: flex;
}

.checklist-item-headline {
  font-weight: 600;
}

.checklist-item-headline-selected {
  color: var(--white);
}

.what-now-quoll-guidance-frame {
  justify-content: center;
  align-items: center;
  gap: 2vh;
  width: 100%;
  display: flex;
  flex-direction: column !important;
}

.what-no-account-button-container {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.what-now-account-buttons-frame {
  padding-bottom: 3vh;
}

.quoll-score-container {
  left: 3%;
  right: unset;
  top: unset;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 80%;
  padding: .5vh .5vw;
  display: flex;
  position: relative;
  overflow: hidden;
}

.quoll-score-container-popup {
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1.5rem !important;
}

.quoll-sore-headline {
  color: var(--black);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .85rem;
  font-weight: 600;
  position: relative;
}

.quoll-score-value {
  color: var(--black);
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 3rem;
  font-weight: 600;
  position: relative;
}

.now-what-header-headline-text {
  font-weight: 800;
}

.now-what-score-container {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.now-what-score-container-text-container {
  flex-direction: row;
  display: flex;
}

.now-what-score-container-text {
  font-weight: 600;
}

.now-what-score-description-text {
  color: var(--black);
  text-align: center;
}

.what-now-button-frame-overlay {
  height: inherit;
  width: inherit;
  background-color: rgba(0, 0, 0, .65);
  border-bottom: 1px solid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1vw;
  display: flex;
  position: absolute;
}

.what-now-button-frame {
  background-color: var(--accent_orange);
  cursor: pointer;
  z-index: 1;
  border-radius: 60px;
  flex-direction: row;
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 4rem;
  display: flex;
  position: fixed;
  bottom: 15%;
  right: 1%;
}

.what-now-button-icon {
  color: var(--accent_orange);
  font-size: var(--h2-font-size) !important;
}

.what-now-button-image-frame-tablet, .what-now-button-frame-mobile {
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  padding: 1%;
}

.what-now-button-image-frame-desktop {
  z-index: 5;
  background-color: var(--white);
  outline: 3px solid var(--page_background);
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: -1rem;
  overflow: hidden;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.what-now-button-image-frame-tablet, .what-now-button-image-frame-mobile {
  border-radius: 60px;
  width: 3rem;
  height: 3rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.what-now-button-image {
  object-fit: cover;
  width: auto;
  height: 100%;
}

.what-now-button-image-tablet, .what-now-button-image-mobile {
  border: none;
}

.now-what-icon-text-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 3.5rem;
  display: flex;
}

.now-what-icon-text-container-tablet, .now-what-icon-text-container-mobile {
  padding-left: 0;
}

.now-what-icon-text {
  color: var(--white);
  margin-right: 1rem;
  font-weight: 800;
  line-height: unset !important;
}

.now-what-icon-text-tablet, .now-what-icon-text-mobile {
  text-wrap: balance;
  max-width: 20vw;
  margin: 0;
  padding: 0 1vw;
}

.now-what-header {
  color: var(--page_background);
  text-align: center;
}

.what-now-frame {
  background-color: var(--hub_background);
  z-index: 1002;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-width: 80vw;
  max-height: 90vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: flex;
  position: fixed;
  top: 5%;
  overflow-x: hidden;
  overflow-y: auto;
}

.what-now-frame-first-view-true {
  max-width: 40vw;
  top: 33%;
}

.what-now-frame-tablet {
  width: calc(100% - 100px);
  max-width: 85vw;
  padding: 2vw;
}

.what-now-frame-mobile {
  width: calc(100% - 20px);
  max-width: 90vw;
  padding: 2vh 1vw;
}

.now-what-background-overlay {
  z-index: 1000;
  background-color: rgba(72, 69, 69, .37);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.now-what-guidance-take-action-frame {
  gap: .5vw;
  width: 100%;
  display: flex;
  flex-direction: row !important;
}

.now-what-guidance-checklist-container {
  justify-content: flex-start;
  gap: .5vh;
  width: 50%;
  display: flex;
  flex-direction: column !important;
}

.now-what-guidance-checklist-container-mobile {
  width: 100%;
}

.now-what-guidance-cta-container {
  border: 1px solid var(--page_background);
  background: var(--page_background);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  gap: 5vh;
  width: 50%;
  padding: 1vw;
  display: flex;
  flex-direction: column !important;
}

.now-what-guidance-cta-container-mobile {
  background: var(--page_background);
  justify-content: center;
  align-items: center;
  gap: 5vh;
  width: 100%;
  padding: 1vw;
  display: flex;
  flex-direction: column !important;
}

.now-what-guidance-cta-container > span, .now-what-guidance-cta-container-mobile > span {
  color: var(--white);
}

.now-what-guidance-checklist-item-header {
  color: var(--white);
  font-weight: 600;
}

.now-what-guidance-checklist-item-header-mobile {
  font-weight: 600;
}

.now-what-guidance-checklist-item-text {
  color: var(--white);
  white-space: break-spaces;
}

.now-what-guidance-subsection-header {
  color: var(--page_background);
  font-weight: 600;
}

.what-now-header-frame {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 100%;
  display: flex;
}

.what-now-guidance-path-button {
  align-self: flex-start;
}

.hub-logo-quoll {
  -webkit-filter: grayscale();
  filter: grayscale();
  opacity: .035;
  border-radius: 10px;
  width: 85%;
  height: 85%;
  position: absolute;
  top: -18%;
  right: -22%;
}

.title-button-container {
  background-color: var(--accent_orange);
  cursor: pointer;
  border-radius: 7.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 3em;
  height: 4vh;
  padding: 0 1vw;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 480px) {
  .title-button-container-selected, .title-button-container {
    width: 20vw;
    height: 4vh;
  }
}

.title-button-container:hover {
  cursor: pointer;
  background-color: var(--accent_light);
}

.title-button-container-selected {
  outline: .7px solid var(--black);
  background-color: var(--accent_light);
  cursor: pointer;
  border-radius: 7.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 3em;
  height: 4vh;
  padding: 0 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.title-button-container-selected:hover {
  cursor: pointer;
}

.title-button-text {
  text-align: center;
  white-space: nowrap;
  letter-spacing: .25px;
  color: var(--white);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
}

.report-title-title {
  left: unset;
  top: unset;
  flex-flow: wrap;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 3vw;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
}

.report-title-text-frame {
  height: inherit;
  flex: 1;
  gap: 15px;
  position: relative;
}

.report-title-share-save-frame {
  justify-content: center;
  gap: 1vh .75vw;
  display: flex;
}

@media (max-width: 480px) {
  .report-title-title {
    flex-direction: column;
  }

  .report-title-share-save-frame {
    justify-content: center;
    gap: 1vh 3vw;
  }
}

.report-address-text {
  align-self: unset;
  letter-spacing: -.21px;
  font-weight: 500;
  line-height: inherit;
  white-space: break-spaces;
  text-align: center;
  color: var(--hub_background);
  text-wrap: balance;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.title-unclaimed-text {
  align-self: unset;
  color: rgba(0, 0, 0, 0);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  position: relative;
}

.title-unclaimed-span-wrapper, .title-span {
  color: #777;
  font-size: .8rem;
}

.title-text-wrapper-2 {
  color: #262626;
  font-size: .8rem;
  text-decoration: underline;
}

.social-share-frame {
  background-color: var(--white);
  z-index: 1;
  opacity: 1;
  border-radius: .25rem;
  flex-direction: row;
  align-items: center;
  gap: 1vw;
  padding: .75rem;
  transition: opacity .15s ease-in-out, -webkit-transform .35s ease-in-out, transform .35s ease-in-out;
  display: flex;
  position: absolute;
  top: 5rem;
  right: 0;
  -webkit-transform: translate(-4vw, -1vh);
  transform: translate(-4vw, -1vh);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
}

.social-share-icon-formatting {
  color: var(--page_background);
  cursor: pointer;
}

.save-share-bar-frame {
  flex-direction: row;
  justify-content: flex-end;
  gap: 1vw;
  padding: 0 2vw;
  display: flex;
}

.report-score-and-maps-frame {
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  height: max(150px, min(10vh, 400px));
  display: flex;
  position: relative;
}

.report-score-streetview {
  border-radius: var(--border_radius_container);
  height: inherit;
  background-position: 50%;
  background-size: cover;
  width: 66%;
  position: relative;
  left: 0;
}

.report-score-streetview-mobile {
  width: 100%;
}

.report-score-satellite-view {
  border-radius: var(--border_radius_container);
  height: inherit;
  background-position: 50%;
  background-size: cover;
  width: 33%;
  position: relative;
}

.missing-streetview-image-overlay {
  border-radius: var(--border_radius_container);
  background-color: rgba(0, 0, 0, .72);
  width: 100%;
  height: 100%;
  position: relative;
}

.missing-streetview-image-text {
  color: var(--hub_background);
  font-style: italic;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: larken, sans-serif !important;
}



.desktop-home-report-frame {
  background-color: #fff;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-width: 834px;
  display: flex;
  position: relative;
}

.desktop-home-report-content-container {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.analysis-loading-image-container {
  z-index: 1002;
  border-radius: 15px;
  width: 30vw;
  position: fixed;
  top: 25%;
  left: 35%;
  overflow: hidden;
}

.analysis-loading-image {
  object-fit: contain;
}

.header-search-bar-item-container {
  width: inherit;
  height: inherit;
  background-color: #fff;
  border-radius: 25px;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
}

.google-auto-complete {
  width: inherit;
  height: inherit;
}

.pac-input {
  text-overflow: ellipsis;
  background-color: #fff;
  width: 400px;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  font-size: 1rem;
  font-weight: 300;
}

.pac-container {
  box-sizing: content-box;
  font-size: 1rem;
  position: absolute;
  width: max(300px, min(35vw, 500px)) !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  bottom: 2vh !important;
}

@media (max-width: 480px) {
  .pac-container {
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
  }
}

.header-search-bar {
  text-align: center;
  width: inherit;
  height: inherit;
  border: none;
  border-radius: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: halyard-display;
  font-size: 1rem;
  display: flex;
  position: relative;
}

.header-search-bar:focus {
  outline: none !important;
}

.header-search-bar-icon-container {
  background-color: var(--accent_orange);
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-height: 80%;
  display: flex;
  position: absolute;
  right: .5em;
}

.header-search-bar-icon {
  object-fit: contain;
  position: relative;
  scale: .5;
}

.hero-bar-container {
  flex-direction: row;
  align-items: center;
  gap: 2vw;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  position: relative;
}

.hero-bar-text-column {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: relative;
}

.hero-bar-text-container {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  gap: 5vh;
  display: flex;
  position: relative;
}

.text-container-desktop {
  align-items: flex-start;
  gap: 5vh;
}

.text-container-mobile {
  align-items: center;
  gap: 3vh;
}

.hero-bar-headline {
  color: var(--white);
  letter-spacing: .05rem;
  text-wrap: balance;
  align-self: stretch;
  font-size: 70px;
  font-weight: 400;
  position: relative;
}

.hero-bar-headline span {
  color: inherit !important;
}

.hero-bar-headline-emphasis {
  font-size: inherit;
  line-height: inherit;
  font-family: lark;
}

.hero-bar-paragraph {
  color: #f6e6db;
  letter-spacing: 0;
  align-self: stretch;
  font-size: 18px;
  position: relative;
}

.mobile-text {
  text-align: center;
}

.hero-bar-image-container {
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  min-width: 35%;
  height: 100%;
  display: inline-flex;
  position: relative;
}

.hero-bar-image-desktop {
  height: inherit;
  width: inherit;
  object-fit: contain;
}

.hero-bar-image-mobile {
  width: inherit;
  object-fit: cover;
  max-height: 25vh;
}

.hero-search-bar-container-desktop {
  width: max(300px, min(25vw, 500px));
  height: 5.5vh;
}

.hero-search-bar-container-desktop div {
  align-items: center;
  display: flex;
}

.hero-search-bar-container-mobile {
  width: 75vw;
  height: 4vh;
}

.value-prop-item-container {
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  min-height: 35vh;
  padding: 10px 30px;
  display: flex;
  position: relative;
}

.value-prop-item-text {
  color: #115453;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  position: relative;
}

.value-item .icon {
  min-width: 170px !important;
  height: 156.69px !important;
  margin-top: -.59px !important;
  position: relative !important;
}

.value-prop {
  background-color: var(--hub_background);
  border-radius: var(--border_radius_container);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.value-prop .message-2 {
  background-color: #f6e6db;
  border-radius: 20px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  padding: 50px 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.value-prop-title-container {
  color: var(--page_background);
  letter-spacing: 0;
  text-align: center;
  align-self: center;
  line-height: normal;
  position: relative;
}

.value-prop-items-bar {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: center;
  align-self: stretch;
  gap: 5vw;
  padding: 10px 40px 30px;
  display: grid;
  position: relative;
}

.value-prop .v-value-item {
  width: unset !important;
  flex: 1 !important;
}

.value-prop .icon-8 {
  min-width: 170px !important;
  height: 156.69px !important;
  margin-top: -.59px !important;
  position: relative !important;
}

.value-prop .v2-value-item, .value-prop .value-item-instance {
  width: unset !important;
  flex: 1 !important;
}

.value-prop-methodology-bar {
  border-color: #ce9870;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex-direction: row;
  align-self: center;
  align-items: flex-start;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 0;
  display: flex;
  position: relative;
}

.value-prop-methodology-text {
  color: var(--accent_orange);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  position: relative;
}

.value-prop .text-wrapper-2 {
  color: #ce9870;
}

.value-prop .text-wrapper-3 {
  color: #ce9870;
  font-weight: 600;
}

.value-prop .icon-9, .value-prop .icon-10 {
  min-width: 170.08px !important;
  height: 156.1px !important;
  position: relative !important;
}

.tour-step-container {
  width: inherit;
  flex-direction: column;
  align-items: flex-start;
  gap: 3vh;
  padding: 5vh 0;
  display: flex;
  position: relative;
}

.tour-step-container-mobile {
  align-items: center;
}

.tour-step-container-mobile h2, .tour-step-container-mobile h3, .tour-step-container-mobile p {
  text-align: center;
  width: 100%;
}

.tour-step-header {
  flex-direction: column;
  align-items: flex-start;
  gap: 1vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.tour-step-context {
  color: var(--hub_background);
  white-space: normal;
  font-size: 28px;
  font-style: italic;
  font-weight: 300;
  position: relative;
}

.tour-step-headline {
  color: var(--hub_background);
  letter-spacing: 0;
  text-align: left;
  font-size: 54px;
  font-weight: 400;
  position: relative;
}

.tour-step-description {
  color: var(--hub_background);
  letter-spacing: 0;
  font-size: 17px;
  font-weight: 300;
  line-height: 25.5px;
  position: relative;
}

.tour-step-questions-container {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 2vh;
  display: flex;
  position: relative;
}

.tour-step-question {
  color: var(--hub_background);
  letter-spacing: 0;
  white-space: normal;
  font-style: italic;
  font-weight: 300;
  position: relative;
}

.tour-step-takeaways-button-frame {
  border: 1px solid var(--white);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 5vh;
  padding: 1vh 3%;
  display: flex;
  position: relative;
}

.tour-step-takeaways-button-text {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: normal;
  position: relative;
}

.tour-frame {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  display: flex;
}

.tour-container-desktop {
  flex-direction: row;
  gap: 5vw;
  padding: 0 1.5%;
  display: flex;
}

.tour-container-mobile {
  flex-direction: column;
  gap: 2vw;
  display: flex;
}

.tour-header {
  color: var(--hub_background);
  text-align: center;
  width: 75%;
}

.tour-text-container {
  flex-direction: column;
  gap: 30vh;
  width: 50%;
  margin: 0;
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
}

.tour-step-text-container {
  border: 1px solid gray;
  margin: 0;
}

.tour-image-container {
  justify-content: flex-end;
  align-self: self-start;
  width: 50%;
  height: 70vh;
  -webkit-animation: 5s fadein;
  animation: 5s fadein;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 15vh;
}

.tour-image {
  object-fit: contain;
  position: relative;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  25% {
    opacity: .25;
  }

  50% {
    opacity: .5;
  }

  75% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  25% {
    opacity: .25;
  }

  50% {
    opacity: .5;
  }

  75% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

.tour .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 0 50px;
  display: flex;
  position: relative;
}

.tour .v-tour-step, .tour .v2-tour-step {
  white-space: unset !important;
}

.deep-breath-frame {
  background-color: #f6e6db;
  border-radius: 20px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.deep-breath-headline {
  color: var(--page_background);
  letter-spacing: 0;
  text-align: center;
  white-space: normal;
  position: relative;
}

.deep-breath-paragraph {
  gap: inherit;
  flex-direction: column;
  display: flex;
}

.deep-breath-paragraph-text {
  color: #115453;
  letter-spacing: 0;
  text-align: center;
  font-size: 18px;
  line-height: normal;
  position: relative;
}

.deep-breath-search-bar-container-desktop, .deep-breath-search-bar-container-tablet {
  width: 40vw;
  height: 4.5vh;
}

.deep-breath-search-bar-container-mobile {
  width: 70vw;
  height: 4.5vh;
}

.about-us-frame {
  align-items: center;
}

.about-us-header {
  color: var(--hub_background);
  letter-spacing: 0;
  text-align: center;
  align-self: center;
  line-height: normal;
  position: relative;
}

.about-us-container {
  flex-flow: wrap;
  justify-content: center;
  gap: 5vw;
  display: flex;
}

.about-us-image-container {
  justify-content: center;
  display: flex;
}

.about-us-text-container {
  color: #fff;
}

.about-us-text {
  color: var(--white);
  text-align: center;
}

.about-us .message-5 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  padding: 50px 10px 30px;
  display: flex;
  position: relative;
}

.about-us-bio-container {
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.about-us .person-instance {
  width: unset !important;
  flex: 1 !important;
}

.about-us .person-2 {
  color: #f6e6db !important;
  font-size: 40px !important;
  font-weight: 400 !important;
  line-height: 60px !important;
}

.about-us .person-3, .about-us .person-4 {
  color: #f6e6db !important;
}

.about-us .person-5 {
  width: unset !important;
  flex: 1 !important;
}

.about-us .person-6 {
  color: #f6e6db !important;
  font-size: 40px !important;
  font-weight: 400 !important;
  line-height: 60px !important;
}

.about-us .person-7, .about-us .person-8 {
  color: #f6e6db !important;
}

.frame-screen {
  background-color: #115453;
  border: 1px;
  flex-direction: column;
  align-items: flex-start;
  min-width: 1400px;
  display: flex;
  position: relative;
}

.frame-screen .frame-63 {
  position: absolute !important;
  top: 1482px !important;
  left: 811px !important;
}

.frame-screen .icon-12, .frame-screen .icon-13 {
  min-width: 170.08px !important;
  height: 156.1px !important;
  position: relative !important;
}

.pagination-bar {
  justify-content: center;
  width: 90%;
  display: flex;
}

.pagination-bar button, .pagination-bar li, .pagination-bar div.MuiPaginationItem-root {
  color: var(--page_background);
  font-size: 1.5rem;
}

.resources-text-styles {
  text-align: center;
  text-wrap: balance;
}

.resources-header-frame {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.resource-filter-section {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.resource-filter-selection-input {
  background-color: #fff;
  border-radius: 5px;
  font-weight: 600;
  color: var(--page_background) !important;
  width: max(150px, min(10vw, 300px)) !important;
}

.resource-filter-selection-input span, .resource-filter-selection-input label {
  color: var(--page_background) !important;
  font-family: inherit !important;
  font-size: var(--span-xs-font-size) !important;
}

.resource-search-bar-headline {
  font-style: italic;
  font-family: larken, sans-serif !important;
}

.input-container {
  width: 100%;
  position: relative;
}

.resource-filter-bar-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.resource-search-bar {
  border: none;
  border-radius: 50px;
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 5vw;
  width: 75%;
  height: 6vh;
  padding: 0% 5%;
  display: flex;
  position: relative;
  font-family: inherit !important;
}

.resource-search-bar:focus {
  outline-width: 0;
}

.cancel-icon {
  cursor: pointer;
  color: var(--page_background);
  position: absolute;
  right: 15%;
  font-size: 2rem !important;
}

.resource-filter-container {
  background-color: var(--modal_background);
  border-radius: 5px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  padding: .5vh .5vw;
  display: flex;
}

.resource-filter-bar-header {
  cursor: pointer;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  display: flex;
}

.resource-filter-bar-header-text {
  font-weight: 600;
}

.resource-filter-bar {
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 5vw;
  display: flex;
}

.resource-filter-item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.resource-filter-item select {
  text-align: center;
  width: 7vw;
  font-family: inherit;
  font-size: 1rem;
}

.resource-filter-item option {
  font-size: 1rem;
}

.resource-filter-item-text {
  font-size: 1.25rem;
  font-style: italic;
}

.resource-filter-item-icon-container {
  height: 5vh;
  display: flex;
}

.resource-filter-item-icon {
  color: var(--accent_orange);
  font-size: 2.25rem;
}

.resource-filter-item-clear {
  color: var(--white);
  cursor: pointer;
  background-color: var(--page_background);
  border-radius: 5px;
  padding: 1vh 2vw;
  font-size: 1.25rem;
  font-weight: 600;
}

.resource-filter-item-clear:hover {
  background-color: var(--accent_orange);
  color: var(--page_background);
}

.header-menu-item-container {
  text-align: center;
  align-items: flex-start;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  display: flex;
  position: relative;
}

.header-menu-item {
  color: var(--accent_orange);
  letter-spacing: 0;
  font-size: max(1.25rem, min(2vw, 1.5rem));
  font-weight: 300;
  line-height: inherit;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: halyard-display;
  position: relative;
}

.header-menu-button-container {
  text-align: center;
  border-radius: 5px;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  position: relative;
}

.menu-button-frame {
  justify-content: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 34px;
  display: flex;
  position: relative;
}

.menu-button-menu-item {
  background-color: var(--accent_orange);
  color: var(--white);
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: inherit;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vh;
  font-family: halyard-display;
  position: relative;
}

.header-frame {
  background-color: var(--page_background);
  mix-blend-mode: normal;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 4.5vh;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.header-frame-border {
  border-bottom: 1px solid var(--border_accent);
}

@media (min-width: 1400px) {
  .header-frame {
    padding: 4vh 3%;
  }
}

@media (min-width: 701px) and (max-width: 1399px) {
  .header-frame {
    padding: 4vh 2vw;
  }
}

@media (max-width: 700px) {
  .header-frame {
    justify-content: space-around;
    padding: 4vh 2vw;
  }
}

.link-container {
  height: inherit;
  width: inherit;
}

.quoll-logo-container {
  height: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 0 0;
  display: flex;
  position: relative;
}

.company-mark-container-mobile {
  width: inherit;
  height: inherit;
}

.quoll-logo-img {
  min-width: 100%;
  height: 100%;
  position: relative;
}

.header-search-bar-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.header-search-bar-container-mobile {
  width: 40vw;
  height: 5vh;
}

.header-search-bar-container-desktop {
  width: 25vw;
  height: 3.5vh;
}

.header-buttons-and-links-container {
  height: inherit;
  justify-content: flex-end;
  align-items: center;
  gap: 1vw;
  display: flex;
  position: relative;
}

.header-mobile-menu {
  background-color: var(--accent_orange);
  color: var(--white);
  letter-spacing: 0;
  border: 0;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  position: relative;
}

.link {
  color: red;
  font-size: inherit;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
}

.link:hover {
  color: #000;
}

.dropdown {
  height: inherit;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.dropdown:focus {
  border: 1px red;
}

.dropdown-menu {
  background-color: var(--white);
  z-index: 1;
  opacity: 1;
  border-radius: .25rem;
  flex-direction: column;
  align-items: center;
  padding: .75rem;
  transition: opacity .15s ease-in-out, -webkit-transform .35s ease-in-out, transform .35s ease-in-out;
  display: flex;
  position: absolute;
  top: 2rem;
  right: 0;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
}

.dropdown-menu.active {
  opacity: 1;
}

.dropdown > .header-mobile-menu:focus + .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.footer-frame {
  border-top: 1px solid var(--border_accent);
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  width: 100%;
  margin-top: auto;
  padding: 2vh 1vw;
  display: flex;
  position: relative;
}

.footer-logo-company-description-container {
  justify-content: space-around;
  align-items: center;
  gap: 5vw;
  width: 100%;
  display: flex;
  position: relative;
}

.footer-logo-box {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  max-width: 30%;
  padding: 2px 20px 0 0;
  display: flex;
  position: relative;
}

.footer-logo-img-container {
  min-width: 150px;
}

.footer-logo-image {
  max-height: 6vh;
  position: relative;
}

.footer-company-description {
  color: var(--white);
  letter-spacing: 0;
  align-self: stretch;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.footer-links-grid-wrapper {
  text-align: center;
  flex: 1;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  align-items: center;
  justify-items: center;
  gap: 1vh .5vw;
  display: grid;
}

.footer-links {
  color: var(--white);
  font-size: 1rem;
}

.footer-div {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.footer-text-wrapper {
  letter-spacing: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.footer-social-icons-container {
  justify-content: center;
  align-items: center;
  gap: 2vh 1vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 10vw;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .footer-social-icons-container {
    flex-direction: row;
    padding: 10px 0;
  }
}

@media (min-width: 770px) and (max-width: 1023px) {
  .footer-social-icons-container {
    flex-direction: row;
    padding: 10px 0;
  }
}

@media (max-width: 769px) {
  .footer-social-icons-container {
    flex-direction: row;
  }
}

.footer-social-icon-box {
  border-radius: 20px;
  width: 30px;
  height: 30px;
  position: relative;
}

.footer-social-icon {
  object-fit: contain;
  height: inherit;
  width: inherit;
}

.footer-bottom-bar-frame {
  border-color: rgba(0, 0, 0, .1);
  border-top-style: solid;
  border-top-width: 1px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.footer-legal-links {
  gap: 1vw;
  display: flex;
  position: relative;
}

.footer-legal-links-span {
  color: var(--white);
  letter-spacing: 0;
  font-size: .8rem;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
}

.footer-legal-links-seperator {
  color: var(--white);
  font-size: .8rem;
  font-weight: 600;
}

.footer-text-wrapper-3 {
  color: var(--white);
  letter-spacing: 0;
  font-size: 11px;
  font-weight: 400;
}

.footer-element-copyright-quoll-intelligence {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  text-wrap: balance;
  font-size: .8rem;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.content-footer-container {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 1vh;
  display: flex;
  position: relative;
}

.got-questions-button {
  margin-bottom: 0;
}

.blog-frame {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 4vh;
  padding: 5vw 10vw;
  display: flex;
  position: relative;
}

.blog-header {
  justify-content: center;
  align-items: left;
  background-position: 50%;
  background-size: contain;
  flex-direction: column;
  width: 100%;
  min-height: 25vw;
  display: flex;
  position: relative;
}

.blog-header-overlay {
  opacity: .7;
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-image {
  object-fit: contain;
  height: inherit;
  opacity: .3;
  width: 100%;
}

.blog-header-text {
  z-index: 2;
  flex-direction: column;
  gap: 2vh;
  padding: 1vh 10vw;
  display: flex;
}

@media (min-width: 480px) {
  .blog-header-text span, .blog-header-text p, .blog-header-text h1, .blog-header-text h2 {
    text-wrap: balance;
    text-align: left !important;
  }
}

@media (max-width: 480px) {
  .blog-header-text span, .blog-header-text p, .blog-header-text h1, .blog-header-text h2 {
    text-wrap: balance;
    text-align: center !important;
  }

  .blog-tags {
    align-self: center;
  }

  .blog-frame {
    padding: 2vh 5vw;
  }
}

.blog-header-font {
  color: var(--white);
}

.blog-header-headline {
  color: var(--hub_background);
  text-wrap: balance;
  font-size: 6vh;
}

.blog-header-category {
  color: var(--accent_orange);
  font-style: italic;
}

.blog-header-author {
  color: var(--hub_background);
  font-size: 1rem;
}

.blog-tags {
  color: var(--hub_background);
  font-size: max(1rem, min(2.5vw, 1.5rem));
}

.blog-paragraph {
  color: var(--black);
}

.blog-li {
  list-style-type: circle;
  list-style-position: inside;
}

.blog-ol {
  color: var(--page_background);
  margin-left: 5%;
  list-style: disc;
}

.italic {
  font-style: italic;
}

.blog-related-resources {
  flex-direction: column;
  gap: 6vh;
  width: 100%;
  display: flex;
}

.about-us-image {
  border-radius: 15px;
  height: 30vh;
}

.about-us-got-questions-text {
  color: var(--page_background);
  font-size: 1.5rem;
}

.clip-svg {
  z-index: -1;
  width: 1280px;
  height: 1182px;
  position: absolute;
  top: 0;
  left: 0;
}

.what-we-do-icons-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.what-we-do-icons-image {
  object-fit: cover;
  position: relative;
}

.what-we-do-icon-image {
  object-fit: contain;
  height: inherit;
  width: inherit;
  position: relative;
}

.what-we-do-icon-container {
  flex-direction: column;
  align-items: center;
  height: 5vh;
  display: flex;
}

.what-we-do-icon-text-box {
  text-wrap: balance;
  text-align: center;
  align-items: center;
  max-width: 20vw;
  position: absolute;
  overflow: hidden;
}

.what-we-do-icon-text {
  text-align: center;
}

.what-we-do-icon-1-box {
  bottom: 5%;
  left: -10%;
}

.methodology-icons-grid {
  grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 2vh 4vw;
  width: 100%;
  display: grid;
  position: relative;
}

.methodology-text-icon-frame {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.methodology-icon-container {
  height: 5vh;
}

.methodology-icon {
  object-fit: contain;
  height: inherit;
}

.methodology-icon-text {
  text-align: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  font-size: .9rem;
  font-style: italic;
  display: flex;
}

.methodology-layer-text {
  text-align: center;
  text-wrap: balance;
}

.methodology-flexbox-secton {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 2vh 3vw;
  display: flex;
}

.methodology-flexbox-secton-reverse {
  flex-flow: wrap-reverse;
}

.methodology-flexbox-text {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: max(225px, min(35vw, 600px));
  display: flex;
}

.sorry-page-container {
  gap: 3vh;
}

.sorry-image-text-container {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  width: 100%;
  display: flex;
}

.sorry-image-container {
  height: 25vh;
  display: flex;
}

.sorry-image {
  object-fit: contain;
}

.sorry-text {
  color: var(--white);
  text-align: center;
  width: 90%;
}

.misclassified-text-container {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.sorry-text-email {
  color: var(--accent_orange);
  text-align: center;
  width: 90%;
  font-size: 1.5rem;
}

.error-text {
  color: var(--white);
}

.scrollytelling-container {
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10vh;
  display: flex;
}

.scrollytelling-text-container {
  z-index: 2;
  flex-direction: column;
  gap: 30vh;
  width: max(150px, min(35vw, 500px));
  margin: 0;
  padding-top: 15vh;
  padding-bottom: 15vh;
  display: flex;
}

.scrolly-telling-image-container {
  border-radius: 15px;
  justify-content: space-between;
  align-self: self-start;
  align-items: center;
  max-width: 40vw;
  height: 50vh;
  -webkit-animation: 5s fadein;
  animation: 5s fadein;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 15vh;
}

.scrollytelling-image {
  object-fit: contain;
  border-radius: 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.scrollytelling-text {
  text-align: center;
  text-wrap: balance;
  padding: 5vh 0%;
}

.scrollytelling-container-mobile {
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  width: 100%;
  height: 40vh;
  margin-top: 10vh;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  overflow: scroll;
}

.scrollytelling-image-mobile {
  object-fit: contain;
  border-radius: 15px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 30vh;
}

.scrolly-telling-image-container-mobile {
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  align-self: self-start;
  align-items: center;
  width: 100%;
  height: 50vh;
  -webkit-animation: 5s fadein;
  animation: 5s fadein;
  display: flex;
  top: 15vh;
  overflow: scroll;
}

.scrollytelling-text-container-mobile {
  z-index: 2;
  flex-direction: column;
  width: 100%;
  margin: 0;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
}

.scrollytelling-text-mobile {
  text-align: center;
  text-wrap: balance;
  color: var(--page_background);
  background-color: var(--white);
  background-image: url("texture-background.3e078548.png");
  border-radius: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vw;
}

.problem-hero-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94vh;
  display: flex;
}

.problem-hero-image {
  object-fit: cover;
  opacity: .2;
  height: inherit;
  object-position: 0% 75%;
  width: 100vw;
  position: absolute;
}

.problem-hero-headline {
  z-index: 2;
  color: var(--white);
  text-align: center;
  text-wrap: balance;
}

.problem-hero-text-container {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 75%;
  height: 80%;
  -webkit-animation: 6s fadeIn;
  animation: 6s fadeIn;
  display: flex;
}

.problem-hero-scroll-notice-container {
  color: var(--white);
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.problem-hero-scroll-notice-text {
  z-index: 2;
  color: var(--white);
  font-size: 2rem;
}

.problem-section-container {
  flex-direction: column;
  align-items: center;
  gap: 8vh;
  display: flex;
}

.problem-section-sub-header-text {
  font-weight: 500;
}

.problem-section-sub-header-context-text {
  color: var(--black);
  text-align: center;
  text-wrap: balance;
  align-self: center;
  width: 75%;
  font-size: 1rem;
  font-style: italic;
}

.problem-section-conclusion-text {
  color: var(--black);
  text-align: center;
  width: 90%;
}

.problem-section-inner-row {
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  justify-content: end;
  align-self: center;
  align-items: center;
  justify-items: center;
  gap: 3vw;
  width: 75%;
  display: grid;
}

.problem-section-inner-column {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  display: flex;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.how-we-talk-about-it-container {
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 40vh;
  display: flex;
}

.how-we-talk-about-it-container-overlay {
  height: inherit;
  width: inherit;
  background-color: rgba(0, 0, 0, .81);
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
}

.how-we-talk-about-it-text {
  text-align: center;
  z-index: 2;
  text-wrap: balance;
  color: var(--white);
  align-self: center;
  width: 80%;
}

.how-we-talk-about-it-container, .problem-section-container {
  padding: 0 0;
}

.dashboard-home-card-frame {
  background: var(--white);
  cursor: pointer;
  border-radius: 15px;
  flex-direction: column;
  gap: 2vh;
  padding-bottom: 1vh;
  display: flex;
  overflow: hidden;
}

.dashboard-home-card-frame-desktop {
  max-width: 40vw;
}

.dashboard-home-card-frame-tablet {
  max-width: 70vw;
}

.dashboard-home-card-image-container {
  background-position: 50%;
  background-size: cover;
  border-top-left-radius: 30px;
  width: 100%;
  height: 20vh;
  display: flex;
  position: relative;
}

.dashboard-home-card-score-bookmark-container {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.dashboard-home-card-bookmark-container {
  background-color: var(--accent_light);
  color: var(--white);
  z-index: 50;
  border-bottom-left-radius: 15px;
  flex-direction: column;
  justify-content: center;
  padding: .75vw;
  display: flex;
}

.dashboard-home-card-bookmark-container:hover {
  background-color: var(--accent_orange);
}

.dashboard-home-card-image {
  object-fit: cover;
  height: 100%;
}

.dashboard-home-card-address-bar {
  flex-direction: column;
  flex: 1;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}

.dashboard-home-card-address-text {
  text-align: center;
  color: var(--page_background);
}

.dashboard-home-card-projects-bar {
  flex-direction: column;
  display: flex;
}

.dashboard-home-card-quoll-score-container {
  text-align: center;
  border-bottom-right-radius: 15px;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1vw;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
}

.dashboard-home-card-projects-bar {
  flex-direction: row;
  justify-content: space-around;
  gap: 0;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
}

.dashboard-home-card-projects-bar-item {
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.dashboard-home-card-projects-bar-item-headline {
  text-align: center;
  color: var(--grey);
  text-wrap: wrap;
}

.dashboard-container {
  background: var(--hub_background);
  border-radius: var(--border_radius_container);
  flex-direction: column;
  gap: 3vh;
  width: 100%;
  padding: 30px;
  display: flex;
}

.dashboard-buttons-container {
  flex-flow: wrap;
  justify-content: space-between;
  display: flex;
}

.dashboard-header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  display: flex;
}

.dashboard-hero-image {
  width: inherit;
  object-fit: cover;
  opacity: .6;
  border-radius: 25px;
  max-height: 20vh;
}

.dashboard-title {
  color: var(--page_background);
}

.dashboard-signout-button-container {
  cursor: pointer;
  border-radius: 5px;
  align-self: flex-end;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: .5vh 1vw;
}

.dashboard-signout-button-container-signout {
  background: var(--accent_orange);
}

.dashboard-signout-button-container-book-time {
  background: var(--page_background);
}

.dashboard-signout-button-text {
  color: var(--white);
  font-size: 1rem;
  font-weight: 600;
}

.dashboard-my-saved-homes-container {
  flex-direction: column;
  gap: 3vh;
  display: flex;
}

.dashboard-my-saved-homes-grid {
  flex-flow: wrap;
  gap: 2vh 2vw;
  display: flex;
}

.dashboard-my-saved-homes-grid-desktop {
  justify-content: flex-start;
}

.dashboard-my-saved-homes-grid-tablet, .dashboard-my-saved-homes-grid-mobile {
  justify-content: center;
}

.legal-page-header {
  justify-content: center;
  align-items: left;
  background-position: 50%;
  background-size: contain;
  flex-direction: column;
  width: 100%;
  min-height: 15vw;
  display: flex;
  position: relative;
}

.careers-icons-grid {
  flex: 1;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 4fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 5vh 10vw;
  width: 90%;
  display: grid;
}

.careers-icon-frame {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.careers-icon-frame span {
  text-align: center;
  text-wrap: balance;
}

.careers-icon-container {
  height: 10vh;
}

.careers-icon-image {
  object-fit: contain;
  height: inherit;
}

.careers-icon-headline {
  font-weight: 600;
}

.careers-icon-text {
  flex: 1;
  align-items: center;
  display: flex;
}

.careers-resume-drop-form {
  border: 1px solid var(--page_background);
  border-radius: 15px;
  flex-direction: column;
  gap: 2vh;
  padding: 2vh 0;
  display: flex;
}



.careers-icons-grid {
  flex: 1;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 4fr));
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 5vh 10vw;
  width: 90%;
  display: grid;
}

.careers-icon-frame {
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.careers-icon-frame span {
  text-align: center;
  text-wrap: balance;
}

.careers-icon-container {
  height: 10vh;
}

.careers-icon-image {
  object-fit: contain;
  height: inherit;
}

.careers-icon-headline {
  font-weight: 600;
}

.careers-icon-text {
  flex: 1;
  align-items: center;
  display: flex;
}

.careers-resume-drop-form {
  border: 1px solid var(--page_background);
  border-radius: 15px;
  flex-direction: column;
  gap: 2vh;
  padding: 2vh 0;
  display: flex;
}

.faq-toggle-accordian-frame {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.faq-toggle-accordian-header-container {
  background-color: var(--page_background);
  cursor: pointer;
  border-radius: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1vw;
  display: flex;
}

.faq-accordian-header-text {
  color: var(--white) !important;
}

.faq-accordian-header-icon {
  color: var(--white) !important;
  font-size: var(--h2-font-size) !important;
}

.faq-accordian-questions-container {
  flex-direction: column;
  gap: 2vh;
  padding: 2vh 2vw;
  display: flex;
}

.faq-accordian-questions-container p {
  text-wrap: unset !important;
}

.faq-accordian-individual-faq-container {
  flex-direction: column;
  gap: 1vh;
  display: flex;
}

.faqs-container {
  flex-direction: column;
  gap: 2vh;
  width: 100%;
  display: flex;
}

:root {
  --page_background: #fff;
  --hub_background: #f1f1f1;
  --modal_background: #bdbdbd;
  --accent_dark: #fff;
  --accent_light: #468347;
  --text-title: #000;
  --page_background: #115453;
  --hub_background: #f6e6db;
  --modal_background: #d3d2d2;
  --accent_orange: #d8a270;
  --border_accent: #367e79;
  --accent_dark: #d3d3d3;
  --accent_light: #7b9478;
  --not_selected_text: #a8a7a7;
  --text-title: #fff;
  --yellow: #fcb500;
  --grey: #777;
  --table-grey: #cac6c6;
  --light-grey: #dadce0;
  --black: #454545;
  --white: #f8f5f5;
  --red: #f14d4d;
  --related_red: #f27e7e;
  --border_radius_container: 10px;
  --border_radius_bar: 50px;
  --small-screen-max: 700px;
  font-family: area-normal, sans-serif;
  font-size: 18px;
  --h1-font-size: clamp(2rem, 3.5vw, 4.5rem) !important;
  --h2-font-size: clamp(1.5rem, 3.25vw, 2.5rem) !important;
  --h3-font-size: clamp(1.25rem, 2.75vw, 2rem) !important;
  --h4-font-size: clamp(1rem, 2vw, 1.5rem) !important;
  --p-font-size: clamp(1rem, 1.4vw, 1.4rem) !important;
  --span-xxs-font-size: clamp(.5rem, .5vw, .5rem) !important;
  --span-xs-font-size: clamp(.7rem, .8vw, 1rem) !important;
  --span-s-font-size: clamp(.75rem, .9vw, 1.1rem) !important;
  --span-m-font-size: clamp(.8rem, 1.25vw, 1.2rem) !important;
  --span-l-font-size: clamp(1rem, 1.4vw, 1.4rem) !important;
  --span-xl-font-size: clamp(1.2rem, 1.75vw, 1.75rem) !important;
  --span-xxl-font-size: clamp(2rem, 3vw, 3rem) !important;
}

.app-style {
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  display: flex;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--page_background);
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1 {
  color: var(--text-title);
  font-style: normal;
  font-size: var(--h1-font-size) !important;
  letter-spacing: normal !important;
  font-family: halyard-display, sans-serif !important;
  font-weight: 300 !important;
  line-height: 1.1em !important;
}

h2 {
  text-align: center;
  line-height: normal;
  font-family: halyard-display, sans-serif !important;
  font-size: var(--h2-font-size) !important;
  text-wrap: balance !important;
  font-weight: 300 !important;
}

h1 span, h2 span {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
  font-family: larken, sans-serif !important;
}

h3 {
  font-weight: 400;
  line-height: 1.1rem;
  font-family: halyard-display, sans-serif !important;
  font-size: var(--h3-font-size) !important;
  text-wrap: balance !important;
}

h4 {
  line-height: 1.1rem;
  font-family: halyard-display, sans-serif !important;
  font-size: var(--h4-font-size) !important;
  font-weight: 400 !important;
}

p {
  color: var(--black);
  font-weight: 500;
  font-size: var(--p-font-size) !important;
  text-wrap: balance !important;
  line-height: 1.4em !important;
}

input {
  color: var(--black);
  font-weight: 400;
  font-size: var(--span-s-font-size) !important;
}

i {
  font-style: italic;
}

svg {
  overflow: visible;
}

span {
  font-size: inherit;
  font-weight: inherit;
  color: var(--black);
  font-size: max(.8rem, min(1.25vw, 1.2rem));
  line-height: 1.4em !important;
}

.span-xxs {
  font-size: var(--span-xxs-font-size) !important;
}

.span-xs {
  font-size: var(--span-xs-font-size) !important;
}

.span-s {
  font-size: var(--span-s-font-size) !important;
}

.span-m {
  font-size: var(--span-m-font-size) !important;
}

.span-l {
  font-size: var(--span-l-font-size) !important;
}

.span-xl {
  font-size: var(--span-xl-font-size) !important;
}

.span-xxl {
  font-size: var(--span-xxl-font-size) !important;
}

.accent-text {
  font-family: larken, sans-serif !important;
}

a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--hub_background);
  text-decoration-color: var(--hub_background);
}

img {
  max-width: 100% !important;
  display: block !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.italic-text {
  font-style: italic;
}

.hub-finance-icons-text {
  color: var(--page_background);
  text-align: center;
  text-wrap: balance;
}

.center-text {
  text-align: center;
}

.page-frame-static {
  background-color: var(--white);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.page-overlay {
  z-index: 1000;
  background-color: rgba(72, 69, 69, .77);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.page-container {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.page-container-static {
  background-color: var(--white);
  color: var(--page_background);
  background-image: url("texture-background.3e078548.png");
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.home-section-container {
  border-radius: var(--border_radius_container);
  width: 100%;
  padding: 5vh 2vw;
}

.home-section-container-hero-mobile {
  border-radius: var(--border_radius_container);
  width: 100%;
  padding: 3vh 2vw;
}

.home-section-row-container {
  flex-direction: row;
  align-items: center;
  gap: 2vw;
  display: flex;
  position: relative;
}

.home-section-column-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vh;
  display: flex;
  position: relative;
}

.hub-frame {
  background-color: var(--hub_background);
  border-radius: var(--border_radius_container);
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: -webkit-fill-available;
  padding: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hub-content-frame {
  background-color: unset;
  border-radius: 3.5px;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
  width: 80%;
  padding: 21px;
  display: flex;
  position: relative;
}

.hub-selection-frame {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 6vh;
  padding: 2vh 0;
  display: flex;
  position: relative;
}

.hub-selection-title-container {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.hub-selection-title-text {
  color: #262626;
  letter-spacing: 0;
  text-align: center;
  white-space: normal;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
}

.modal-frame {
  background-color: var(--modal_background);
  z-index: 1002;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 60vw;
  max-height: 95vh;
  padding-top: 4vh;
  padding-bottom: 4vh;
  display: flex;
  position: fixed;
  top: 3%;
  left: 50%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.modal-frame-tablet {
  width: calc(100% - 100px);
  padding: 2vw;
}

.modal-frame-mobile {
  width: calc(100% - 20px);
  padding: 1vw;
}

.modal-exit-icon-container {
  cursor: pointer;
  z-index: 1000;
  justify-content: flex-end;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 4vh;
  padding-right: 1vw;
  display: flex;
  position: absolute;
  top: 25px;
  right: .1%;
}

.modal-exit-icon-container > .css-i4bv87-MuiSvgIcon-root {
  font-size: 2.5rem;
}

.modal-exit-icon-container-mobile, .modal-exit-icon-container-tablet, .modal-exit-icon-container-desktop {
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  top: 1px;
  left: calc(100% - 40px);
}

.modal-content-frame {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 4vh;
  width: 100%;
  padding: 1vh 3vw;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.modal-content-frame::-webkit-scrollbar {
  width: 10px;
  position: absolute;
}

.modal-content-frame::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.modal-content-frame::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px var(--page_background);
  border-radius: 10px;
}

.modal-content-frame::-webkit-scrollbar-button:start:increment {
  background: none;
  height: 2%;
  display: block;
}

.modal-content-frame::-webkit-scrollbar-button:end:increment {
  background: none;
  height: 2%;
  display: block;
}

.modal-header {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 5vw;
  display: flex;
  position: relative;
}

.modal-title {
  color: #262626;
  letter-spacing: -.5px;
  font-size: 50px;
  font-weight: 600;
  line-height: inherit;
  white-space: break-spaces;
  text-align: center;
  align-self: auto;
  position: relative;
}

.modal-div {
  border-color: #262626;
  border-top-style: solid;
  border-top-width: 1px;
  width: 100%;
}

.modal-subsection-header {
  letter-spacing: -.5px;
  line-height: 3rem;
}

.background-color-high-risk {
  background-color: var(--red) !important;
}

.background-color-medium-risk {
  background-color: var(--yellow) !important;
}

.background-color-low-risk {
  background-color: var(--accent_light) !important;
}

.text-underline {
  text-decoration: underline;
}

.div-line-seperator {
  border-bottom: 1px solid var(--grey);
  width: 100%;
  height: 1px !important;
}

.drop-shadow-standard {
  -webkit-filter: drop-shadow(0 0 30px #333);
  filter: drop-shadow(0 0 30px #333);
}

.box-shadow-standard {
  box-shadow: 3px 3px 4px rgba(0, 0, 0, .45);
}

.box-shadow-standard-left {
  box-shadow: -3px 3px 4px rgba(0, 0, 0, .45);
}

.box-shadow-light {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, .45);
}

.box-shadow-hover {
  box-shadow: 3px 3px 4px 0px var(--page_background);
  text-decoration: none;
}

.box-shadow-related {
  box-shadow: 3px 3px 4px 0px var(--related_red);
}

.not-selected-text {
  color: var(--not_selected_text);
}

.text-align-center {
  text-align: center;
  width: 100%;
}

.statistic-header {
  color: var(--black);
  letter-spacing: 0;
  text-align: center;
  white-space: normal;
  text-wrap: balance;
  align-items: center;
  gap: 2px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  display: flex;
  position: relative;
}

.statistic-header-climate {
  flex: 0 !important;
}

.statistic-datapoint {
  color: var(--black);
  letter-spacing: -.5px;
  text-align: center;
  white-space: normal;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  margin-bottom: 1vh;
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  position: relative;
}

.statistic-datapoint-High {
  color: var(--related_red);
}

.statistic-datapoint-Medium {
  color: var(--yellow);
}

.statistic-datapoint-Low {
  color: var(--accent_light);
}

.static-page-header-frame {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.statistic-geography-text {
  color: var(--page_background);
  width: 95%;
  font-size: 1rem;
  font-style: italic;
}

.statistic-datapoint-type-text-container {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  width: 100%;
  display: flex;
}

.page-container-static div h1, .page-container-static div h3 {
  color: var(--page_background);
  text-align: center;
  padding: 2vh 1vw;
}

.static-page-header-text {
  color: var(--white);
  text-align: center;
}

.page-content-container-static {
  flex-direction: column;
  align-items: center;
  gap: 8vh;
  width: 90%;
  display: flex;
}

.page-content-container-row-static {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  display: flex;
}

.page-content-container-row-static-right {
  flex: 1;
}

.page-content-container-flexbox {
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  display: flex;
}

.page-content-vertical-divider {
  border-left: 2px solid var(--page_background);
}

.page-content-horizontal-divider {
  border-bottom: 2px double var(--page_background);
  border-style: double;
  align-self: center;
  width: 50%;
}

.page-content-horizontal-divider-white {
  border-bottom: 2px double var(--white);
  border-style: double;
  align-self: center;
  width: 50%;
}

.page-content-horizontal-divider-full {
  border-bottom: 2px double var(--page_background);
  border-style: double;
  align-self: center;
  width: 90%;
}

.page-content-horizontal-divider-full-left-align {
  border-bottom: 2px double var(--page_background);
  border-style: double;
  align-self: flex-start;
  width: 90%;
}

.page-content-horizontal-divider-full-tall {
  border-bottom: 12px double var(--page_background);
  border-style: double;
  align-self: center;
  width: 90%;
}

.page-content-horizontal-divider-full-orange {
  border-bottom: 2px double var(--accent_orange);
  border-style: double;
  align-self: center;
  width: 90%;
}

select {
  text-align: center;
  font-family: inherit;
  font-size: var(--span-xs-font-size);
  width: 7vw;
  min-width: 100px;
  font-family: inherit;
  border-color: var(--light-grey) !important;
  border-radius: 5px !important;
}

.hub-financial-subsection-header {
  color: var(--page_background);
  text-align: center;
  align-self: center;
  gap: 1vh;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  display: flex;
  position: relative;
  flex-direction: column !important;
}

.hub-finaicial-section-frame {
  background-color: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6vh;
  padding: 5vh 3vw;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hub-financial-section-keep-in-mind-text {
  color: var(--black);
  letter-spacing: 0;
  text-align: center;
  text-wrap: balance;
  align-self: stretch;
  font-weight: 400;
  position: relative;
  font-size: max(.8rem, min(1.25vw, 1.2rem)) !important;
}

.image-container-flexbox {
  min-width: 50%;
  padding: 3%;
}

.methodology-layer-image {
  object-fit: fill;
  height: inherit;
  border-radius: 25px;
  width: max(225px, min(35vw, 500px));
  min-height: 20vh;
  max-height: 25vh;
}

.header-center-text {
  text-align: center;
  text-wrap: balance;
}

.hub-bar-font {
  font-size: max(.8rem, min(1.25vw, 1.2rem)) !important;
}

@media (min-width: 769px) {
  .hub-frame {
    padding: 30px;
  }

  .hub-content-frame {
    width: 100%;
  }

  .page-container {
    gap: 2vh;
    padding: 2vh 4.5%;
  }

  .page-container-static {
    gap: 6vh;
    padding: 4vh 6.5%;
  }

  .hub-projects-filter-bar-frame {
    gap: 5vw;
  }

  .modal-frame {
    width: 70vw;
  }
}

@media (min-width: 480px) and (max-width: 769px) {
  .hub-frame {
    padding: 20px;
  }

  .hub-content-frame {
    width: 100%;
    padding: 10px;
  }

  .page-container {
    gap: 2vh;
    padding: 2vh 4vw;
  }

  .page-container-static {
    gap: 4vh;
    padding: 2vh 4vw;
  }

  .hub-projects-filter-bar-frame {
    gap: 5vw;
  }

  .modal-frame {
    width: 85vw;
  }
}

@media (max-width: 480px) {
  .hub-frame {
    padding: 1vh 1.5vw;
  }

  .hub-content-frame {
    gap: 10px;
    width: 100%;
    padding: 0;
  }

  .page-container {
    gap: 2vh;
    padding: 2vh 2vw;
  }

  .page-container-static {
    gap: 4vh;
    padding: 2vh 2vw;
  }

  .modal-frame {
    width: 90vw;
  }
}

.underline-text {
  text-decoration: underline;
}

.text-wrap-balance {
  text-wrap: balance;
}

.text-strikethrough {
  text-decoration: line-through;
}

.clickable {
  cursor: pointer;
  margin-bottom: 1vh;
}

.haAclf {
  display: block;
}

.text-align-center {
  text-align: center;
}

.border-none {
  border: 0 !important;
}

.break-spaces {
  white-space: break-spaces;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.full-width {
  width: 100%;
}

/*# sourceMappingURL=index.ad4a3fab.css.map */
