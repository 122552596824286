.header-menu-button-container {
  display: flex;
  position: relative;
  width: fit-content;
  border-radius: 5px;
  align-items: center;
  /* padding: 1vh 0px; */
  /* background-color: var(--accent_orange); */
  text-align: center;
}

.menu-button-frame {
  display: flex;
  gap: 10px;
  height: 34px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.menu-button-menu-item {
  background-color: var(--accent_orange);
  color: var(--white);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  padding: 1vh;
  line-height: inherit;
  position: relative;
  width: fit-content;
  font-family: halyard-display;
}
