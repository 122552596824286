.quoll-score-container {
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: .5vh .5vw;
  left: 3%;
  gap: 1vh;
  min-height: 80%;
  position: relative;
  right: unset;
  top: unset;
  width: fit-content;
  justify-content: space-around;
}

.quoll-score-container-popup {
  aspect-ratio: 1/1;
  border-radius: 50%;
  padding: 1.5rem !important;
}

.quoll-sore-headline {
  color: var(--black);
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-size: .85rem;
}

.quoll-score-value {
  color: var(--black);
  font-size: 3rem;
  font-weight: 600;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  /* line-height: 77px; */
  /* letter-spacing: -0.7px; */
}