.project-card-accordian-header {
  align-self: stretch;
  align-items: center;
  padding: 0 0px 0 0;
  display: flex;
  position: relative;
  gap: 16px;
  cursor: pointer;
}

.project-card-accordian-header-mobile {
  flex-direction: column;
  gap: 1vh;
} 

.project-card-accordian-header-tablet {
  flex-direction: column;
  gap: .5vh;
} 

.project-card-accordian-header-desktop {
  flex-direction: row;
} 

.project-card-accordian-header-row-spaced {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.project-card-accordian-header-row-close {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.project-card-accordian-headline {
  color: var(--hub_background);
  letter-spacing: 0;
  white-space: normal;
  text-align: left;
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

@media (max-width: 480px) {
  .project-card-accordian-headline {
    text-align: center;
  }

  .report-title-share-save-frame {
    gap: 1vh 3vw;
    justify-content: center;
  }
}

.project-card-accordian-value-description {
  color: var(--hub_background);
  letter-spacing: .55px;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  font-weight: 400;
  line-height: 15.4px;
  position: relative;
}

.project-card-accordian-value {
  color: #468347;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .75rem;
  font-weight: 600;
  line-height: 25.2px;
  position: relative;
}

